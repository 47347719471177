import { FC, PropsWithChildren } from 'react';

import { Stack } from '@sparky';
import { styled } from '@sparky/stitches';

export const StyledWrapper = styled('div', {
  position: 'absolute',
  inset: 0,
});

export const StyledStack = styled(Stack, {
  height: '100%',
});

export const CenterInViewport: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledWrapper>
      <StyledStack direction="row" alignX="center" alignY="center">
        <Stack.Item>{children}</Stack.Item>
      </StyledStack>
    </StyledWrapper>
  );
};
