// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgWarmWaterIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M12.78 4.37A.99.99 0 0 0 12 4c-.3 0-.59.14-.78.37C10.69 5.04 6 10.97 6 14c0 3.31 2.69 6 6 6s6-2.69 6-6c0-3.03-4.69-8.96-5.22-9.63Zm-3.2 11.69c.23.19.28.53.1.77-.11.14-.27.22-.44.22-.11 0-.22-.03-.32-.11-.57-.43-1.34-1.7-.13-3.5.9-1.33.2-1.94.12-2.01a.564.564 0 0 1-.1-.77c.18-.24.52-.29.76-.11.57.43 1.34 1.7.13 3.5-.9 1.33-.2 1.94-.12 2.01Zm2.75 0c.23.19.28.53.1.77-.11.14-.27.22-.44.22-.11 0-.22-.03-.32-.11-.57-.43-1.34-1.7-.13-3.5.9-1.33.2-1.94.12-2.01a.564.564 0 0 1-.1-.77c.18-.24.52-.29.76-.11.57.43 1.34 1.7.13 3.5-.9 1.33-.2 1.94-.12 2.01Zm2.75 0c.23.19.28.53.1.77-.11.14-.27.22-.44.22-.11 0-.22-.03-.32-.11-.57-.43-1.34-1.7-.13-3.5.9-1.33.2-1.94.12-2.01a.564.564 0 0 1-.1-.77c.18-.24.52-.29.76-.11.57.43 1.34 1.7.13 3.5-.9 1.33-.2 1.94-.12 2.01Z" />
  </Icon>
);
export default SvgWarmWaterIcon;
SvgWarmWaterIcon.displayName = 'SvgWarmWaterIcon';
