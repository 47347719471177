import React, { FC } from 'react';

import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import NextLink from 'next/link';
import ReactIs from 'react-is';

import { getCombinedLinkFieldValueHref } from '@common/link';
import logger from '@common/log';
import { join } from '@common/path';
import { wrap } from '@sitecore/common';
import { NavLink, TabNavigation, Tag } from '@sparky';

import { useRouter } from './useRouter';

type HrefOrLinkValue =
  | { href: string; linkValue?: never }
  | { href?: never; linkValue: Pick<LinkFieldValue, 'anchor' | 'href' | 'querystring' | 'target'> };

type LinkProps = {
  className?: never;
  currentMatch?: 'exact';
  editable?: string;
  linkType: string;
} & HrefOrLinkValue;

// TODO Sparky could perhaps export something like an enum to identify components featuring the `isCurrent` prop
const hasIsCurrent = [NavLink.toString(), TabNavigation.Item.toString(), Tag.toString()];
const supportsIsCurrent = (component: React.ReactElement): boolean =>
  typeof component.props.isCurrent !== 'boolean' && hasIsCurrent.includes(component.type.toString());

const IS_HOSTED_PR_REGULAR_EXPRESSION = /^\/pr-\d+\//;

export const Link: FC<React.PropsWithChildren<LinkProps>> = props => {
  const router = useRouter();

  if (props.editable) {
    const { editable, ...rest } = props;
    return wrap(props, <Link {...rest} />, { a: props.children });
  }

  const { basePath, routerBasePath, activePath } = router;
  const { children, currentMatch, className, href: _href, linkType, linkValue, ...rest } = props;

  const href = _href ?? getCombinedLinkFieldValueHref(linkValue);
  const { target } = linkValue || {};

  if (!href) {
    logger.warn(`Xo9K80`, `Link component is missing href`, props);
    return children;
  }

  const isAbsolute = href.startsWith('http') || href.startsWith('tel') || href.startsWith('mailto');
  const isAnchor = href.startsWith('#');

  const path = isAnchor ? href : href.replace(/(https?:\/\/)?([^/]+)?(.+)?/, '$3');
  const pathname = routerBasePath && !path.startsWith(routerBasePath) ? join(routerBasePath, path) : path;

  const onlyChild = React.Children.only(children);

  if (ReactIs.isElement(onlyChild)) {
    // Stay within container (base path) using client-side routing
    if (linkType !== 'external' && !isAbsolute) {
      const isCurrent = currentMatch === 'exact' ? pathname === activePath : activePath.startsWith(pathname);
      const childprops = { className, ...(isCurrent && supportsIsCurrent(onlyChild) && { isCurrent }) };
      const child = React.cloneElement(onlyChild, childprops);

      // When running in a Hosted PR container, the basepath is prefixed with /pr-<pr-number>/
      // To fix links in the PR container, we need to remove the prefix from the pathname
      let basepathWithoutHostedPr = basePath;
      const isHostedPr = IS_HOSTED_PR_REGULAR_EXPRESSION.test(basePath);
      const pathnameIncludesHostedPr = IS_HOSTED_PR_REGULAR_EXPRESSION.test(pathname);
      if (isHostedPr && !pathnameIncludesHostedPr) {
        basepathWithoutHostedPr = basePath.replace(IS_HOSTED_PR_REGULAR_EXPRESSION, '/');
      }
      const path = (basepathWithoutHostedPr ? pathname.replace(basepathWithoutHostedPr, '') : pathname) || '/';

      return (
        <NextLink href={path} passHref legacyBehavior locale={false} target={target}>
          {child}
        </NextLink>
      );
    }

    // Container hopping: full page load
    return React.cloneElement(onlyChild, { href, className, target: onlyChild.props.target || target });
  }

  return (
    <a href={href} className={className} {...rest} target={target}>
      {children}
    </a>
  );
};
