// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgVisibilityIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M22.866 11.5C22.686 11.2 18.508 4 12 4S1.314 11.2 1.134 11.5a1 1 0 0 0 0 1C1.314 12.8 5.492 20 12 20s10.686-7.2 10.866-7.5a1 1 0 0 0 0-1ZM12 17a4.996 4.996 0 0 1-4.618-3.087 5.002 5.002 0 0 1 3.643-6.817A4.996 4.996 0 0 1 16.998 12 5 5 0 0 1 12 17Zm2.999-5a3 3 0 0 1-3.584 2.942A2.999 2.999 0 1 1 14.999 12Z"
    />
  </Icon>
);
export default SvgVisibilityIcon;
SvgVisibilityIcon.displayName = 'SvgVisibilityIcon';
