// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttle<T extends (...args: any) => any>(func: T, limit: number) {
  let inThrottle: boolean;

  return (...args: unknown[]) => {
    if (!inThrottle) {
      inThrottle = true;

      setTimeout(() => {
        inThrottle = false;
        func(args);
      }, limit);

      func(args);
    }
  };
}
