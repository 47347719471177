import { forwardRef, useEffect, useRef, useState, ChangeEvent } from 'react';

import { I18nProvider, useTranslation } from '@i18n';

import { fakeButtonStyle } from './FilePicker.css';
import { StyledInput, DragArea } from './styles';
import { Props, fileType } from './types';
import { UploadItem } from './UploadItem';
import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { DocumentIcon } from '../../icons';
import { useLocale } from '../../providers/locale';
import { mergeRefs } from '../../util';
import { ButtonInner } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { NotificationBox } from '../Notification/NotificationBox';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

const stitchesClassName = 'sparky-drag-area';

export const FilePicker = forwardRef<HTMLInputElement, Props>(({ customLabels, ...props }, ref) => {
  if (customLabels) {
    return (
      <I18nProvider dictionary={customLabels}>
        <FilePickerComponent {...props} ref={ref} />
      </I18nProvider>
    );
  } else
    return (
      <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
        <FilePickerComponent {...props} ref={ref} />
      </I18nProvider>
    );
});

const FilePickerComponent = forwardRef<HTMLInputElement, Props>(
  (
    {
      accept = ['PDF', 'DOC', 'DOCX', 'JPG', 'JPEG', 'PNG', 'GIF'],
      className = '',
      files,
      hasError,
      headingLevel = 'h2',
      maxAmount = 7,
      maxFileSize = 5,
      maxTotalSize = 10,
      name,
      setFiles,
      setHasError,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const localRef = useRef<HTMLInputElement>(null);
    // Merge the local ref and forwarded ref so we can forward it *and* use it locally
    const mergedRef = mergeRefs([ref, localRef]);

    const [isFocused, setIsFocused] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isPointerDevice, setIsPointerDevice] = useState(true);

    const [rejectedFiles, setRejectedFiles] = useState<{ name: string; message: string }[]>([]);
    const [error, setError] = useState<{ title: string; message: string } | null>(null);

    useEffect(() => {
      if (!window.matchMedia('(pointer:coarse)').matches) {
        setIsPointerDevice(false);
      }

      const dragEnterListener = () => setIsDragging(true);
      localRef.current?.addEventListener('dragenter', dragEnterListener);

      const dragLeaveListener = () => setIsDragging(false);
      localRef.current?.addEventListener('drop', dragLeaveListener);
      localRef.current?.addEventListener('dragleave', dragLeaveListener);
    }, []);

    const { inputId, errorId, describedBy } = useInputIds({ error: error?.title, hint: undefined });
    useValidationErrorEvent({ error: error?.title, name }, localRef);

    useEffect(() => {
      const totalSize = files.reduce((sum, currentValue) => sum + currentValue.size, 0);
      const maxTotalSizeBytes = maxTotalSize * 1e6;

      if (files.length > maxAmount) {
        setError({
          title: t('errors.maxAmountReached.title'),
          message: t('errors.maxAmountReached.message', { maxAmount }),
        });
        setHasError(true);
      } else if (totalSize > maxTotalSizeBytes) {
        setError({
          title: t('errors.maxTotalSizeReached.title'),
          message: t('errors.maxTotalSizeReached.message', { maxTotalSize }),
        });
        setHasError(true);
      } else {
        setError(null);
        setHasError(false);
      }
    }, [files, maxAmount, maxTotalSize]);

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      const maxFileSizeBytes = maxFileSize * 1e6;

      const newRejectedFiles: { name: string; message: string }[] = [];
      const newAcceptedFiles: File[] = [];

      const selectedFiles = e.target.files;
      if (selectedFiles) {
        Array.from(selectedFiles).forEach(file => {
          if (file.size > maxFileSizeBytes) {
            newRejectedFiles.push({ name: file.name, message: t('errors.fileTooBig') });
          } else if (!accept.includes(file.name.split('.').pop()?.toUpperCase() as fileType)) {
            newRejectedFiles.push({ name: file.name, message: t('errors.incorrectType') });
          } else {
            newAcceptedFiles.push(file);
          }
        });
      }

      //Limit total amount of files to protect memory
      setFiles([...files, ...newAcceptedFiles].slice(0, maxAmount + 10));
      setRejectedFiles([...rejectedFiles, ...newRejectedFiles]);

      //Clear hidden input as the values have been passed to the state
      if (localRef.current) {
        localRef.current.value = '';
      }
    };

    return (
      <Stack gap="6">
        <DragArea className={`${stitchesClassName} ${className}`} hasFocus={isFocused} hasDrag={isDragging}>
          <Stack gap="3" alignX="center">
            <DocumentIcon />
            <label>
              <StyledInput
                ref={mergedRef}
                type="file"
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                accept={accept.reduce(
                  (accumulator, currentValue) => accumulator + `.${currentValue.toLowerCase()},`,
                  '',
                )}
                onChange={changeHandler}
                aria-invalid={hasError}
                id={inputId}
                aria-describedby={describedBy}
                multiple
                disabled={hasError}
              />
              <Stack gap="1" alignX="center">
                <Text size="BodyXL" weight="bold">
                  {t('selectFile')} {!isPointerDevice ? t('orDrag') : ''}
                </Text>
                <Text size="BodyS" color="textLowEmphasis">
                  {`${new Intl.ListFormat(locale, { style: 'short', type: 'disjunction' }).format(accept)}, ${t(
                    'maxFileSize',
                    { maxFileSize },
                  )}`}
                </Text>
              </Stack>
            </label>
            {error ? (
              <div id={errorId}>
                <NotificationBox text={error.message} title={error.title} isAlert />
              </div>
            ) : (
              <div className={fakeButtonStyle}>
                <ButtonInner> {t('selectFile')}</ButtonInner>
              </div>
            )}
          </Stack>
        </DragArea>
        <Heading as={headingLevel} size="XS">
          {t('uploadedFiles', { amount: files.length })}
        </Heading>
        <Stack as="ul" gap="2">
          {files.map((file, i) => (
            <UploadItem
              key={`${file.name} ${i}`}
              name={file.name}
              state="success"
              onDelete={() => setFiles([...files.slice(0, i), ...files.slice(i + 1)])}
            />
          ))}
          {rejectedFiles.map((file, i) => (
            <UploadItem
              key={`${file.name} ${i}`}
              name={file.name}
              state="error"
              errorMessage={file.message}
              onDelete={() => setRejectedFiles([...rejectedFiles.slice(0, i), ...rejectedFiles.slice(i + 1)])}
            />
          ))}
        </Stack>
      </Stack>
    );
  },
);
