import type { CSSProperties } from '@stitches/react';
import { F } from 'ts-toolbelt';

import { getToken, defaultTheme } from '../../../stitches.config';
import type {
  CSSProperty,
  DynamicVariantProp,
  LinkColors,
  OptionalMediaQuery,
  Responsive,
  TextColors,
  Theme,
} from '../../../types';

export { supportsFlexboxGap } from '../../supports-flexbox-gap';

function isResponsive(value: unknown): value is Responsive<ValueType> {
  return typeof value === 'object' && value !== null;
}

/**
 * This function transforms the props that we get from our custom component API to an ordered selection. We need to
 * order it because Stitches requires a specific order in which the direct CSS properties must come first and the Media
 * Queries second.
 */
type Alias<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

type ExtractBreakpoint<T, K extends keyof T> = {
  [Property in K]: T[Property] extends object ? keyof T[Property] : never;
};

type DirectObject<T, K extends keyof T = keyof T> = {
  [Key in K as T[Key] extends object ? never : Key]: Extract<F.Narrow<T[Key]>, string | number | boolean>;
};

type MediaQueryObject<
  T,
  K extends keyof T = keyof T,
  B extends ExtractBreakpoint<T, K>[K] = ExtractBreakpoint<T, K>[K],
> = {
  [Breakpoint in B as `@${string & Breakpoint}`]: {
    [Property in K as T[Property] extends object
      ? T[Property][Breakpoint] extends infer Value
        ? Value extends string
          ? Property
          : Value extends boolean
            ? Property
            : Value extends number
              ? Property
              : never
        : never
      : never]: T[Property][Breakpoint];
  };
};

type TokenValue<T extends CSSProperty> = OptionalMediaQuery<
  T extends 'color' ? keyof TextColors | keyof LinkColors : never
>;

type Format = {
  [K in keyof CSSProperties]: DynamicVariantProp<K> | TokenValue<K>;
};

export function extractCssProps<T extends Format, K extends keyof T>(props: T) {
  const cssKeys = Object.keys(props) as K[];

  let directObj = {} as Alias<DirectObject<T>>;
  let mqObj = {} as Alias<MediaQueryObject<T>>;

  function deriveTokenValue(cssProperty: K, value: T[K] | T[K][string & keyof T[K]]) {
    if (typeof value === 'string') {
      switch (cssProperty) {
        case 'color':
          return value in defaultTheme['colors'] ? getToken('colors', value as keyof Theme['colors']) : value;
      }
    }
    return value;
  }

  cssKeys.forEach(cssProperty => {
    const value = props[cssProperty];
    if (isResponsive(value)) {
      const nestedKeys = Object.keys(value) as (string & keyof T[K])[];
      nestedKeys.forEach(breakpoint => {
        if (breakpoint === 'initial') {
          directObj = { ...directObj, [cssProperty]: deriveTokenValue(cssProperty, value[breakpoint]) };
        } else {
          const key = `@${breakpoint}` as keyof typeof mqObj;
          mqObj = {
            ...mqObj,
            [`@${breakpoint}`]: { ...mqObj[key], [cssProperty]: deriveTokenValue(cssProperty, value[breakpoint]) },
          };
        }
      });
    } else {
      directObj = { ...directObj, [cssProperty]: deriveTokenValue(cssProperty, props[cssProperty]) };
    }
  });

  return { ...directObj, ...mqObj };
}

/**
 *
 * @param props CssVariantProps
 *
 * This function tranforms our own Sparky Component API to a variant-compliant format that a Stitches component accepts
 * as props.
 */
type ValueType = string | number | boolean | undefined;
type TransformProps<T extends V | Responsive<V>, K extends keyof T = keyof T, V = ValueType> = {
  [Variant in K]: Extract<T[Variant], V> | Extract<T[Variant], Responsive<V>> extends infer Value
    ? { [NestedKey in keyof Value as `@${string & NestedKey}`]: Value[NestedKey] }
    : never;
};

export function extractVariantProps<T extends { [Key in K]?: V | Responsive<V> }, K extends keyof T, V = ValueType>(
  props: T,
) {
  const keys = Object.keys(props) as K[];

  return keys.reduce((acc, variant) => {
    const value = props[variant];

    if (isResponsive(value)) {
      const nestedEntries = Object.entries(value);
      const entries = nestedEntries.map(([key, value]) => [`@${key}`, value]);
      return { ...acc, [variant]: Object.fromEntries(entries) };
    } else {
      return { ...acc, [variant]: value };
    }
  }, {} as TransformProps<T>);
}

/**
 * This utility creates Stitches variants for Stitches components based on a set of theme tokens.
 * @param tokens An object with tokens from the theme
 * @param property The CSS property that we want to create the variant for
 * @returns An object with the variants for the given property and its corresponding tokens
 */
type CreatedVariants<T> = keyof T extends string ? Record<keyof T, Record<keyof CSSProperties, `$${keyof T}`>> : never;

export function createVariants<T extends Record<string, string | number>>(tokens: T, cssProperty: keyof CSSProperties) {
  const keys = Object.keys(tokens) as (keyof T)[];
  return keys.reduce((acc, key) => {
    return { ...acc, [key]: { [cssProperty]: '$' + String(key) } };
  }, {} as CreatedVariants<T>);
}
