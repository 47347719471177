import React from 'react';

type Props = {
  isLoading?: boolean;
};

/** @deprecated Use `Skeleton` or `SpinnerIcon` to indicate a loading state */
export const LoadingSpinner = React.forwardRef<SVGSVGElement, React.PropsWithChildren<Props>>(
  ({ children, isLoading }, ref) => {
    if (!isLoading) return null;

    const duration = (mult = 1.0) => mult * 1.5;

    const min = 4;
    const max = 12;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" ref={ref} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(0, -8)">
          <circle cx="50" cy="40.4" r="10" fill="#ebbd27">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur={duration(1.05)}
              repeatCount="indefinite"
              keyTimes="0;1"
              values={'90 50 50;450 50 50'}></animateTransform>
            <animate
              attributeName="r"
              dur={duration(1.05)}
              repeatCount="indefinite"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values={[max, min, max].join(';')}
              keySplines="0.2 0 0.8 1;0.2 0 0.8 1"></animate>
          </circle>

          <circle cx="50" cy="40.4" r="10" fill="#eb4356">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur={duration()}
              repeatCount="indefinite"
              keyTimes="0;1"
              values={'180 50 50;540 50 50'}></animateTransform>
            <animate
              attributeName="r"
              dur={duration()}
              repeatCount="indefinite"
              calcMode="spline"
              keyTimes="0;0.6;1"
              values={[max, min, max].join(';')}
              keySplines="0.2 0 0.8 1;0.2 0 0.8 1"></animate>
          </circle>

          <circle cx="50" cy="40.4" r="10" fill="#ef7a56">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur={duration(1.25)}
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 46 53;360 46 53"></animateTransform>
            <animate
              attributeName="r"
              dur={duration(1.25)}
              repeatCount="indefinite"
              calcMode="spline"
              keyTimes="0;0.45;1"
              values={[max * 0.9, min, max * 0.9].join(';')}
              keySplines="0.2 0 0.8 1;0.2 0 0.8 1"></animate>
          </circle>
        </g>
      </svg>
    );
  },
);
