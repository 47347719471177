import { AnchorHTMLAttributes, FC } from 'react';

import reactToText from 'react-to-text';

import { styled } from '../../stitches.config';
import { StyledOverlayButton, StyledOverlayBox } from '../ButtonOverlay/ButtonOverlay';

const StyledOverlayLink = styled('a', StyledOverlayButton, {
  color: 'inherit',
  textDecoration: 'none',
});

interface CompoundLinkOverlay {
  Link: typeof Link;
}

interface LinkProps
  extends Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'download' | 'href' | 'hrefLang' | 'rel' | 'target'> {
  /**
   * TODO: Custom type children to React.ReactNode or JSX.Element when we support
   * `asChild` prop.
   */
  children: React.ReactNode;
}

const Link: FC<LinkProps> = ({ children, ...anchorAttributes }) => (
  <StyledOverlayLink {...anchorAttributes} className="overlay-link" data-label={reactToText(children)}>
    {children}
  </StyledOverlayLink>
);

/**
 * For more information on this component and how it works, refer to the ButtonOverlay component.
 */
export const LinkOverlay: FC<React.PropsWithChildren<{ className?: never }>> & CompoundLinkOverlay = ({
  className = '',
  children,
}) => <StyledOverlayBox className={className}>{children}</StyledOverlayBox>;

LinkOverlay.Link = Link;

StyledOverlayLink.displayName = 'styled(OverlayLink)';
