import { forwardRef, InputHTMLAttributes, useRef } from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { CheckIcon } from '../../icons';
import { useI18nTranslation } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { TextProps } from '../../types';
import { mergeRefs } from '../../util';
import { Grid } from '../Grid/Grid';
import { InputBaseProps } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

type InputCheckboxHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur' | 'onFocus' | 'defaultChecked'
>;

export interface Props extends InputBaseProps, Omit<InputCheckboxHTMLAttributes, 'isReadOnly'> {
  isChecked?: boolean;
  weight?: TextProps['weight'];
}

export const StyledCheckedState = styled('div', {
  height: '20px',
  width: '20px',
  padding: '2px',
  borderRadius: '$s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  //hidden by default
  opacity: 0,

  //match input positioning
  marginTop: '2px',
  gridColumn: '1/2',
  gridRow: '1/2',

  variants: {
    isDisabled: {
      false: {
        backgroundColor: '$controlsActive',
      },
    },
  },
});

const LabelWrapper = styled('span', {
  paddingLeft: '$2',
  userSelect: 'none',
  variants: {
    isDisabled: {
      false: {
        cursor: 'pointer',
      },
    },
  },
});

export const StyledCheckbox = styled('input', {
  //reset styles
  appearance: 'none',
  display: 'block',
  outlineOffset: 0,

  border: '$borderWidths$s solid $formBorderDefault',
  height: '20px',
  width: '20px',
  borderRadius: '$s',
  cursor: 'pointer',
  background: '$backgroundPrimary',

  gridColumn: '1/2',
  gridRow: '1/2',

  marginTop: '2px', // Custom value; with top alignment it doesn't look right without spacing

  '&:focus-visible': {
    outline: '$outlineInputFocus',
    borderColor: '$borderFocus',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineInputFocus',
      borderColor: '$borderFocus',
    },
  },

  '&:hover:not(:disabled)': {
    boxShadow: '$shadowHover',
    borderColor: '$formBorderHover',
  },

  '&:checked:not(:disabled)': {
    border: 'none',
    background: 'transparent',
  },

  [`&:checked + ${StyledCheckedState}`]: {
    opacity: 1,
  },

  '&:disabled': {
    boxShadow: 'none',
    cursor: 'not-allowed',
    opacity: '$opacity50',
  },

  //Only make the label opacity50 if the checkbox isn't checked, for UX
  [`&:disabled:not(:checked) ~ ${LabelWrapper}`]: {
    opacity: '$opacity50',
  },

  [`&:disabled:checked + ${StyledCheckedState}`]: {
    opacity: '$opacity50',
  },

  variants: {
    isInvalid: {
      true: {
        boxShadow: '$shadowError',
        borderColor: '$formBorderError',
      },
    },
  },
});

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ error, hint, isChecked, isDisabled = false, isOptional, label, name, weight, ...restProps }, ref) => {
    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });
    const i18nOptionalText = useI18nTranslation('optional');

    const localRef = useRef<HTMLDivElement>(null);
    // Merge the local ref and forwarded ref so we can forward it *and* use it locally
    const mergedRef = mergeRefs([ref, localRef]);

    useValidationErrorEvent({ error, name }, localRef);

    return (
      <Stack gap="2">
        <label>
          <Grid gridTemplateColumns="min-content auto" gridTemplateRows="1fr">
            <StyledCheckbox
              aria-describedby={describedBy}
              aria-invalid={error ? true : undefined}
              checked={isChecked}
              data-label={label}
              disabled={isDisabled}
              id={inputId}
              isInvalid={!!error}
              name={name}
              ref={mergedRef}
              type="checkbox"
              {...restProps}
            />
            <StyledCheckedState isDisabled={isDisabled}>
              <CheckIcon color={isDisabled ? 'iconSecondary' : 'iconInverted'} size="small" />
            </StyledCheckedState>
            <LabelWrapper isDisabled={isDisabled}>
              <Text size="BodyM" color="textPrimary" weight={weight}>
                {label}
                {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
              </Text>
            </LabelWrapper>
          </Grid>
        </label>

        {error ? (
          <Error id={errorId} hasArrow={false}>
            {error}
          </Error>
        ) : null}
        {hint ? <Hint id={hintId}>{hint}</Hint> : null}
      </Stack>
    );
  },
);

StyledCheckedState.displayName = 'styled(CheckedState)';
LabelWrapper.displayName = 'LabelWrapper';
StyledCheckbox.displayName = 'styled(Checkbox)';
Checkbox.displayName = 'Checkbox';
