import { FC, ReactNode, useId } from 'react';

import * as UIPopover from '@radix-ui/react-popover';

import { CloseIcon } from '../../icons';
import { useI18nTranslation } from '../../providers/i18n';
import { styled, keyframes } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

/**
 * This component is based on the popover component from radix-ui. It pops up to the top by default, and will reverse
 * side when it detects collision. Source: https://www.radix-ui.com/docs/primitives/components/popover
 */

const slideDownOpen = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideDownClose = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0px)' },
  '100%': { opacity: 0, transform: 'translateY(10px)' },
});

const slideUpOpen = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideUpClose = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0px)' },
  '100%': { opacity: 0, transform: 'translateY(-10px)' },
});

const slideLeftOpen = keyframes({
  '0%': { opacity: 0, transform: 'translateX(10px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideLeftClose = keyframes({
  '0%': { opacity: 1, transform: 'translateX(0px)' },
  '100%': { opacity: 0, transform: 'translateX(-10px)' },
});

const slideRightOpen = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-10px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideRightClose = keyframes({
  '0%': { opacity: 1, transform: 'translateX(0px)' },
  '100%': { opacity: 0, transform: 'translateX(10px)' },
});

const StyledTrigger = styled(UIPopover.Trigger, {
  margin: 0,
  padding: 0,
  width: '100%',
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  textAlign: 'left',
});

const StyledArrow = styled(UIPopover.Arrow, { fill: '$backgroundDark' });
export const StyledContent = styled(UIPopover.Content, {
  backgroundColor: '$backgroundDark',
  color: '$textInverted',
  padding: '$3',
  zIndex: 1,
  borderRadius: '$s',
  '@safeMotion': {
    animationDuration: '0.6s',
  },
  maxWidth: '100vw',

  float: 'left',

  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  animationFillMode: 'forwards',
  '&[data-side="bottom"][data-state="open"]': { animationName: slideDownOpen },
  '&[data-side="bottom"][data-state="closed"]': { animationName: slideDownClose },

  '&[data-side="top"][data-state="open"]': { animationName: slideUpOpen },
  '&[data-side="top"][data-state="closed"]': { animationName: slideUpClose },

  '&[data-side="left"][data-state="open"]': { animationName: slideLeftOpen },
  '&[data-side="left"][data-state="closed"]': { animationName: slideLeftClose },

  '&[data-side="right"][data-state="open"]': { animationName: slideRightOpen },
  '&[data-side="right"][data-state="closed"]': { animationName: slideRightClose },

  variants: {
    size: {
      large: {
        width: '400px',
      },
      small: {
        width: '200px',
      },
    },
  },
});

export const CloseWrapper = styled('div', {
  float: 'right',
  marginTop: '-$2',
  marginRight: '-$2',
});

export const Popover: FC<React.PropsWithChildren<Props>> = ({
  size = 'large',
  side = 'top',
  trigger,
  children,
  title,
}) => {
  const closeText = useI18nTranslation('close');
  const titleId = useId();

  return (
    <UIPopover.Root>
      {typeof trigger === 'string' ? (
        <StyledTrigger>{trigger}</StyledTrigger>
      ) : (
        <UIPopover.Trigger asChild>{trigger}</UIPopover.Trigger>
      )}
      <StyledContent sideOffset={-5} side={side} size={size} aria-labelledby={titleId}>
        <VisuallyHidden id={titleId}>{title}</VisuallyHidden>
        <CloseWrapper>
          <UIPopover.Close asChild>
            <IconButton label={closeText} color="inverted">
              <CloseIcon size="small" />
            </IconButton>
          </UIPopover.Close>
        </CloseWrapper>
        <Text size="BodyXS" weight="bold">
          {children}
        </Text>
        <StyledArrow offset={10} />
      </StyledContent>
    </UIPopover.Root>
  );
};

StyledTrigger.displayName = 'styled(Trigger)';
StyledArrow.displayName = 'styled(Arrow)';
StyledContent.displayName = 'styled(Content)';
CloseWrapper.displayName = 'CloseWrapper';

type PopoverSizeVariants = TransformStitchesToSparky<typeof StyledContent>;

interface Props extends Pick<PopoverSizeVariants, 'size'> {
  trigger: ReactNode;
  /** The title used to announce the popover dialog to screen reader users */
  title: string;
  /** The direction to which the Popover opens. If there is no space available, it will be opened to the opposite side. */
  side?: 'top' | 'right' | 'bottom' | 'left';
}
