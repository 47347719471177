/**
 * Adaptation of the original VisitorIdentification component:
 *
 * - https://github.com/Sitecore/jss/blob/v21.0.1/packages/sitecore-jss-react/src/components/VisitorIdentification.tsx
 * - https://docs.acc.eneco.nl/docs/decisions/sitecore-jss/#jss-react--nextjs-sdks
 */

import React from 'react';

import { useLayoutData } from './SitecoreContext';

interface VisitorIdentificationProps {
  defer?: boolean;
}

let emittedVI = false;
const VIComponent: React.FC<VisitorIdentificationProps> = props => {
  const { context: sitecoreContext } = useLayoutData();

  if (emittedVI || typeof document === 'undefined' || !sitecoreContext.visitorIdentificationTimestamp) {
    // Don't emit VI script and meta tag if we've already done so,
    // aren't rendering client-side, or don't have a VI timestamp.
    return null;
  }
  emittedVI = true;
  // ssticvsr Sitecore Script To Identify Customer VS Robot
  // original name VisitorIdentification is blocked by browser clients
  const script = document.createElement('script');
  script.src = '/layoutsdxp/system/ssticvsr.js';
  script.type = 'text/javascript';
  script.defer = !!props.defer;

  const meta = document.createElement('meta');
  meta.name = 'VIcurrentDateTime';
  meta.content = sitecoreContext.visitorIdentificationTimestamp.toString();

  const head = document.querySelector('head');
  head && head.appendChild(script);
  head && head.appendChild(meta);

  return null;
};

VIComponent.displayName = 'VisitorIdentification';

/**
 * Reset the VI switch to allow VIComponent to render
 * Mainly for unit testing
 */
export function resetEmittedVI() {
  emittedVI = false;
}

export const VisitorIdentification = VIComponent;
