import deBE from './de-BE.json';
import deNL from './de-NL.json';
import enGB from './en-GB.json';
import enNL from './en-NL.json';
import frBE from './fr-BE.json';
import nlBE from './nl-BE.json';
import nlNL from './nl-NL.json';

const translations = {
  'de-BE': deBE,
  'de-NL': deNL,
  'en-GB': enGB,
  'en-NL': enNL,
  'fr-BE': frBE,
  'nl-BE': nlBE,
  'nl-NL': nlNL,
};

export default (locale: keyof typeof translations) => {
  return translations[locale];
};
