import { FC, ReactNode } from 'react';

import { Unstyled } from '@custom-components/Unstyled';

const LUCKY_ORANGE_SENSITIVE_CLASS = 'lo_sensitive';

export type SensitiveContentComponentProps = {
  children: ReactNode;
  /** used as a replacement of any sensitive selected_content in events, e.g. with radio buttons, checkboxes or select lists that sent label / value to tracking */
  contentMask?: string;
};

export function getContentMask(element: HTMLElement): string | undefined {
  return (element.closest('[data-sensitive-content-mask]') as HTMLElement)?.dataset.sensitiveContentMask;
}

export const SensitiveContentComponent: FC<SensitiveContentComponentProps> = ({ children, contentMask }) => {
  return (
    <Unstyled data-sensitive-content data-sensitive-content-mask={contentMask} className={LUCKY_ORANGE_SENSITIVE_CLASS}>
      {children}
    </Unstyled>
  );
};
