import { FC } from 'react';

import { Cache, Middleware, SWRConfig, unstable_serialize, useSWRConfig, State } from 'swr';

interface P extends State {
  __age?: number;
}

const CacheProvider = (cache: Cache): Cache => ({
  get: key => cache.get(key),
  set: (key, value: P) => {
    if (value && typeof value !== 'number' && typeof value !== 'boolean') {
      value.__age = Date.now();
    }
    return cache.set(key, value);
  },
  delete: key => cache.delete(key),
  keys: () => cache.keys(),
});

export const SWRCacheProvider: FC<React.PropsWithChildren> = ({ children }) => (
  <SWRConfig value={{ provider: CacheProvider }}>{children}</SWRConfig>
);

// Middleware to check if we should trigger a revalidation as the cache is expired.
export const revalidateIfCacheIsExpired: (cacheMaxAge?: number) => Middleware =
  (maxAge = 15_000) =>
  useSWRNext =>
  (key, fetcher, config) => {
    const { cache } = useSWRConfig();

    const value: P = cache.get(unstable_serialize(key)) as P;
    const cacheMaxAge = value?.__age && value.__age + maxAge;
    const revalidateIfStale = !cacheMaxAge || cacheMaxAge < Date.now();

    return useSWRNext(key, fetcher, {
      ...config,
      revalidateIfStale,
    });
  };
