// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgCheckIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M8.997 18a1 1 0 0 1-.71-.29l-3.997-4a1.004 1.004 0 0 1 1.42-1.42l3.287 3.3 9.286-9.3a1.003 1.003 0 1 1 1.419 1.42l-9.995 10a1 1 0 0 1-.71.29Z"
    />
  </Icon>
);
export default SvgCheckIcon;
SvgCheckIcon.displayName = 'SvgCheckIcon';
