import React from 'react';

import { FormatOptions } from './useNumberInput';
import { Locale } from '../providers/locale';

interface FormatValue {
  options: FormatOptions;
  value: string;
  locale?: Locale;
}

interface ReverseNumber {
  options: FormatOptions;
  formattedValue?: string;
  replaceInteger?: boolean;
  locale: Locale;
}

interface Parts {
  options?: FormatOptions;
  value: number | string;
  locale?: Locale;
  parts?: Intl.NumberFormatPartTypes[];
}

interface GetBounds {
  options: FormatOptions;
  value?: string;
  locale: Locale;
}

interface CorrectCaretPosition {
  options: FormatOptions;
  expectedCaretPosition: number;
  event: React.SyntheticEvent<HTMLInputElement>;
  locale: Locale;
  value?: string;
}

type RemoveFormatting = Omit<ReverseNumber, 'replaceInteger'>;

/**
 * Create a string numeric literal that can safely be cast to a Number
 */
export const castToNumericLiteral = (value: string) => {
  if (value.startsWith('.')) return `0${value}`;
  if (value.endsWith('.')) return value.slice(0, -1);
  return value;
};

/**
 * Force a value between a min and a max value
 */
export const clamp = (min: number, max: number, value: number) => Math.max(min, Math.min(value, max));

/**
 * Get an Array of NumberFormat parts for a numeric value
 */
export const getParts = ({ locale = 'nl-NL', options = {}, value }: Parts): Intl.NumberFormatPart[] => {
  if (value === '') return [];

  // figure out what the thousands and decimal separators are for this locale
  const [thousandsSeparator, decimalSeparator] = (1000.1).toLocaleString(locale).replace(/\d/g, '').split('');

  // the given value should always be a (string-type) float value with a . as decimal separator
  const [integers, fraction = ''] = value.toString().split('.');

  const parts = parseInt(integers) // make sure integers is an integer
    .toLocaleString(locale, { useGrouping: options.useGrouping }) // so we can format it to a localestring
    .split(thousandsSeparator) // and optionally get the separate thousands parts
    .map(
      thousandsPart =>
        [
          { type: 'group', value: thousandsSeparator }, // only to add the group separator
          { type: 'integer', value: thousandsPart },
        ] as Intl.NumberFormatPart[],
    )
    .flat()
    .slice(1);

  if (fraction) {
    parts.push({ type: 'decimal', value: decimalSeparator }, { type: 'fraction', value: fraction });
  }

  if (options.currency) {
    const currencyString = (1.2).toLocaleString(locale, {
      currency: options.currency,
      style: 'currency',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
    const currencySymbol = currencyString.replace(/\d/g, '').replace(decimalSeparator, '');

    // either push or unshift to parts based on the position of the currency symbol
    parts[currencyString.indexOf(currencySymbol) === 0 ? 'unshift' : 'push']({
      type: 'currency',
      value: currencySymbol,
    });
  }

  return parts;
};

/**
 * Clamp a value to its lowest point if its defined
 */
export const getLowest = (val: number, min?: number) => (min !== undefined ? Math.max(min, val) : val);

/**
 * Clamp a value to its highest point if its defined
 */
export const getHighest = (val: number, max?: number) => (max !== undefined ? Math.min(max, val) : val);

/**
 * Sets the position of the caret. Taken from https://github.com/s-yadav/react-number-format/blob/master/src/utils.js
 */
export const setCaretPosition = (el: HTMLInputElement, caretPos: number) => {
  // eslint-disable-next-line no-self-assign
  el.value = el.value;
  // ^ this is used to not only get 'focus', but to make sure we don't have it everything -selected-

  if (el !== null) {
    if (el.selectionStart || el.selectionStart === 0) {
      el.focus();
      el.setSelectionRange(caretPos, caretPos);
      return true;
    }

    // fail city, fortunately this never happens (as far as I've tested) :)
    el.focus();
    return false;
  }
};

const removeFormatting = ({ formattedValue = '', options, locale = 'nl-NL' }: RemoveFormatting) => {
  const { unit, ...formatOptions } = options;
  const parts = getParts({ value: 11111.11, locale, options: formatOptions });
  const decimal = parts.find(p => p.type === 'decimal')?.value || ',';
  const group = parts.find(p => p.type === 'group')?.value || '.';

  return formattedValue
    .replace(new RegExp(unit + '$'), '') // Remove last occurence of the unit
    .split(group)
    .join('') // Remove all groups
    .replace(decimal, '.') // Replace the decimal sign with a dot
    .replace(/[^\d.-]/g, ''); // Remove all non-numeric characters except for the decimal dot
};

/**
 * Reverse a formatted value to a numeric string
 */
export const formattedToString = ({
  formattedValue = '',
  replaceInteger = false,
  options,
  locale = 'nl-NL',
}: ReverseNumber) => {
  if (formattedValue === '') return '';

  const replacedValue = removeFormatting({ formattedValue, locale, options });

  // Early return for no value return
  if (replacedValue === '' || isNaN(Number(replacedValue))) return '';

  // If there is no integer and all decimals are 0 we return ""
  const isEmptyValue = replacedValue.startsWith('.')
    ? replacedValue
        .slice(replacedValue.indexOf('.') + 1, replacedValue.length - 1)
        .split('')
        .every(v => v === '0')
    : false;

  // We want 1.14 -> Backspace -> 0.14 -> 1.14 when user removes and re-enters integer, so replace whole integer if flag is true
  const modifiedValue = replaceInteger
    ? replacedValue.slice(0, 1) + replacedValue.slice(2, replacedValue.length)
    : replacedValue;

  return isEmptyValue ? '' : modifiedValue;
};

/**
 * Format a numeric string value
 */
export const formatValue = ({ options, value, locale = 'nl-NL' }: FormatValue) => {
  if (value === '') return '';

  // Formatting removes zeroes that trail on the fraction (1.100 -> 1.1). This becomes a problem when we try to write 1.0[1].
  const [integer, decimals = ''] = value.split('.');
  const maxDigits = options.maximumFractionDigits || options.minimumFractionDigits || 0;
  const minDigits = Number(options?.minimumFractionDigits);
  const minAmountDecimals = clamp(minDigits, maxDigits, decimals.length);
  const instance = new Intl.NumberFormat(locale, { ...options, minimumFractionDigits: minAmountDecimals });

  // We need some specific behaviour when the value ends with a decimal
  if (value.endsWith('.')) {
    const formattedInteger = instance.format(Number(integer));

    // If we cant have digits, we only format the integer to add grouping
    if (maxDigits === 0) {
      return formattedInteger;
    }

    // `NumberFormat.format()` removes decimal point when no fractions, so manually add it
    if (minDigits === 0) {
      const parts = instance.formatToParts(Number(integer));

      // Manually create and find the multilanguage decimal to insert after the last integer.
      const mockParts = instance.formatToParts(1.11);
      const mockDecimal = mockParts.find(p => p.type === 'decimal');

      const reversedIntegerIndex = parts
        .slice()
        .reverse()
        .findIndex(p => p.type === 'integer');

      const lastIntegerIndex = parts.length - 1 - reversedIntegerIndex;

      if (lastIntegerIndex > -1 && mockDecimal) {
        const newParts = [...parts];
        newParts.splice(lastIntegerIndex + 1, 0, mockDecimal);
        return newParts.map(p => p.value).join('');
      }

      return formattedInteger;
    }

    return formattedInteger;
  }

  // @ts-ignore `format` also accepts a string
  return instance.format(value);
};

/**
 * Get the positions where the caret can go
 */
export const getBounds = ({ options, locale, value }: GetBounds) => {
  const { unit, ...restOptions } = options;

  // Create a mock value to find currency prefix
  const parts = getParts({ locale, options: restOptions, value: '1' });
  const currency = parts.find(p => p.type === 'currency')?.value || '';
  const suffix = options.unit || '';

  const definedValue = value === undefined ? new Intl.NumberFormat(locale, restOptions).format(1.11) : value;

  // Remove currency and suffix
  const stripped = definedValue
    .replace(currency, '')
    .replace(new RegExp(suffix + '$'), '')
    .trim();

  const start = definedValue.indexOf(stripped);
  const end = start + stripped.length;
  return { start, end, stripped };
};

/**
 * Corrects the caret when it goes out of bounds
 */
export const correctCaretPosition = ({ options, locale, expectedCaretPosition, event }: CorrectCaretPosition) => {
  const el = event.target as EventTarget & HTMLInputElement;

  const { start, end } = getBounds({ options, locale, value: el.value });
  const newPos = clamp(start, end, expectedCaretPosition);

  if (newPos !== expectedCaretPosition) {
    event.preventDefault();
    setCaretPosition(el, newPos);
  }
};
