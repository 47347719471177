import React, { ReactNode } from 'react';

import * as icons from '../icons/eneco';

type IconType = React.FC<React.SVGProps<SVGSVGElement>> & {
  displayName: `Svg${string}`;
};

/**
 * Determine whether the children (or any of their subchildren) contain a Sparky icon
 *
 * @param children List of React children
 * @returns boolean indicating whether the children (or any of their subchildren) contain a Sparky icon
 */
export function hasIcon(children: ReactNode): boolean {
  let hasIcon = false;
  const iconNames = Object.keys(icons);

  const checkChildren = (child: ReactNode): void => {
    if (!hasIcon && React.isValidElement(child)) {
      if (
        (child.type as IconType).displayName &&
        iconNames.includes((child.type as IconType).displayName?.replace(/^Svg/, ''))
      ) {
        hasIcon = true;
      } else {
        React.Children.forEach(child.props.children, checkChildren);
      }
    }
  };

  React.Children.forEach(children, checkChildren);

  return hasIcon;
}
