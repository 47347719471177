import { useEffect } from 'react';

interface Props {
  error?: string;
  name: string;
}

export const useValidationErrorEvent = ({ error, name }: Props, ref: React.MutableRefObject<HTMLElement | null>) => {
  useEffect(() => {
    if (!error) return;

    ref.current?.dispatchEvent(
      new CustomEvent('validationError', {
        bubbles: true,
        detail: {
          name,
          error,
        },
      }),
    );
  }, [error, name, ref]);
};
