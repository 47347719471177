import { Dispatch, SetStateAction, createContext } from 'react';

type TrackingProviderContext = {
  scope: string;
  setTrackingVariant: Dispatch<SetStateAction<string | undefined>>;
};

export const TrackingContext = createContext<TrackingProviderContext>({
  scope: '_root',
  setTrackingVariant: (variant): string | null => null,
});
