// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgRedeliveryIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M22 11.74A9 9 0 0 1 13 21a8.86 8.86 0 0 1-5.52-1.91 1 1 0 0 1 1.24-1.57A6.9 6.9 0 0 0 13 19a7 7 0 0 0 7-7.1A7.09 7.09 0 0 0 13.1 5 7 7 0 0 0 6 12h1.79a.5.5 0 0 1 .36.85l-2.79 2.8a.501.501 0 0 1-.71 0l-2.79-2.8a.49.49 0 0 1 .35-.85H4a9 9 0 0 1 9.26-9A9.11 9.11 0 0 1 22 11.74ZM13.69 8a.5.5 0 0 0-.59.16l-3 4a.5.5 0 0 0 .4.8h1.81L12 15.44a.5.5 0 0 0 .31.52.432.432 0 0 0 .19 0 .511.511 0 0 0 .4-.2l3-4a.5.5 0 0 0-.4-.8h-1.81l.31-2.4a.5.5 0 0 0-.31-.56Z"
    />
  </Icon>
);
export default SvgRedeliveryIcon;
SvgRedeliveryIcon.displayName = 'SvgRedeliveryIcon';
