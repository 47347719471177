/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Safely retrieves a nested property value from the given object based on the specified key.
 * The key can be a dot-separated string or an array of strings and numbers.
 * If the key is not found, or if the object is undefined, a default value is returned.
 * 
 * @template T The expected return type of the function.
 * @param {object | undefined} object The object from which to retrieve the property.
 * @param {string | Array<string | number>} key The key path to the property in the object, either as a dot-separated string or an array.
 * @param {T} [defaultValue] An optional default value to return if the key is not found.
 * @returns {T | undefined} The value of the nested property if found, otherwise defaultValue or undefined.
 * @throws {Error} If the key is undefined.
 */
export const delve = <T = any>(
  object: object | undefined,
  key: string | Array<string | number>,
  defaultValue?: T
): T | undefined => {
  if (key === undefined) {
    throw new Error('Key cannot be undefined');
  }

  // Return default value for empty string key or undefined object
  if (!object || key === '' || typeof object !== 'object') {
    return defaultValue;
  }

  // Convert string keys to array
  if (typeof key === 'string') {
    key = key.split('.');
  }

  let result: any = object;
  for (const k of key) {
    if (typeof result !== 'object' || result === null || !(k in result)) {
      return defaultValue;
    }
    result = result[k];
  }

  return result === undefined ? defaultValue : result;
}
