export const GRID = 4;
export const BASE_FONT_SIZE = 10;
export const BASE_FONT_RELATIVE = `${(BASE_FONT_SIZE / 16) * 100}%`;

/**
 * Optional design tokens
 * --------
 * Base colors used in aliasses for general decision tokens
 */
export const brandColors = {
  brandRed: '#E5384C',
  brandOrange: '#EF7A56',
  brandDarkRed: '#D21242',
  brandLightRed: '#F9C7CC',
};

export const brandGradients = {
  brandGradientStart: brandColors.brandRed,
  brandGradientEnd: brandColors.brandOrange,
  brandGradientDark: 'linear-gradient(90deg, #B4334E 0%, #B65A4E 100%)',
  brandGradient: `linear-gradient(90deg, ${brandColors.brandRed} 0%, ${brandColors.brandOrange} 100%)`,
};

export const brandSupport = {
  darkerRed: '#A63B3C',
  darkPurple: '#300C38',
};

export const neutralColors = {
  neutralWhite: '#FFFFFF',
  neutral25: '#F8F8F8',
  neutral100: '#F3F3F3',
  neutral200: '#E0E0E0',
  neutral300: '#CBCBCB',
  neutral400: '#8C8B8D',
  neutral500: '#6F6F6F',
  neutral900: '#1A171B',
};

// For a hex to percentage opacity conversion, see https://davidwalsh.name/hex-opacity
export const opacityColors = {
  whiteOpacity15: `${neutralColors.neutralWhite}15`,
  whiteOpacity30: `${neutralColors.neutralWhite}30`,
  blackOpacity40: `#00000040`,
  blackOpacity70: `${neutralColors.neutral900}70`,
};

export const secondaryColors = {
  // Purple
  purple50: '#F4F3FA',
  purple100: '#E8E6F4',
  purple300: '#C3C6E5',
  purple500: '#8D8CC6',
  purple700: '#8586CF',
  purple800: '#655790',
  purple900: '#3E235B',

  // Pink
  pink50: '#FDEDF1',
  pink100: '#FBDBE3',
  pink300: '#F6B1C6',
  pink500: '#EC6C87',
  pink700: '#C44B6B',
  pink800: '#A04967',
  pink900: '#552748',

  // Blue
  blue50: '#F0FAF8',
  blue100: '#E1F4F1',
  blue300: '#B1DDDF',
  blue500: '#72BDCE',
  blue700: '#3E798D',
  blue900: '#09354B',

  // Bluegray
  blueGray50: '#EBF6F0',
  blueGray100: '#D7EDE1',
  blueGray300: '#BDE0D7',
  blueGray500: '#85BAB0',
  blueGray700: '#557C77',
  blueGray900: '#243D3D',

  // Green
  green50: '#F2F7EC',
  green100: '#E4EFD8',
  green300: '#CDE3BB',
  green500: '#7EC389',
  green700: '#4E9D5E',
  green800: '#2C6F49',
  green900: '#0A4033',

  // Yellow
  yellow50: '#FFFAF0',
  yellow100: '#FEF4E0',
  yellow300: '#FDE8B6',
  yellow500: '#FCCA6D',
  yellow700: '#CE7731',
  yellow900: '#501318',
};

// General decision tokens

export const backgroundColors = {
  backgroundPrimary: neutralColors.neutralWhite,
  backgroundSecondary: neutralColors.neutral100,
  backgroundTertiary: neutralColors.neutral200,
  backgroundBrand: brandGradients.brandGradient,
  backgroundScrim: opacityColors.blackOpacity70,
  backgroundDark: neutralColors.neutral900,
  backgroundPressed: neutralColors.neutral100,
};

export const backgroundColoredColors = {
  backgroundVarOne: secondaryColors.purple100,
  backgroundVarTwo: secondaryColors.pink100,
  backgroundVarThree: secondaryColors.blue100,
  backgroundVarFour: secondaryColors.blueGray100,
  backgroundVarFive: secondaryColors.green100,
  backgroundVarSix: secondaryColors.yellow100,
};

export const backgroundSitecoreColors = {
  backgroundCMSVarOne: secondaryColors.purple100,
  backgroundCMSVarTwo: secondaryColors.pink100,
  backgroundCMSVarThree: secondaryColors.blue100,
  backgroundCMSVarFour: secondaryColors.blueGray100,
  backgroundCMSVarFive: secondaryColors.green100,
  backgroundCMSVarSix: secondaryColors.yellow100,
  backgroundCMSVarSeven: secondaryColors.purple900,
  backgroundCMSVarEight: secondaryColors.purple500,
};

export const textColors = {
  textPrimary: neutralColors.neutral900,
  textInverted: neutralColors.neutralWhite,
  textBrand: brandColors.brandDarkRed,
  textOnBackgroundVarOne: secondaryColors.purple900,
  textOnBackgroundVarTwo: secondaryColors.pink900,
  textOnBackgroundVarThree: secondaryColors.blue900,
  textOnBackgroundVarFour: secondaryColors.blueGray900,
  textOnBackgroundVarFive: secondaryColors.green900,
  textOnBackgroundVarSix: secondaryColors.yellow900,
  textLowEmphasis: neutralColors.neutral500,
  textHighlightVarOne: secondaryColors.purple700,
  textHighlightVarTwo: secondaryColors.pink700,
  textHighlightVarThree: secondaryColors.blue700,
  textHighlightVarFour: secondaryColors.blueGray700,
  textHighlightVarFive: secondaryColors.green700,
  textHighlightVarSix: secondaryColors.yellow700,
};

export const iconColors = {
  iconPrimary: neutralColors.neutral900,
  iconSecondary: neutralColors.neutral500,
  iconTertiary: neutralColors.neutral900,
  iconInverted: neutralColors.neutralWhite,
  iconBrand: brandColors.brandDarkRed,
  iconGas: secondaryColors.purple700,
  iconHeat: secondaryColors.pink700,
  iconCooling: secondaryColors.blue700,
  iconTotal: secondaryColors.blueGray700,
  iconElectricity: secondaryColors.green700,
  iconSolar: secondaryColors.yellow700,
  iconWater: secondaryColors.blue700,
  iconOnBackgroundVarOne: secondaryColors.purple900,
  iconOnBackgroundVarTwo: secondaryColors.pink900,
  iconOnBackgroundVarThree: secondaryColors.blue900,
  iconOnBackgroundVarFour: secondaryColors.blueGray900,
  iconOnBackgroundVarFive: secondaryColors.green900,
  iconOnBackgroundVarSix: secondaryColors.yellow900,
  currentColor: 'currentColor',
};

export const borderColors = {
  borderDividerLowEmphasis: neutralColors.neutral200,
  borderDividerMediumEmphasis: neutralColors.neutral400,
  borderDividerHighEmphasis: neutralColors.neutral900,
  borderFocus: neutralColors.neutral900,
  borderSelected: secondaryColors.green500,
  outlineHover: neutralColors.neutral300,
};

export const linkColors = {
  linkBrand: textColors.textBrand,
  linkPrimary: textColors.textPrimary,
  linkSecondary: textColors.textLowEmphasis,
  linkDisabled: neutralColors.neutral300,
  linkInverted: textColors.textInverted,
};

export const controlColors = {
  controlsActive: secondaryColors.green700,
  controlsInactive: neutralColors.neutral200,
  controlsKnob: neutralColors.neutralWhite,
};

export const feedbackColors = {
  feedbackError: brandColors.brandDarkRed,
  feedbackSuccess: secondaryColors.green700,
  feedbackWarning: secondaryColors.yellow700,
  feedbackInfo: secondaryColors.blue700,
  feedbackBackgroundError: neutralColors.neutral100,
  feedbackBackgroundSuccess: secondaryColors.green100,
  feedbackBackgroundWarning: secondaryColors.yellow100,
  feedbackBackgroundInfo: secondaryColors.blue100,
};

export const formColors = {
  formBorderDefault: neutralColors.neutral500,
  formBorderError: brandColors.brandDarkRed,
  formBorderHover: neutralColors.neutral900,
  formErrorMessageBackground: brandSupport.darkerRed,
  formOutlineError: brandColors.brandLightRed,
};

export const graphsColors = {
  graphsTotal: secondaryColors.blueGray500,
  graphsGasPrimary: secondaryColors.purple800,
  graphsGasSecondary: secondaryColors.purple900,
  graphsElectricityPrimary: secondaryColors.green500,
  graphsElectricitySecondary: secondaryColors.green800,
  graphsSolarPrimary: secondaryColors.yellow500,
  graphsSolarSecondary: secondaryColors.yellow300,
  graphsWarmthPrimary: secondaryColors.pink800,
  graphsWaterPrimary: secondaryColors.blue700,
  graphsFixedCosts: neutralColors.neutral400,
  graphsEstimatedPrimary: neutralColors.neutral100,
  graphsEstimatedSecondary: neutralColors.neutral500,
  graphComparePrimary: secondaryColors.pink800,
  graphCompareSecondary: secondaryColors.blue900,
  graphCompareStickerPositive: secondaryColors.green700,
  graphCompareStickerNeutral: secondaryColors.blue700,
  graphCompareStickerNegative: secondaryColors.yellow700,
  graphCompareInnerTextColor: textColors.textInverted,
};

export const globalThemeColors = {
  ...neutralColors,
  ...opacityColors,
  ...brandColors,
  ...brandGradients,
  ...backgroundColors,
  ...backgroundColoredColors,
  ...backgroundSitecoreColors,
  ...textColors,
  ...iconColors,
  ...borderColors,
  ...linkColors,
  ...controlColors,
  ...feedbackColors,
  ...formColors,
  ...graphsColors,
};

export const boxShadows = {
  xs: '0 3px 5px rgba(26, 23, 27, 0.2)',
  s: '0 5px 10px rgba(26, 23, 27, 0.1)',
  m: '0 10px 15px rgba(26, 23, 27, 0.05)',
  l: '0 10px 30px rgba(26, 23, 27, 0.2)',
};

export const radii = {
  none: '0',
  s: `${GRID}px`,
  m: `${GRID * 2}px`,
  l: `${GRID * 3}px`,
  round: '9999px',
};

export const borderWidths = {
  none: '0',
  s: '1px',
  m: '2px',
  l: '3px',
};

export const transitions = {
  easeQuick: '0.2s ease',
  easeMedium: '0.3s ease',
};

export const borderShadows = {
  shadowHover: `0 0 0 ${borderWidths.l} ${borderColors.outlineHover}`,
  shadowError: `0 0 0 ${borderWidths.l} ${formColors.formOutlineError}`,
  shadowSelected: `0 0 0 ${borderWidths.m} ${borderColors.borderSelected}`,
};

export const outlines = {
  outlineFocus: `${borderWidths.m} solid ${borderColors.borderFocus}`,
  outlineInputFocus: `${borderWidths.s} solid ${borderColors.borderFocus}`,
};

export const opacities = {
  opacity50: '0.5',
};

export const fontSizes = {
  12: '1.2rem',
  14: '1.4rem',
  16: '1.6rem',
  18: '1.8rem',
  20: '2rem',
  24: '2.4rem',
  32: '3.2rem',
  40: '4rem',
  48: '4.8rem',
  64: '6.4rem',
  80: '8rem',

  '3XS': '1.6rem',
  '2XS': '1.8rem',
  XS: '2rem',
  S: '2.4rem',
  M: '3.2rem',
  L: '4rem',
  XL: '4.8rem',
  '2XL': '6.4rem',
  '3XL': '8rem',

  BodyXS: '1.2rem',
  BodyS: '1.4rem',
  BodyM: '1.6rem',
  BodyL: '1.8rem',
  BodyXL: '2.0rem',
};

export const fontWeights = {
  bodyRegular: '300',
  bodyBold: '500',
  headingLight: '500',
  headingRegular: '700',
};

export const lineHeights = {
  4: '1.6rem',
  5: '2rem',
  6: '2.4rem',
  7: '2.8rem',
  9: '3.6rem',
  10: '4rem',
  12: '4.8rem',
  16: '6.4rem',
  20: '8rem',
};

export const letterSpacings = {
  narrowM: '-2px',
  narrowS: '-1px',
  normal: '0',
  wide: '0.3px',
};
