// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDotIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <circle cx={12} cy={12} r={7.5} />
  </Icon>
);
export default SvgDotIcon;
SvgDotIcon.displayName = 'SvgDotIcon';
