// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDeleteIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M18.5 8.15 13.775 3.5v3.15c0 .417.146.77.438 1.063.291.291.645.437 1.062.437H18.5ZM18 19.075l-1.575 1.55a.82.82 0 0 1-.525.212.677.677 0 0 1-.525-.212.72.72 0 0 1-.225-.525c0-.2.075-.375.225-.525L16.95 18l-1.575-1.575a.72.72 0 0 1-.225-.525c0-.2.075-.375.225-.525a.72.72 0 0 1 .525-.225c.2 0 .375.075.525.225L18 16.95l1.575-1.575a.72.72 0 0 1 .525-.225c.2 0 .375.075.525.225.15.15.22.325.212.525a.82.82 0 0 1-.212.525L19.075 18l1.55 1.575a.82.82 0 0 1 .212.525.677.677 0 0 1-.212.525.677.677 0 0 1-.525.212.82.82 0 0 1-.525-.212L18 19.075ZM5.5 22c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-17c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h8.4c.2 0 .396.042.588.125.191.083.354.192.487.325l4.575 4.575c.133.133.242.296.325.487.083.192.125.388.125.588v5.05a5.707 5.707 0 0 0-1-.3 5.211 5.211 0 0 0-1.025-.1c-1.45 0-2.683.508-3.7 1.525-1.017 1.017-1.525 2.25-1.525 3.7 0 .783.167 1.525.5 2.225.333.7.792 1.3 1.375 1.8H5.5Z" />
  </Icon>
);
export default SvgDeleteIcon;
SvgDeleteIcon.displayName = 'SvgDeleteIcon';
