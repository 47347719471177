import React, { FC } from 'react';

import { styled } from '../../stitches.config';

const StyledUnorderedList = styled('ul', {
  paddingX: '$6',
  listStyle: 'disc',
});

type CompoundBulletListProps = {
  Item: typeof Item;
};

export const BulletList: FC<React.PropsWithChildren<unknown>> & CompoundBulletListProps = ({ children }) => {
  return <StyledUnorderedList>{children}</StyledUnorderedList>;
};

const Item: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <li>{children}</li>;
};

BulletList.Item = Item;
StyledUnorderedList.displayName = 'styled(BulletList)';
