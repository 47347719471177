import { forwardRef, InputHTMLAttributes } from 'react';

import { Input, InputBaseProps } from '../Input/Input';

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputEmailHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'placeholder' | 'value' /** used for controlled inputs */ | 'onChange' | 'onBlur' | 'onFocus' | 'defaultValue'
>;

/** Allowed autocomplete values for InputEmail component */
type InputEmailAutocompleteAttribute = 'email' | 'username' | 'off';

interface InputEmailProps extends InputBaseProps {
  /**
   * Custom `autoComplete` attribute specifically for the email input. More info on when to
   * use a certain value can be found here:
   * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls:-the-autocomplete-attribute
   */
  autoComplete: InputEmailAutocompleteAttribute;
}

type Props = InputEmailProps & InputEmailHTMLAttributes;

export const InputEmail = forwardRef<HTMLInputElement, Props>(
  ({ label, hint, error, isOptional, isDisabled, isReadOnly, autoComplete, ...rest }, ref) => {
    return (
      <Input
        ref={ref}
        type="text"
        inputMode="email" // recommended instead of type="email", prevents native validation
        label={label}
        hint={hint}
        error={error}
        isOptional={isOptional}
        autoComplete={autoComplete}
        disabled={isDisabled}
        readOnly={isReadOnly}
        {...rest}
      />
    );
  },
);
