import { FC } from 'react';

import { StyledListItem, StyledText } from './styles';
import { CheckIcon, CloseIcon, DocumentIcon, ErrorIcon } from '../../icons';
import { styled } from '../../stitches.config';
import { Bleed } from '../Bleed/Bleed';
import { IconButton } from '../IconButton/IconButton';
import { NotificationBox } from '../Notification/NotificationBox';
import { Stack } from '../Stack/Stack';

type UploadItemProps = ({ state: 'success'; errorMessage?: never } | { state: 'error'; errorMessage: string }) & {
  name: string;
  onDelete: () => void;
};

const Elevator = styled('div', {
  zIndex: 1,
});

const uploadItemIcon = { success: <CheckIcon color="feedbackSuccess" />, error: <ErrorIcon color="feedbackError" /> };

export const UploadItem: FC<UploadItemProps> = ({ state, errorMessage, name, onDelete }) => (
  <StyledListItem>
    <Stack gap="2">
      <Stack gap="2" direction="row" alignY="center">
        <DocumentIcon />
        <StyledText size="BodyS" weight="bold">
          {name}
        </StyledText>
        <Stack.Item grow>{uploadItemIcon[state]}</Stack.Item>
        <Elevator>
          <Bleed right="4" vertical="4">
            <IconButton onClick={onDelete} label={`Remove file ${name}`}>
              <CloseIcon />
            </IconButton>
          </Bleed>
        </Elevator>
      </Stack>
      {errorMessage ? <NotificationBox variant="error" text={errorMessage} isAlert /> : null}
    </Stack>
  </StyledListItem>
);
