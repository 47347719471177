import { FC, useId } from 'react';

import type { RadioGroupItemProps } from '@radix-ui/react-radio-group';
import reactToText from 'react-to-text';

import { styled } from '../../../stitches.config';
import { Box } from '../../Box/Box';
import { Stack } from '../../Stack/Stack';
import { Text } from '../../Text/Text';
import { RadioBase } from '../RadioBase';
import { useRadioGroup } from '../RadioGroup/RadioGroup';
import { RadioIndicator } from '../RadioIndicator';

const StyledRadio = styled(RadioBase, {
  '&:focus': {
    // Outline is handled in the styled Label
    outline: 'none',
  },
});

const TileWrapper = styled(Box, {
  minHeight: '72px',
  display: 'flex',
  alignItems: 'center',
  paddingX: '$4',
});
/**
 * Label element for the input.
 */
const Label = styled('label', {
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  borderRadius: '$m',
  backgroundColor: '$backgroundPrimary',
  boxShadow: '$s',

  '&:hover': {
    boxShadow: '$shadowHover',
  },

  [`& ${StyledRadio}`]: {
    '&::before': {
      position: 'absolute',
      content: '',
      width: '100%',
      height: '100%',
      left: 0,
      borderRadius: '$m',
    },

    '&[data-state="checked"]': {
      '&::before': {
        border: '$borderWidths$m solid $borderSelected',
      },
    },

    '&:focus-visible': {
      '&::before': {
        outline: '$outlineFocus',
      },
    },

    '@supports not selector(:focus-visible)': {
      '&:focus': {
        '&::before': {
          outline: '$outlineFocus',
        },
      },
    },
  },
});

export const RadioTile: FC<Props> = ({ children, value, icon, subtitle }) => {
  const id = useId();
  const { ariaDescribedby } = useRadioGroup();

  return (
    <Label data-label={reactToText(children)}>
      <TileWrapper>
        <Stack gap="3" direction="row" alignY="center">
          <StyledRadio aria-describedby={ariaDescribedby} id={id} value={value}>
            <RadioIndicator />
          </StyledRadio>
          {icon ? <Stack.Item shrink={false}>{icon}</Stack.Item> : null}
          <Box paddingY={3}>
            <Text weight="bold">{children}</Text>
            {subtitle && (
              <Text weight="regular" size="BodyM">
                {subtitle}
              </Text>
            )}
          </Box>
        </Stack>
      </TileWrapper>
    </Label>
  );
};

StyledRadio.displayName = 'styled(RadioBase)';
Label.displayName = 'Label';

interface Props extends Pick<RadioGroupItemProps, 'value'> {
  children: string;
  icon?: JSX.Element;
  subtitle?: string;
}
