// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgInsulationIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="m12.61 3.25 8 7.11v-.01c.21.19.33.45.33.73v8.89c0 .54-.43.97-.97.97s-.97-.43-.97-.97v-8.45l-7.03-6.25-7.03 6.24v8.46c0 .54-.43.97-.97.97S3 20.51 3 19.97v-8.9c0-.28.12-.55.33-.73l7.99-7.09c.37-.33.92-.33 1.29 0Zm-5.26 9.26 4-3.58a.92.92 0 0 1 1.24 0v-.01l4 3.59c.2.17.31.43.31.69v6.77c0 .51-.42.93-.93.93h-2c-.51 0-.93-.42-.93-.93v-3.38H10.9v3.38c0 .51-.42.93-.93.93h-2c-.51 0-.93-.42-.93-.93V13.2c0-.26.11-.51.31-.69Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgInsulationIcon;
SvgInsulationIcon.displayName = 'SvgInsulationIcon';
