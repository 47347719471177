import { FC, createContext, useMemo, useContext } from 'react';

import { useApplication } from '@common/application';
import type { Locale } from '@common/application';
import { delve } from '@common/delve';

import { format } from './format';
import { Dictionary, MessageContext } from './types';
import useDictionary from './useDictionary';

type InternationalizationContext = {
  isLoading: boolean;
  t: (key: string, context?: MessageContext) => string;
};

const I18nContext = createContext<InternationalizationContext>({
  isLoading: false,
  t: () => '',
});

export const useTranslation = (): InternationalizationContext => useContext(I18nContext);

// TODO Cache messages across navigations
// const dictionaries: Partial<Dictionaries> = {};

type Props = {
  dictionary: Dictionary | ((locale: Locale) => Promise<Dictionary>);
};

export const I18nProvider: FC<React.PropsWithChildren<Props>> = ({ children, dictionary }) => {
  const { locale } = useApplication();
  const { isLoading, dictionary: messages } = useDictionary(dictionary, locale);

  const i18n = useMemo(() => {
    return {
      isLoading,
      t: (key, context) => {
        if (messages) {
          const message = delve(messages, key);
          if (typeof message !== 'undefined') {
            return format(message, locale, context);
          } else {
            // TODO: log missing key somewhere to be able to later audit
            /* eslint-disable no-console */
            console.groupCollapsed(`i18n: key '${key}' not found in dictionary:`);
            console.log(JSON.stringify(messages, null, 2));
            console.groupEnd();
            /* eslint-enable no-console */
            return key;
          }
        }
      },
    } as InternationalizationContext;
  }, [locale, messages, isLoading]);

  return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>;
};
