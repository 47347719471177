export { replaceStringWithComponent } from './replaceStringWithComponent';
export { stripAccolades } from './stripAccolades';
export { spaceTextByUpperCase } from './spaceTextByUpperCase';

export const capitalizeFirstLetter = (str?: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lowercaseFirstLetter = (str?: string) => {
  if (!str) return '';
  return str.charAt(0).toLowerCase() + str.slice(1);
};
