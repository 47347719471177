// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgLocationIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M11.25 22.95v-1.875c-2.283-.233-4.183-1.108-5.7-2.625-1.517-1.517-2.392-3.417-2.625-5.7H1.05v-1.5h1.875c.233-2.283 1.108-4.183 2.625-5.7 1.517-1.517 3.417-2.392 5.7-2.625V1.05h1.5v1.875c2.283.233 4.183 1.108 5.7 2.625 1.517 1.517 2.392 3.417 2.625 5.7h1.875v1.5h-1.875c-.233 2.283-1.108 4.183-2.625 5.7-1.517 1.517-3.417 2.392-5.7 2.625v1.875h-1.5ZM12 19.6c2.083 0 3.87-.746 5.363-2.238C18.854 15.872 19.6 14.084 19.6 12s-.746-3.87-2.238-5.362C15.872 5.146 14.084 4.4 12 4.4s-3.87.746-5.362 2.238C5.146 8.129 4.4 9.917 4.4 12s.746 3.87 2.238 5.363C8.129 18.854 9.917 19.6 12 19.6Zm0-3.85c-1.05 0-1.938-.363-2.662-1.088C8.613 13.938 8.25 13.05 8.25 12c0-1.05.363-1.938 1.088-2.662C10.063 8.613 10.95 8.25 12 8.25c1.05 0 1.938.363 2.662 1.088.725.725 1.088 1.612 1.088 2.662 0 1.05-.363 1.938-1.088 2.662-.725.725-1.612 1.088-2.662 1.088Zm0-1.5c.633 0 1.167-.217 1.6-.65.433-.433.65-.967.65-1.6 0-.633-.217-1.167-.65-1.6-.433-.433-.967-.65-1.6-.65-.633 0-1.167.217-1.6.65-.433.433-.65.967-.65 1.6 0 .633.217 1.167.65 1.6.433.433.967.65 1.6.65Z" />
  </Icon>
);
export default SvgLocationIcon;
SvgLocationIcon.displayName = 'SvgLocationIcon';
