import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

export const enecoExpandableTokens = {
  expandableIndicatorBackground: eneco.backgroundColoredColors.backgroundVarThree,
  expandableDefaultIndicatorColor: eneco.iconColors.iconOnBackgroundVarThree,
  expandableTransparentIndicatorColor: eneco.iconColors.iconSecondary,
};
export const oxxioExpandableTokens = {
  expandableIndicatorBackground: oxxio.backgroundColors.backgroundBrand,
  expandableDefaultIndicatorColor: oxxio.iconColors.iconInverted,
  expandableTransparentIndicatorColor: oxxio.iconColors.iconBrand,
};
export const woonenergieExpandableTokens = {
  expandableIndicatorBackground: woonenergie.brandSupport.lightLime,
  expandableDefaultIndicatorColor: woonenergie.iconColors.iconPrimary,
  expandableTransparentIndicatorColor: woonenergie.iconColors.iconPrimary,
};
