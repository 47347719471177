// This file contains the css derived from normalize.css, using this gets rid
// of the main differences between browsers.
// https://necolas.github.io/normalize.css/

export const normalizeCSS = {
  html: {
    textSizeAdjust: '100%',
  },

  'h1, h2, h3, h4, h5, h6, p': {
    margin: 0,
  },

  'ul, ol': {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },

  dl: {
    margin: 0,
  },

  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },

  hr: {
    boxSizing: 'content-box' /* 1 */,
    height: 0 /* 1 */,
    overflow: 'visible' /* 2 */,
  },

  pre: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },

  a: {
    backgroundColor: 'transparent',
  },

  abbr: {
    borderBottom: 'none',
    textDecoration: 'underline dotted',
  },

  b: {
    fontWeight: 'bolder',
  },

  'code, kbd, samp': {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },

  small: {
    fontSize: '80%',
  },

  'sub, sup': {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
  },

  sub: {
    bottom: '-0.25em',
  },

  sup: {
    top: '-0.5em',
  },

  img: {
    borderStyle: 'none',
  },

  'button, input, optgroup, select, textarea': {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: 1.15,
    margin: '0',
  },

  'button, input': {
    overflow: 'visible',
  },

  'button, [type="button"], [type="reset"], [type="submit"]': {
    '-webkit-appearance': 'button',
  },

  'button::-moz-focus-inner,  [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner':
    {
      borderStyle: 'none',
      padding: 0,
    },

  fieldset: {
    margin: 0,
    padding: 0,
    border: 0,
  },

  legend: {
    boxSizing: 'border-box',
    color: 'inherit',
    display: 'table',
    maxWidth: '100%',
    padding: 0,
    whiteSpace: 'normal',
  },

  progress: {
    verticalAlign: 'baseline',
  },

  textarea: {
    overflow: 'auto',
  },

  '[type="checkbox"],  [type="radio"]': {
    boxSizing: 'border-box',
    padding: 0,
  },

  '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button': {
    height: 'auto',
  },

  '[type="search"]::-webkit-search-decoration': {
    '-webkit-appearance': 'none',
  },

  '::-webkit-file-upload-button': {
    '-webkit-appearance': 'button',
    font: 'inherit',
  },

  details: {
    display: 'block',
  },

  summary: {
    display: 'list-item',
  },

  template: {
    display: 'none',
  },

  '[hidden]': {
    display: 'none',
  },
};
