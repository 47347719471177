import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

/**
 * MessageBar specific tokens
 */
export const enecoMessagebarColors = {
  messagebarBackground: eneco.secondaryColors.purple900,
  messagebarHoverBackground: '#2a183b',
  messagebarText: eneco.textColors.textInverted,
};

export const oxxioMessagebarColors = {
  messagebarBackground: oxxio.backgroundColors.backgroundDark,
  messagebarHoverBackground: '#00082e',
  messagebarText: oxxio.textColors.textInverted,
};

export const woonenergieMessagebarColors = {
  messagebarBackground: woonenergie.brandSupport.lime,
  messagebarHoverBackground: '#8f9a25',
  messagebarText: woonenergie.textColors.textPrimary,
};
