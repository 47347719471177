import React, { forwardRef, ReactNode } from 'react';

import reactToText from 'react-to-text';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { StyledContainer, StyledLink, StyledText } from '../TextLink/TextLink';

const StyledNavLink = styled(StyledLink, {
  display: 'inline-flex',
  placeItems: 'flex-start',

  '@safeMotion': {
    transition: 'color $easeQuick',
  },

  variants: {
    variant: {
      primary: {
        color: '$colors$linkPrimary',

        [`& ${StyledText}`]: {
          typography: '$bodyM',
          fontWeight: '$bodyBold',
        },

        [`&:hover ${StyledText}`]: {
          backgroundSize: '100% 2px', // show the underline
        },

        '&:hover, &:active': {
          color: '$colors$linkBrand',
        },
      },
      secondary: {
        color: '$colors$linkSecondary',

        [`& ${StyledText}`]: {
          typography: '$bodyM',
          fontWeight: '$bodyBold',
        },

        '&:hover, &:active': {
          color: '$colors$linkPrimary',
        },
      },
      tertiary: {
        color: '$colors$linkSecondary',

        [`& ${StyledText}`]: {
          typography: '$bodyS',
          fontWeight: '$bodyBold',
        },

        '&:hover, &:active': {
          color: '$colors$linkPrimary',
        },
      },
      quaternary: {
        color: '$colors$linkSecondary',

        [`& ${StyledText}`]: {
          typography: '$bodyM',
        },

        '&:hover, &:active': {
          color: '$colors$linkPrimary',
        },
      },
    },

    isCurrent: {
      true: {},
    },
    tone: {
      onColor: {
        '&:focus-visible': {
          outline: '$borderWidths$m solid $colors$navLinkSecondaryOnColorFocusOutline',
        },

        '@supports not selector(:focus-visible)': {
          '&:focus': {
            outline: '$borderWidths$m solid $colors$navLinkSecondaryOnColorFocusOutline',
          },
        },
      },
      onLight: {
        '&:focus-visible': {
          outline: '$outlineFocus',
        },

        '@supports not selector(:focus-visible)': {
          '&:focus': {
            outline: '$outlineFocus',
          },
        },
      },
    },
    justifyContent: {
      spaceAround: {
        justifyContent: 'space-around',
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
      spaceEvenly: {
        justifyContent: 'space-evenly',
      },
      flexStart: {
        justifyContent: 'flex-start',
      },
      flexEnd: {
        justifyContent: 'flex-end',
      },
      center: {
        justifyContent: 'center',
      },
      initial: {
        justifyContent: 'initial',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      isCurrent: true,
      css: {
        color: '$colors$linkBrand',
      },
    },
    {
      variant: 'secondary',
      isCurrent: true,
      css: {
        color: '$colors$linkPrimary',
        [`& ${StyledText}`]: {
          backgroundSize: '100% 2px',
        },
      },
    },
    {
      variant: 'tertiary',
      isCurrent: true,
      css: {
        color: '$colors$linkPrimary',
        [`& ${StyledText}`]: {
          backgroundSize: '100% 2px',
        },
      },
    },
    {
      //Temporary variant until the header is restyled
      variant: 'quaternary',
      isCurrent: true,
      css: {
        color: '$colors$linkPrimary',
      },
    },
    {
      variant: 'secondary',
      tone: 'onColor',
      css: {
        color: '$navLinkSecondaryOnColorDefault',
        '&:hover, &:active': {
          color: '$navLinkSecondaryOnColorHover',
        },
        [`&:hover ${StyledText}`]: {
          backgroundSize: '100% 2px', // show the underline
        },
      },
    },
    {
      variant: 'secondary',
      tone: 'onColor',
      isCurrent: true,
      css: {
        color: '$navLinkSecondaryOnColorHover',
      },
    },
    {
      variant: 'tertiary',
      tone: 'onColor',
      css: {
        color: '$navLinkTertiaryOnColorDefault',
        '&:hover, &:active': {
          color: '$navLinkTertiaryOnColorHover',
        },
      },
    },
    {
      variant: 'tertiary',
      tone: 'onColor',
      isCurrent: true,
      css: {
        color: '$navLinkTertiaryOnColorHover',
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
    tone: 'onLight',
    justifyContent: 'initial',
  },
});

const stitchesClassName = 'sparky-navlink';

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (
    {
      children,
      variant,
      isCurrent,
      href,
      target,
      type = 'button',
      leftIcon,
      rightIcon,
      onClick,
      tone,
      className = '',
      justifyContent = 'initial',
    },
    ref,
  ) => {
    const variantProps = extractVariantProps({ variant, isCurrent, tone, justifyContent });

    const baseProps = { onClick };
    const componentProps = href
      ? { ...baseProps, href, ...(target ? { target } : null), rel: target === '_blank' ? 'noreferrer' : '' }
      : { ...baseProps, as: 'button', type };

    return (
      <StyledNavLink
        {...variantProps}
        {...componentProps}
        aria-current={isCurrent ? 'page' : 'false'}
        className={`${stitchesClassName} ${className}`}
        data-label={reactToText(children)}
        href={href}
        onClick={onClick}
        ref={ref}>
        {leftIcon}
        <StyledContainer hasLeftIcon={!!leftIcon} hasRightIcon={!!rightIcon}>
          <StyledText>{children}</StyledText>
        </StyledContainer>
        {rightIcon}
      </StyledNavLink>
    );
  },
);

StyledNavLink.displayName = 'styled(NavLink)';
NavLink.displayName = 'NavLink';

type NavLinkButtonProps = {
  href?: never;
  target?: never;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

type NavLinkAnchorProps = {
  href?: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  type?: never;
};

type NavLinkProps = TransformStitchesToSparky<typeof StyledNavLink> & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  /** Text shown inside the link. */
  children: ReactNode;
  /** Icon shown to the left of the text. */
  leftIcon?: JSX.Element;
  /** Icon shown to the right of the text. */
  rightIcon?: JSX.Element;
  className?: never;
} & (NavLinkAnchorProps | NavLinkButtonProps);
