import { useId, FC } from 'react';

import type { RadioGroupItemProps } from '@radix-ui/react-radio-group';
import reactToText from 'react-to-text';

import { styled } from '../../../stitches.config';
import { Stack } from '../../Stack/Stack';
import { Text } from '../../Text/Text';
import { RadioBase } from '../RadioBase';
import { useRadioGroup } from '../RadioGroup/RadioGroup';
import { RadioIndicator } from '../RadioIndicator';

const StyledRadio = styled(RadioBase, {
  marginTop: '2px', // Custom value; with top alignment it doesn't look right without spacing

  '&:focus-visible': {
    outline: '$outlineFocus',
    borderColor: '$borderFocus',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineFocus',
      borderColor: '$borderFocus',
    },
  },
});

/**
 * Label element for the input.
 */
const Label = styled('label', {
  cursor: 'pointer',

  '&:hover': {
    [`& ${StyledRadio}`]: {
      boxShadow: '0 0 0 $borderWidths$l $colors$outlineHover',
      borderColor: '$formBorderHover',
    },
  },
});

export const RadioButton: FC<Props> = ({ children, value }) => {
  const id = useId();
  const { ariaDescribedby } = useRadioGroup();

  return (
    <Label data-label={reactToText(children)} htmlFor={id}>
      <Stack gap="2" direction="row" alignY="start">
        <Stack.Item shrink={false}>
          <StyledRadio id={id} value={value} aria-describedby={ariaDescribedby}>
            <RadioIndicator />
          </StyledRadio>
        </Stack.Item>
        <Text>{children}</Text>
      </Stack>
    </Label>
  );
};

StyledRadio.displayName = 'styled(RadioBase)';
Label.displayName = 'styled(label)';

interface Props extends Pick<RadioGroupItemProps, 'value'> {
  children: string;
}
