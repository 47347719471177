import React, { FC } from 'react';

import { styled } from '../../stitches.config';
import { extractVariantProps } from '../../util/css/stitches';

const StyledIconListItem = styled('li', {
  display: 'flex',
  gap: '$1',

  '& + &': {
    paddingTop: '$1',
  },
});

const StyledIconListText = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const StyledIconListIcon = styled('div', {
  display: 'flex',
  flexShrink: 0,
});

type Props = {
  /** Text shown inside the list item */
  children: React.ReactNode;
  /** Icon shown inside the list item. */
  icon?: JSX.Element;
};

export const IconListItem: FC<React.PropsWithChildren<Props>> = ({ children, icon, ...props }) => {
  const variantProps = extractVariantProps(props);
  return (
    <StyledIconListItem>
      <StyledIconListIcon {...variantProps}>{icon}</StyledIconListIcon>
      <StyledIconListText>{children}</StyledIconListText>
    </StyledIconListItem>
  );
};
