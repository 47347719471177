import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useWindow } from '../../providers/mediaQuery';
import { styled } from '../../stitches.config';

const StickyContainer = styled('div', {
  zIndex: '2',
  left: '0',
  right: '0',
  variants: {
    scrollDirection: {
      top: {
        position: 'static',
      },
      up: {
        position: 'fixed',
        transition: 'top $easeQuick',
        top: '0',
      },
      down: {
        position: 'fixed',
        transition: 'top $easeQuick',
      },
    },
  },
});

type ScrollDirection = 'up' | 'down' | 'top';

export const StickyHeader: FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useWindow();
  const ref = useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>('top');
  const [headerHeight, setHeight] = useState<number>();

  let previousScroll = isMounted ? window.scrollY : 0;

  const handleScroll = () => {
    const headerHeight = ref.current?.offsetHeight;
    if (!headerHeight) {
      return;
    }

    const currentScroll = window.scrollY;
    const isScrollingUp = previousScroll > currentScroll;

    previousScroll = currentScroll;

    setScrollDirection(prevDirection => {
      if (
        (isScrollingUp && currentScroll === 0) ||
        (!isScrollingUp && currentScroll < headerHeight) ||
        (prevDirection === 'top' && currentScroll < headerHeight)
      ) {
        return 'top';
      }

      return isScrollingUp ? 'up' : 'down';
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    const resizeObserver = new ResizeObserver(entries => {
      setHeight(entries[0]?.contentRect.height);
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={headerHeight ? { height: headerHeight } : {}}>
      <StickyContainer
        ref={ref}
        css={scrollDirection !== 'up' ? { top: `-${headerHeight}px` } : undefined}
        scrollDirection={scrollDirection}>
        {children}
      </StickyContainer>
    </div>
  );
};
