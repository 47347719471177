import { createContext, FC, useContext } from 'react';

export interface I18nTranslations {
  optional: string;
  showPassword: string;
  hidePassword: string;
  isShown: string;
  isHidden: string;
  on: string;
  off: string;
  loading: string;
  finishedLoading: string;
  skipToMain: string;
  inKilowattHour: string;
  inWattHour: string;
  inCubicMetres: string;
  inGigajoule: string;
  step: string;
  stepOf: string;
  steps: string;
  current: string;
  completed: string;
  increase: string;
  decrease: string;
  maxReached: string;
  minReached: string;
  noValue: string;
  close: string;
  willBeUpdated: string;
  openProfileMenu: string;
  closeProfileMenu: string;
  openHamburgerMenu: string;
  closeHamburgerMenu: string;
  mainNavigation: string;
  secondaryNavigation: string;
  domainNavigation: string;
  languageAndProfile: string;
  filterBy: string;
  clearInput: string;
  closePopup: string;
  noResults: string;
  save: string;
  reset: string;
  nextMonth: string;
  previousMonth: string;
  openCalendar: string;
  closeCalendar: string;
  previous: string;
  next: string;
  resetFilters: string;
  profileNavigation: string;
  languageNavigation: string;
  languageSelectorDropdownA11yButton: string;
}

const I18nTranslationContext = createContext<I18nTranslations>({
  optional: 'optional',
  showPassword: 'show password',
  hidePassword: 'hide password',
  isShown: 'is shown',
  isHidden: 'is hidden',
  on: 'On',
  off: 'Off',
  loading: 'Loading',
  finishedLoading: 'Finished loading',
  skipToMain: 'Skip to main content',
  inKilowattHour: 'in kilowatt hour',
  inWattHour: 'in watt hour',
  inCubicMetres: 'in cubic metres',
  inGigajoule: 'in gigajoule',
  step: 'Step {step}',
  stepOf: 'Step {step} of {total}',
  steps: 'steps',
  current: 'current',
  completed: 'completed',
  increase: 'Increase',
  decrease: 'Decrease',
  maxReached: 'is the maximum amount',
  minReached: 'is the minimum amount',
  noValue: 'No value selected',
  close: 'close',
  willBeUpdated: 'will be updated after selection',
  openProfileMenu: 'Open profile menu',
  closeProfileMenu: 'Close profile menu',
  openHamburgerMenu: 'Open menu',
  closeHamburgerMenu: 'Close menu',
  mainNavigation: 'Main navigation',
  secondaryNavigation: 'Secondary navigation',
  domainNavigation: 'Domain navigation',
  languageAndProfile: 'Language and profile',
  filterBy: 'Filter by ',
  clearInput: 'Clear input',
  closePopup: 'Close popup',
  noResults: 'No results',
  save: 'Save',
  reset: 'Reset',
  nextMonth: 'Next month',
  previousMonth: 'Previous month',
  openCalendar: 'Open calendar',
  closeCalendar: 'Close calendar',
  previous: 'Previous',
  next: 'Next',
  resetFilters: 'Reset filters',
  profileNavigation: 'Profile navigation',
  languageNavigation: 'Language navigation',
  languageSelectorDropdownA11yButton: 'Select language',
});

interface Props {
  labels: I18nTranslations;
}

function throwErrorFor(name: string) {
  throw new Error(`${name} must be used within a I18nTranslationProvider or SparkyProvider`);
}

export const I18nTranslationProvider: FC<React.PropsWithChildren<Props>> = ({ children, labels }) => {
  return <I18nTranslationContext.Provider value={labels}>{children}</I18nTranslationContext.Provider>;
};

export const useI18nTranslation = (translation: keyof I18nTranslations): string => {
  const context = useContext(I18nTranslationContext);

  if (context === undefined) {
    throwErrorFor('useI18nTranslation');
  }

  if (context[translation] === undefined) {
    throw new Error(`Requested translation: ${translation} doesn't exist.`);
  }

  return context[translation];
};

export const useI18nTranslations = (): I18nTranslations => {
  const context = useContext(I18nTranslationContext);

  if (context === undefined) {
    throwErrorFor('useI18nTranslation');
  }

  return context;
};
