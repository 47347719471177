// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgStarIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M25.68 9.213a1.333 1.333 0 0 0-1.267-.92h-7.746L14.267.92a1.333 1.333 0 0 0-2.534 0l-2.4 7.373H1.587c-.574 0-1.094.374-1.267.92a1.349 1.349 0 0 0 .48 1.494l6.267 4.56-2.4 7.373a1.349 1.349 0 0 0 .48 1.493 1.32 1.32 0 0 0 1.573 0l6.267-4.56 6.266 4.56c.24.174.507.254.787.254s.547-.08.787-.254c.466-.333.666-.946.48-1.493l-2.4-7.373 6.266-4.56c.467-.334.667-.947.48-1.494h.027Z" />
  </Icon>
);
export default SvgStarIcon;
SvgStarIcon.displayName = 'SvgStarIcon';
