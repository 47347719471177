import { forwardRef } from 'react';

import * as SwitchPrimitive from '@radix-ui/react-switch';

import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { Label } from '../Input/InputLabel';
import { Stack } from '../Stack/Stack';

const OnLabel = styled('span', {
  display: 'none',
  margin: '0',
  color: '$textInverted',
  fontWeight: '$bodyBold',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingRight: '36px',
  marginTop: '-2px',
});

const OffLabel = styled('span', {
  display: 'flex',
  color: '$textPrimary',
  fontWeight: '$bodyBold',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingLeft: '36px',
  marginTop: '-2px',
});

const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  width: '102px',
  height: '40px',
  backgroundColor: '$controlsInactive',
  borderRadius: '$round',
  position: 'relative',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  cursor: 'pointer',

  '&[data-state="checked"]': {
    backgroundColor: '$controlsActive',
    [`${OnLabel}`]: {
      display: 'flex',
    },
    [`${OffLabel}`]: {
      display: 'none',
    },
  },

  '&:focus-visible': {
    outline: '$outlineFocus',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineFocus',
    },
  },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  width: '36px',
  height: '36px',
  backgroundColor: '$controlsKnob',
  borderRadius: '$round',
  transform: 'translateX(2px)',

  '&[data-state="checked"]': {
    transform: 'translateX(64px)',
  },

  '@safeMotion': {
    transition: '$easeQuick',
  },
});

type LabelProps =
  | {
      label: string;
      'aria-labelledby'?: never;
    }
  | {
      'aria-labelledby': string;
      label?: never;
    };

type ToggleSwitchProps = {
  name: string;
  value?: HTMLInputElement['value'];
  onCheckedChange?(checked: boolean): void;
  isChecked?: boolean;
  defaultChecked?: boolean;
} & LabelProps;

export const ToggleSwitch = forwardRef<HTMLButtonElement, ToggleSwitchProps>(
  ({ label, name, value, onCheckedChange, isChecked, defaultChecked, 'aria-labelledby': ariaLabelledby }, ref) => {
    const { on, off } = useI18nTranslations();

    return (
      <div>
        <Stack gap="3">
          {label ? <Label htmlFor={name}>{label}</Label> : null}
          <StyledSwitch
            id={name}
            name={name}
            onCheckedChange={onCheckedChange}
            defaultChecked={defaultChecked}
            checked={isChecked}
            aria-labelledby={ariaLabelledby}
            ref={ref}
            value={value}>
            <OnLabel aria-hidden="true">{on}</OnLabel>
            <OffLabel aria-hidden="true">{off}</OffLabel>
            <StyledThumb />
          </StyledSwitch>
        </Stack>
      </div>
    );
  },
);

OnLabel.displayName = 'OnLabel';
OffLabel.displayName = 'OffLabel';
StyledSwitch.displayName = 'styled(SwitchPrimitive.Root)';
StyledThumb.displayName = 'styled(SwitchPrimitive.Thumb)';
ToggleSwitch.displayName = 'ToggleSwitch';
