import { getStaticPrefix } from '@common/env';

import { Fonts, FontFace } from '../types';

export const getFontFaces = (fonts: Fonts): Partial<FontFace>[] =>
  fonts.map(([fontFamily, fontWeight, fileName, format]) => ({
    fontFamily,
    fontWeight,
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `url('${getStaticPrefix()}/font/${fileName}') format('${format}')`,
  }));

// Borrowed from https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeRefs<T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
