import { FC, PropsWithChildren, ReactNode } from 'react';

export const ConditionalWrapper: FC<{
  children: ReactNode;
  shouldWrap: boolean;
  wrapper: FC<PropsWithChildren<any>>; // eslint-disable-line @typescript-eslint/no-explicit-any

  // ...props
  [key: string]: unknown;
}> = ({ children, shouldWrap, wrapper: Wrapper, ...props }) => {
  return shouldWrap ? <Wrapper {...props}>{children}</Wrapper> : <>{children}</>;
};
