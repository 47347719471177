import React, { FC } from 'react';

import { IconListItem, StyledIconListIcon } from './IconListItem';
import { styled } from '../../stitches.config';

const StyledIconList = styled('ul', {
  listStyle: 'none',

  variants: {
    alignment: {
      top: {
        [`${StyledIconListIcon}`]: {
          alignItems: 'flex-start',
        },
      },
      middle: {
        [`${StyledIconListIcon}`]: {
          alignItems: 'center',
        },
      },
      bottom: {
        [`${StyledIconListIcon}`]: {
          alignItems: 'flex-end',
        },
      },
    },
  },
});

type Props = {
  /** IconList.Item components */
  children: React.ReactNode;
  alignment?: 'top' | 'middle' | 'bottom';
};

const IconListComponent: FC<React.PropsWithChildren<Props>> = ({ children, alignment }) => {
  return <StyledIconList alignment={alignment}>{children}</StyledIconList>;
};

export const IconList = Object.assign(IconListComponent, { Item: IconListItem });
