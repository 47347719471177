import React, { useContext } from 'react';

import { CarouselContext } from './Carousel';
import { styled } from '../../stitches.config';
import { extractVariantProps } from '../../util/css/stitches';
import { gridData } from '../PageGrid/PageGrid';

export const carouselPageGridPadding = `calc((100vw - ${gridData.maxWidth.xl} - $2) / 2 + $24)`;

const StyledCarouselItem = styled('div', {
  variants: {
    scrollBehavior: {
      snap: {
        scrollSnapAlign: 'center',
        scrollSnapStop: 'always',
        '&:first-child': {
          scrollSnapAlign: 'start',
        },
        '&:last-child': {
          scrollSnapAlign: 'end',
        },
        '@md': {
          scrollSnapAlign: 'start',
        },
      },
      vertical: {},
      native: {},
    },
    scrollMarginX: {
      gridGutter: {
        scrollMarginInlineStart: '$6',
        scrollMarginInlineEnd: '$6',
        '@md': {
          scrollMarginInlineStart: '$10',
          scrollMarginInlineEnd: '$10',
        },
        '@xl': {
          scrollMarginInlineStart: carouselPageGridPadding,
          scrollMarginInlineEnd: carouselPageGridPadding,
        },
      },
    },
    layout: {
      default: {},
      peekNext: {
        '@md': {
          scrollSnapAlign: 'center',
        },
      },
      fullWidth: {},
    },
  },
});

interface Props {
  ariaLabel?: string;
  as?: Extract<keyof JSX.IntrinsicElements, 'div' | 'span' | 'li'>;
  className?: never;
}
const stitchesClassName = 'sparky-carousel-item';

export const CarouselItem: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ariaLabel,
  as,
  className = '',
  ...props
}) => {
  const context = useContext(CarouselContext);

  if (context === undefined) {
    throw new Error(`CarouselItem must be used within a Carousel.`);
  }
  const variants = extractVariantProps(context);

  return (
    <StyledCarouselItem
      {...props}
      {...variants}
      aria-label={ariaLabel}
      tabIndex={0}
      as={as}
      className={`${stitchesClassName} ${className}`}>
      {children}
    </StyledCarouselItem>
  );
};
