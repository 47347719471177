// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgRecycleIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M19.784 12.94c.126 0 .133.11.126.164-.47 3.511-3.328 6.357-6.83 6.827l-.25.029a7.944 7.944 0 0 1-6.281-2.118l-.199-.192-.64.65c-.603.603-1.617.217-1.704-.596L4 17.59V14c0-.51.388-.935.884-.993L5 13h3.59c.851 0 1.29.988.776 1.625l-.076.085-1.51 1.51C8.86 17.31 10.34 18 12 18a6.003 6.003 0 0 0 5.89-4.88c.021-.12.084-.18.19-.18h1.704Zm-2.333-6.782.199.192.64-.65c.603-.603 1.617-.217 1.704.596L20 6.41V10c0 .51-.388.935-.884.993L19 11h-3.59c-.851 0-1.29-.988-.776-1.625l.076-.085 1.51-1.51C15.14 6.69 13.66 6 12 6a6.003 6.003 0 0 0-5.89 4.88c-.021.12-.084.18-.19.18H4.217c-.126 0-.133-.11-.126-.164.47-3.511 3.328-6.357 6.83-6.827l.25-.029a7.943 7.943 0 0 1 6.281 2.118h-.001Z"
    />
  </Icon>
);
export default SvgRecycleIcon;
SvgRecycleIcon.displayName = 'SvgRecycleIcon';
