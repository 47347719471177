import React, { FC } from 'react';

import { styled } from '../../stitches.config';

const StyledNumberedList = styled('ol', {
  paddingX: '$6',
  listStyleType: 'decimal',
});

type CompoundNumberedListProps = {
  Item: typeof Item;
};

export const NumberedList: FC<React.PropsWithChildren> & CompoundNumberedListProps = ({ children }) => {
  return <StyledNumberedList>{children}</StyledNumberedList>;
};

const Item: FC<React.PropsWithChildren> = ({ children }) => {
  return <li>{children}</li>;
};

NumberedList.Item = Item;
StyledNumberedList.displayName = 'styled(NumberedList)';
