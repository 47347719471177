import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_NextBestAction_ActionFeedbackDataRequestModel } from '@monorepo-types/dc';
import { ResponseModels_NextBestAction_NextBestActionResponseModel } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type GetNextBestActionsPublic = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  personalizationId: string;
  servingPointId?: number;
};
/**
 * GetNextBestActionsPublic
 * Get Next Best Actions
 * @returns ResponseModels_NextBestAction_NextBestActionResponseModel Success
 */
export function getNextBestActionsPublic(
  { businessUnit, label, personalizationId, servingPointId }: GetNextBestActionsPublic,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_NextBestAction_NextBestActionResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/nextbestaction/${personalizationId}`,
      query: { servingPointId },
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type PutNextBestActionFeedbackPublic = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  personalizationId: string;
  requestBody: RequestModels_NextBestAction_ActionFeedbackDataRequestModel;
};
/**
 * PutNextBestActionFeedbackPublic
 * Put Next Best Action Feedback
 * @returns any Success
 */
export function putNextBestActionFeedbackPublic(
  { businessUnit, label, personalizationId, requestBody }: PutNextBestActionFeedbackPublic,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/public/${businessUnit}/${label}/nextbestaction/${personalizationId}/feedback`,
      body: requestBody,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}
