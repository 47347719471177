import React, { forwardRef, ReactNode } from 'react';

import reactToText from 'react-to-text';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { StyledLink } from '../TextLink/TextLink';

const StyledLI = styled('li', {
  display: 'flex',
});

const StyledTabLink = styled(StyledLink, {
  //Need to target because of specificity
  'button&, a&': {
    paddingX: '$8',
    paddingTop: '$4',
  },
  whiteSpace: 'nowrap',
  color: '$textPrimary',
  fontSize: '$BodyL',

  '@safeMotion': {
    transition: 'background-color $easeQuick',
  },

  '&:hover': {
    backgroundColor: '$backgroundSecondary',
  },

  '&:focus-visible': {
    outline: '$outlineFocus',
    outlineOffset: '-$borderWidths$m',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineFocus',
      outlineOffset: '-$borderWidths$m',
    },
  },

  '&:active': {
    background: '$backgroundTertiary',
  },

  variants: {
    isCurrent: {
      true: {
        'button&, a&': {
          fontWeight: '$bodyBold',
          borderBottom: '$borderWidths$m solid currentColor',
          paddingBottom: 'calc($4 - $borderWidths$m)',
        },
      },
      false: {
        'button&, a&': {
          fontWeight: '$bodyRegular',
          borderBottom: 'none',
          paddingBottom: '$4',
        },
      },
    },
  },
});

const stitchesClassName = 'sparky-tablink';

export const TabLink = forwardRef<HTMLLIElement, TabLinkProps>(
  ({ children, isCurrent = 'false', href, target, type = 'button', onClick, className = '' }, ref) => {
    const variantProps = extractVariantProps({ isCurrent });

    const baseProps = { onClick };
    const componentProps = href
      ? { ...baseProps, href, target, rel: target === '_blank' ? 'noreferrer' : '' }
      : { ...baseProps, as: 'button', type };

    return (
      <StyledLI ref={ref}>
        <StyledTabLink
          {...variantProps}
          {...componentProps}
          aria-current={isCurrent ? 'page' : 'false'}
          className={`${stitchesClassName} ${className}`}
          data-label={reactToText(children)}
          href={href}
          onClick={onClick}>
          {children}
        </StyledTabLink>
      </StyledLI>
    );
  },
);

TabLink.toString = () => `.${stitchesClassName}`;

StyledTabLink.displayName = 'styled(TabLink)';
TabLink.displayName = 'TabLink';

type TabLinkButtonProps = {
  href?: never;
  target?: never;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

type TabLinkAnchorProps = {
  href?: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  type?: never;
};

type TabLinkProps = TransformStitchesToSparky<typeof StyledTabLink> & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  /** Text shown inside the link. */
  children: ReactNode;
  className?: never;
} & (TabLinkAnchorProps | TabLinkButtonProps);
