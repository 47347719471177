import { forwardRef, InputHTMLAttributes, ReactNode, useRef } from 'react';

import { Error } from './InputError';
import { Hint } from './InputHint';
import { Label } from './InputLabel';
import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { styled, css } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { Stack } from '../Stack/Stack';

/** Props necessary for every Input* component */
export interface InputBaseProps {
  label: string;
  name: string;
  hint?: ReactNode;
  error?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

interface Props extends InputBaseProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'name'> {}

export const inputStyle = css({
  backgroundColor: '$backgroundPrimary',
  border: '$borderWidths$s solid $formBorderDefault',
  borderRadius: '$s',
  color: '$textPrimary',
  fontSize: '$BodyL',
  lineHeight: 'calc(1.15 + $2)',
  minHeight: '$inputMinHeight',
  paddingX: '$4',
  paddingY: '$2',
  width: '100%',

  '&:focus': {
    outline: '$outlineInputFocus',
    borderColor: '$borderFocus',
  },

  '&::placeholder': {
    color: '$textLowEmphasis',
  },

  '&:disabled': {
    opacity: '$opacity50',
    boxShadow: 'none',
    outlineColor: 'transparent',
    cursor: 'not-allowed',
  },

  variants: {
    isInvalid: {
      true: {
        border: '$borderWidths$s solid $formBorderError',
        boxShadow: '$shadowError',
      },
      false: {
        '&:not(:disabled):hover': {
          boxShadow: '$shadowHover',
          borderColor: '$formBorderHover',
        },
      },
    },
  },
});

export const StyledInput = styled('input', inputStyle);

/** Internal use only (InputText, InputTelephone, etc.) */
export const Input = forwardRef<HTMLInputElement, Props>(
  ({ error, hint, isOptional, label, name, type, ...rest }, ref) => {
    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });

    const localRef = useRef<HTMLInputElement>(null);
    // Merge the local ref and forwarded ref so we can forward it *and* use it locally
    const mergedRef = mergeRefs([ref, localRef]);

    useValidationErrorEvent({ error, name }, localRef);

    return (
      <div>
        <Stack gap="2">
          <Label htmlFor={inputId} isOptional={isOptional}>
            {label}
          </Label>
          <Stack.Item grow>
            <StyledInput
              aria-errormessage={errorId}
              aria-describedby={describedBy}
              aria-invalid={error ? true : undefined}
              id={inputId}
              isInvalid={!!error}
              name={name}
              ref={mergedRef}
              type={type}
              {...rest}
            />
            {error ? <Error id={errorId}>{error}</Error> : null}
          </Stack.Item>
          {hint ? <Hint id={hintId}>{hint}</Hint> : null}
        </Stack>
      </div>
    );
  },
);

Input.displayName = 'Input';
StyledInput.displayName = 'styled(input)';
