// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgChevronLeftIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M14.757 6.314a1.07 1.07 0 0 0-1.514 0l-4.929 4.929a1.07 1.07 0 0 0 0 1.514l4.929 4.929a1.07 1.07 0 1 0 1.514-1.514l-4.166-4.177 4.166-4.167a1.08 1.08 0 0 0 0-1.514Z"
    />
  </Icon>
);
export default SvgChevronLeftIcon;
SvgChevronLeftIcon.displayName = 'SvgChevronLeftIcon';
