import React, { useId } from 'react';

import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { ChevronDownIcon } from '../../icons';
import { styled, keyframes } from '../../stitches.config';
import { Box } from '../Box/Box';
import { StyledCard } from '../Card/Card';
import { IndicatorButton } from '../Expandable/Expandable';
import { Heading } from '../Heading/Heading';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledAccordion = styled(AccordionPrimitive.Root, StyledCard);

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: 'hidden',
  '&:not(:last-child)': {
    borderBottom: '$borderWidths$s solid $borderDividerLowEmphasis',
  },
  position: 'relative',
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  display: 'flex',
  padding: '$6',
  textAlign: 'left',
  transition: 'background-color $easeQuick',
  typography: '$heading3xs',
  width: '100%',

  '&::before': {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: 'inherit',
    content: '',
    position: 'absolute',
    outline: '$outlineFocus',
    outlineOffset: '-$borderWidths$m',
    pointerEvents: 'none',
    opacity: 0,
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: 'none',
      '&::before': {
        opacity: 1,
      },
    },
  },
  '@supports selector(:focus-visible)': {
    '&:focus': {
      '&::before': {
        opacity: 0,
      },
    },
    '&:focus-visible': {
      '&::before': {
        opacity: 1,
      },
    },
  },

  '&:focus-visible': {
    outline: 'none',
  },
});

const StyledHeadingContainer = styled('div', {
  flex: 1,

  variants: {
    hasLeftIcon: {
      true: {
        paddingLeft: '$6',
      },
    },
  },
});

const StyledChevron = styled('div', IndicatorButton, {
  '@safeMotion': {
    transition: '$easeMedium',
  },
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',
  typography: '$bodyS',

  '@safeMotion': {
    '&[data-state="open"]': {
      animation: `${slideDown} $transitions$easeMedium`,
    },
    '&[data-state="closed"]': {
      animation: `${slideUp} $transitions$easeMedium`,
    },
  },
});

const StyledContentBox = styled(Box, {
  padding: '$2 $6 $6',

  '@md': {
    paddingRight: '$12',
  },

  variants: {
    noContentPadding: {
      true: {
        padding: 0,
      },
    },
  },
});

type StateProps =
  | {
      value?: never;
      setValue?: never;
    }
  | {
      /** Controlled state of the expandable */
      value: string[];
      /** State setter for the controlled state. Required in order to use built-in Expandable controls. */
      setValue: React.Dispatch<React.SetStateAction<string[]>>;
    };

type Props = {
  /**
   * Id(s) of the item(s) that will be opened on load.
   */
  defaultValue?: string[];
} & StateProps;
interface CompoundAccordionProps extends React.FC<React.PropsWithChildren<Props>> {
  Item: typeof Item;
}

export const Accordion = (({ children, defaultValue, value, setValue }) => {
  return (
    <StyledAccordion type="multiple" defaultValue={defaultValue} value={value} onValueChange={setValue}>
      {children}
    </StyledAccordion>
  );
}) as CompoundAccordionProps;

interface ItemProps {
  /**
   * Determines whether the indicator button has a background or not.
   */
  hasChevronBg?: boolean;
  /**
   * Heading that will become the trigger of the Accordion.Item.
   */
  heading: string;
  /**
   * The correct heading level of the trigger inside the document.
   */
  headingLevel?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   * A unique id tied to the item. Used in conjunction with defaultValue.
   */
  id?: string;
  /**
   * Icon shown to the left of the text.
   */
  leftIcon?: JSX.Element;
  /**
   * Option to turn off the content padding of the item, useful for when the item contains an image only.
   */
  noContentPadding?: boolean;
}

const Item: React.FC<React.PropsWithChildren<ItemProps>> = ({
  children,
  hasChevronBg = true,
  heading,
  headingLevel = 'h3',
  id,
  leftIcon,
  noContentPadding,
}) => {
  const generatedId = useId();
  const value = id ? id : generatedId;
  return (
    <StyledItem value={value}>
      <Heading as={headingLevel} size="2XS">
        <StyledTrigger>
          {leftIcon}
          <StyledHeadingContainer hasLeftIcon={!!leftIcon}>{heading}</StyledHeadingContainer>
          <StyledChevron aria-hidden isTransparent={!hasChevronBg}>
            <ChevronDownIcon />
          </StyledChevron>
        </StyledTrigger>
      </Heading>
      <StyledContent>
        <StyledContentBox noContentPadding={noContentPadding}>{children}</StyledContentBox>
      </StyledContent>
    </StyledItem>
  );
};

Accordion.Item = Item;

StyledContent.displayName = 'styled(Accordion.Content)';
StyledChevron.displayName = 'styled(Chevron)';
StyledTrigger.displayName = 'styled(Accordion.Trigger)';
StyledItem.displayName = 'styled(Accordion.Item)';
StyledAccordion.displayName = 'styled(Accordion)';
Accordion.displayName = 'Accordion';
Item.displayName = 'Accordion.Item';
Accordion.Item.displayName = 'Accordion.Item';
