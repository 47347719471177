import React, { ReactNode } from 'react';

import { invisibleStyle } from './Invisible.css';
import { getClassNames } from '../../util/css';

export const Invisible: React.FC<{ children: ReactNode; className?: string }> = ({ children, className = '' }) => {
  const classNames = getClassNames(invisibleStyle, className);
  return <span className={classNames}>{children}</span>;
};

Invisible.displayName = 'Invisible';
