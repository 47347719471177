import React, { forwardRef, InputHTMLAttributes, useRef } from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { FormatOptions, useNumberInput } from '../../hooks/useNumberInput';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { mergeRefs } from '../../util';
import { InputBaseProps, StyledInput } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Label } from '../Input/InputLabel';
import { Stack } from '../Stack/Stack';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
export type InputNumberHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'autoComplete'
  | 'placeholder' /** used for controlled inputs */
  | 'min'
  | 'max'
  | 'step'
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
>;

interface Props extends InputBaseProps, InputNumberHTMLAttributes {
  defaultValue?: number | string;
  formatOptions?: FormatOptions;
}

export const getInputMode = (formatOptions?: FormatOptions) =>
  formatOptions?.minimumFractionDigits || formatOptions?.maximumFractionDigits ? 'decimal' : 'numeric';

export const InputNumber = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { defaultValue, min, max, step, onChange, onBlur, formatOptions, ...inputProps } = props;
  const { error, isOptional, label, hint, isDisabled, isReadOnly, name, ...rest } = inputProps;
  const mockRef = useRef<HTMLInputElement>(null);
  const mergedRef = mergeRefs([ref, mockRef]);

  const {
    onKeyDown,
    onChange: numberOnChange,
    ariaMessage,
    formattedValue,
    onFocus,
    onMouseUp,
  } = useNumberInput({ defaultValue, min, max, step, onChange, formatOptions }, mockRef);

  // Generate IDs
  const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });

  useValidationErrorEvent({ error, name }, mockRef);

  return (
    <div>
      <Stack gap="2">
        <Label htmlFor={inputId} isOptional={isOptional}>
          {label}
        </Label>
        <div>
          <div>
            <VisuallyHidden ariaLive="off">
              <input
                tabIndex={-1}
                aria-hidden="true"
                ref={mergedRef}
                name={name}
                onFocus={() => {
                  if (mockRef) mockRef.current?.focus();
                }}
              />
            </VisuallyHidden>

            <StyledInput
              ref={mockRef}
              name={`${name}-visual`}
              id={inputId}
              type="text"
              value={formattedValue}
              onKeyDown={onKeyDown}
              onChange={numberOnChange}
              onBlur={({ target, ...blurEventProps }) => {
                // Manually call the the passed blur with the mocked ref
                if (mockRef.current && onBlur) {
                  onBlur({ target: mockRef?.current, ...blurEventProps });
                }
              }}
              onFocus={e => {
                onFocus(e);
              }}
              onMouseUp={onMouseUp}
              autoComplete="off"
              inputMode={getInputMode(formatOptions)}
              // TODO: We might want aria-roledescription, but might want to audit that first.
              // aria-roledescription="Nummer veld"
              disabled={isDisabled}
              readOnly={isReadOnly}
              aria-describedby={describedBy}
              aria-invalid={error ? true : undefined}
              isInvalid={!!error}
              {...rest}
            />
            <VisuallyHidden ariaLive="assertive">{ariaMessage}</VisuallyHidden>
          </div>

          {error ? <Error id={errorId}>{error}</Error> : null}
        </div>
        {hint && <Hint id={hintId}>{hint}</Hint>}
      </Stack>
    </div>
  );
});
