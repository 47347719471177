import React, { FC, useEffect } from 'react';

import { useI18nTranslation } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { ButtonLink } from '../ButtonLink/ButtonLink';

const Wrapper = styled('div', {
  position: 'absolute',
  zIndex: 1,
  top: '$2',
  left: '$2',
  transform: 'translateX(-120%)',
  '&:focus-within': {
    transform: 'translateX(0)',
  },
});

const Main: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <main id="main">{children}</main>;
};

const SkipLink: FC = () => {
  const buttonText = useI18nTranslation('skipToMain');
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const mainCounter = document.querySelectorAll('[id="main"]').length;
      if (mainCounter !== 1) {
        // eslint-disable-next-line no-console
        console.error(
          `Error: The <SkipToMain.SkipLink> button requires one item to be identified as "main", but this amount was found: ${mainCounter}. Use <SkipToMain.Main> to wrap the main content that can be skipped to, and make sure it is only rendered once per page. Check the docs of \`SkipToMain\` for more info.`,
        );
      }
    }
  }, []);

  return (
    <Wrapper>
      <ButtonLink action="secondary" href="#main">
        {buttonText}
      </ButtonLink>
    </Wrapper>
  );
};

export const SkipToMain = { SkipLink, Main };

SkipLink.displayName = 'SkipToMain.SkipLink';
