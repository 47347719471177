import { FC, createContext, useContext, ComponentType } from 'react';

import { LayoutServiceData, ComponentRendering } from '@sitecore/types/lib';

import renderError from '../components/renderError';

export type ComponentFactory = (componentName: string) => ComponentType<ComponentRendering> | null;
export type ComponentsDefinition = Record<string, ComponentType<any>>; // eslint-disable-line @typescript-eslint/no-explicit-any

const defaultComponentFactory = () => {
  throw new Error('No component factory provided');
};

const ComponentFactoryContext = createContext<ComponentFactory>(defaultComponentFactory);

const LayoutDataContext = createContext<LayoutServiceData['sitecore'] | null>(null);

export const useComponentFactory = (): ComponentFactory => useContext(ComponentFactoryContext);
export const useLayoutData = (): LayoutServiceData['sitecore'] => {
  const context = useContext(LayoutDataContext);
  if (!context) throw new Error('useLayoutData must be wrapped inside LayoutDataContext provider');
  return context;
};

type Props = {
  components: ComponentsDefinition;
  layoutData: LayoutServiceData;
};

export const SitecoreContext: FC<React.PropsWithChildren<Props>> = ({ layoutData, components, children }) => {
  const componentFactory: ComponentFactory = (componentName: string) => {
    const component = components[componentName];
    return component ?? renderError(componentName);
  };

  return (
    <LayoutDataContext.Provider value={layoutData.sitecore}>
      <ComponentFactoryContext.Provider value={componentFactory}>{children}</ComponentFactoryContext.Provider>
    </LayoutDataContext.Provider>
  );
};
