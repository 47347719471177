import { forwardRef, InputHTMLAttributes } from 'react';

import { Input, InputBaseProps } from '../Input/Input';

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputTelephoneHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'placeholder' | 'defaultValue' | 'value' /** used for controlled inputs */ | 'onChange' | 'onBlur' | 'onFocus'
>;

/** Allowed autocomplete values for InputTelephone component */
type InputTelephoneAutocompleteAttribute =
  | 'tel'
  | 'home tel'
  | 'work tel'
  | 'mobile tel'
  | 'fax tel'
  | 'pager tel'
  | 'off';

/** Allowed autocomplete values with section-* prefix for InputTelephone component */
type InputTelephoneAutocompleteAttributeWithSection =
  | InputTelephoneAutocompleteAttribute
  | `section-${string} ${InputTelephoneAutocompleteAttribute}`;

interface InputTelephoneProps extends InputBaseProps {
  /**
   * Custom `autoComplete` attribute specifically for the telephone input. More info on when to
   * use a certain value can be found here:
   * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls:-the-autocomplete-attribute
   */
  autoComplete: InputTelephoneAutocompleteAttributeWithSection;
}

type Props = InputTelephoneProps & InputTelephoneHTMLAttributes;

export const InputTelephone = forwardRef<HTMLInputElement, Props>(
  ({ label, hint, error, isOptional, autoComplete, isDisabled, isReadOnly, ...rest }, ref) => {
    return (
      <Input
        ref={ref}
        type="tel" // recommended instead of inputmode="tel"
        label={label}
        hint={hint}
        error={error}
        isOptional={isOptional}
        autoComplete={autoComplete}
        disabled={isDisabled}
        readOnly={isReadOnly}
        {...rest}
      />
    );
  },
);
