import { FC, useEffect } from 'react';

import { I18nTranslationProvider } from './i18n';
import { Locale, LocaleProvider } from './locale';
import { MediaQueryProvider } from './mediaQuery';
import { themeClass } from '../theme.css';
import getI18nTranslations from '../util/i18nTranslations';

interface Props {
  locale?: Locale;
  platformHint?: 'desktop' | 'mobile';
}

export const SparkyProvider: FC<React.PropsWithChildren<Props>> = ({ children, locale = 'nl-NL', platformHint }) => {
  // While the theme class will likely be added through _document for SSR, this doesn't apply to projects that don't
  //  use Next. To ensure that other projects still get a theme applied, the SparkyProvider also adds the themeClass to
  //  the body.
  useEffect(() => {
    document.body.classList.add(themeClass);
  }, []);

  return (
    <MediaQueryProvider platformHint={platformHint}>
      <LocaleProvider locale={locale}>
        <I18nTranslationProvider labels={getI18nTranslations(locale)}>{children}</I18nTranslationProvider>
      </LocaleProvider>
    </MediaQueryProvider>
  );
};
