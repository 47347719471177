// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSolarPanelIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12.9 4.81a.905.905 0 0 1-.9.904.905.905 0 0 1-.9-.904v-.905c0-.498.405-.905.9-.905s.9.407.9.905v.905Zm-7.2 7.238a.905.905 0 0 1-.9.904h-.9a.905.905 0 0 1 0-1.81h.9c.495 0 .9.408.9.906ZM5.637 7.83a.893.893 0 0 0 1.269 0 .904.904 0 0 0 0-1.275l-.639-.643a.893.893 0 0 0-1.269 0 .904.904 0 0 0 0 1.276l.639.642Zm13.365-.642-.639.642a.893.893 0 0 1-1.269 0 .904.904 0 0 1 0-1.275l.639-.643a.893.893 0 0 1 1.269 0 .904.904 0 0 1 0 1.276Zm1.098 3.954h-.9a.905.905 0 0 0 0 1.81h.9a.905.905 0 0 0 0-1.81ZM5.79 14.762h5.391c.207 0 .369.163.369.37v1.973a.366.366 0 0 1-.369.371H4.602a.372.372 0 0 1-.36-.461l.315-1.285c.144-.57.648-.968 1.233-.968Zm6.66.37v1.982c0 .2.162.371.369.371h6.588a.367.367 0 0 0 .351-.461l-.324-1.294a1.27 1.27 0 0 0-1.224-.968h-5.391a.372.372 0 0 0-.369.37Zm7.362 3.249c.171 0 .315.118.36.28v-.009l.432 1.747a1.277 1.277 0 0 1-1.233 1.592h-6.543a.372.372 0 0 1-.369-.371v-2.868c0-.2.162-.371.369-.371h6.984Zm-8.631 0H4.188a.376.376 0 0 0-.36.28l-.441 1.756A1.274 1.274 0 0 0 4.62 22h6.561a.366.366 0 0 0 .369-.371v-2.877a.366.366 0 0 0-.369-.371Zm4.581-5.564H8.238a.757.757 0 0 1-.756-.634 4.452 4.452 0 0 1-.081-.805c0-2.551 2.061-4.623 4.599-4.623 2.538 0 4.599 2.072 4.599 4.623 0 .317-.045.615-.081.805a.773.773 0 0 1-.756.634Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgSolarPanelIcon;
SvgSolarPanelIcon.displayName = 'SvgSolarPanelIcon';
