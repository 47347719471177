import React, { FC } from 'react';

import { StickyNavLinks } from './types';
import { NavLink } from '../NavLink/NavLink';
import { Stack } from '../Stack/Stack';

interface Props {
  links: StickyNavLinks;
}

export const StickyNavDesktop: FC<Props> = ({ links }) => {
  return (
    <Stack gap="2" as="ul">
      {links.map(link => {
        return (
          <li key={link.href}>
            <NavLink href={link.href} isCurrent={link.active} target={link.target} variant="secondary">
              {link.text}
            </NavLink>
          </li>
        );
      })}
    </Stack>
  );
};

StickyNavDesktop.displayName = 'StickyNavDesktop';
