import { FC } from 'react';

import { CenterInViewport } from '@custom-components/CenterInViewport';
import { I18nProvider, useTranslation } from '@i18n';
import { Card, Heading, Stack, Text } from '@sparky';

import { getOverloadDictionary } from '../i18n';

const OverloadNotification: FC = () => {
  const { t } = useTranslation();

  return (
    <CenterInViewport>
      <Card>
        <Card.Content>
          <Stack gap="3">
            <Stack alignY="center" direction="row" gap="3">
              <Heading as="h3" size="M">
                {t('title')}
              </Heading>
            </Stack>
            <Text>{t('error')}</Text>
          </Stack>
        </Card.Content>
      </Card>
    </CenterInViewport>
  );
};

export const OverloadErrorPage: FC = () => {
  return (
    <I18nProvider dictionary={getOverloadDictionary}>
      <OverloadNotification />
    </I18nProvider>
  );
};
