// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgThermometerIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M12.002 21.994a5.187 5.187 0 0 1-2.886-.88 4.812 4.812 0 0 1-1.828-2.303 4.567 4.567 0 0 1-.102-2.887 4.77 4.77 0 0 1 1.66-2.415v-8.24a2.88 2.88 0 0 1 .159-1.23c.141-.394.367-.757.663-1.064a3.163 3.163 0 0 1 1.057-.72 3.295 3.295 0 0 1 2.543 0c.4.167.76.413 1.057.72.296.307.522.67.663 1.065.142.394.195.813.158 1.229v8.24a4.77 4.77 0 0 1 1.667 2.418c.28.95.245 1.96-.102 2.89a4.812 4.812 0 0 1-1.833 2.307 5.188 5.188 0 0 1-2.893.876l.017-.006Zm0-18.646a2.067 2.067 0 0 0-1.421.564c-.377.36-.589.848-.589 1.357v8.786l-.24.163A3.706 3.706 0 0 0 8.33 16.06a3.524 3.524 0 0 0-.01 2.286c.25.744.742 1.393 1.404 1.853.663.46 1.461.709 2.281.709.82 0 1.618-.248 2.28-.709a3.702 3.702 0 0 0 1.406-1.853 3.524 3.524 0 0 0-.01-2.286 3.707 3.707 0 0 0-1.423-1.84l-.24-.164V5.269a1.882 1.882 0 0 0-.592-1.357 2.073 2.073 0 0 0-1.424-.564Z" />
    <path d="M13.021 14.884V8.052a.928.928 0 0 0-.054-.394.963.963 0 0 0-.215-.34 1.021 1.021 0 0 0-.34-.23 1.062 1.062 0 0 0-1.155.23.965.965 0 0 0-.215.34.93.93 0 0 0-.054.394v6.81c-.57.225-1.04.63-1.33 1.148a2.433 2.433 0 0 0-.265 1.692c.12.576.446 1.094.922 1.467a2.745 2.745 0 0 0 1.69.576c.616 0 1.213-.203 1.69-.576.476-.373.802-.891.921-1.467a2.433 2.433 0 0 0-.264-1.692 2.622 2.622 0 0 0-1.331-1.148v.022Z" />
  </Icon>
);
export default SvgThermometerIcon;
SvgThermometerIcon.displayName = 'SvgThermometerIcon';
