// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHomeIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="m20.708 11.293-8-7.997a1 1 0 0 0-1.42 0l-8 7.997a1 1 0 0 0-.21 1.09 1 1 0 0 0 .92.62h1V20a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-5.998h4V20a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-6.997h1a1 1 0 0 0 .92-.62 1 1 0 0 0-.21-1.09Z" />
  </Icon>
);
export default SvgHomeIcon;
SvgHomeIcon.displayName = 'SvgHomeIcon';
