import { FC, createElement } from 'react';

import logger from '@common/log';
import type { ComponentProps, ComponentRendering, LayoutServiceData, RouteData } from '@sitecore/types/lib';

import { useContent, wrapComponent, isSitecoreComponent } from './Placeholder';
import { useComponentFactory, useLayoutData } from './SitecoreContext';
import type { ComponentFactory } from './SitecoreContext';

const PLACEHOLDER_APPLICATION_PREFIX = 'jss-app-';
export const PLACEHOLDER_APPLICATION_REGEX = /^jss-app-(?<application>.+)/;

export const getPlaceholderNameFromRendering = (rendering: RouteData | ComponentRendering): string => {
  const names = Object.keys(rendering?.placeholders ?? {});
  const placeholderName = names.find(name => PLACEHOLDER_APPLICATION_REGEX.test(name));
  if (!placeholderName) throw new Error(`No placeholder found matching ${PLACEHOLDER_APPLICATION_REGEX}`);
  return placeholderName;
};

export const usePlaceholderContent = <T extends Record<string, ComponentRendering>>(): T => {
  const { route } = useLayoutData();
  const placeholderName = getPlaceholderNameFromRendering(route);

  return route.placeholders[placeholderName].reduce(
    (acc, rendering) => ({
      ...acc,
      [rendering.componentName]: rendering,
    }),
    {} as T,
  );
};

export const getApplicationForPlaceholder = (
  name: string,
  componentFactory: ComponentFactory,
  context: LayoutServiceData['sitecore']['context'],
) => {
  const componentName = name.replace(PLACEHOLDER_APPLICATION_PREFIX, '');
  const component = componentFactory(componentName);
  if (!component) {
    logger.error('gL9Tc0', 'Application component not found by factory:', componentName);
    return null;
  }
  const props: ComponentProps = { componentName, context };
  const application = createElement(component, props);
  return isSitecoreComponent(application) ? wrapComponent(application, context) : null;
};

export const AppPlaceholder: FC = () => {
  const componentFactory = useComponentFactory();
  const { context, route } = useLayoutData();
  const ensuredRendering = useContent(route);
  const placeholderName = getPlaceholderNameFromRendering(ensuredRendering);
  return getApplicationForPlaceholder(placeholderName, componentFactory, context);
};
