import React, { FC } from 'react';

import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

interface Props {
  children: string;
  /**
   * Description that conveys the semantic meaning of the strikethrough text,
   * specifically why it is no longer relevant.
   */
  semanticDescription: string;
  className?: never;
}

export const Strikethrough: FC<Props> = ({ children, semanticDescription, className }) => {
  return (
    <s className={className}>
      <VisuallyHidden>{semanticDescription}</VisuallyHidden>
      {children}
    </s>
  );
};

Strikethrough.displayName = 'Strikethrough';
