import { forwardRef, InputHTMLAttributes, KeyboardEventHandler, useEffect, useRef, useState } from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { ChangeHandler, useNumberInput } from '../../hooks/useNumberInput';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { useI18nTranslation } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { Box } from '../Box/Box';
import { InputBaseProps, StyledInput } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Stack } from '../Stack/Stack';
import { Stretch } from '../Stretch/Stretch';
import { Text } from '../Text/Text';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

const InputStyled = styled(StyledInput, {
  variants: {
    isDecimal: {
      true: {
        width: '9rem',
      },
    },
    align: {
      right: {
        textAlign: 'right',
      },
    },
  },
});

const DisabledDecimals = styled(Stack.Item, {
  width: '9rem',
  alignSelf: 'stretch',
});

function getSplittedValue(value: string, maximumFractionDigits: number) {
  if (!value) {
    return ['', ''];
  }

  return Number(value).toFixed(maximumFractionDigits).split('.');
}

const DisabledDecimal: React.FC = () => (
  <Box borderRadius="s" backgroundColor="backgroundTertiary" paddingX="2">
    <Stretch>
      <Stack alignY="center">
        <Text color="textLowEmphasis" size="BodyL" weight="bold">
          <span role="presentation">X</span>
        </Text>
      </Stack>
    </Stretch>
  </Box>
);

type Unit = 'Wh' | 'kWh' | 'm3' | 'GJ';
type UnitSymbol = 'Wh' | 'kWh' | 'm³' | 'GJ';
type InUnitLabel = 'inWattHour' | 'inKilowattHour' | 'inCubicMetres' | 'inGigajoule';

const unitMap: Record<
  Unit,
  {
    symbol: UnitSymbol;
    inLabel: InUnitLabel;
  }
> = {
  Wh: { symbol: 'Wh', inLabel: 'inWattHour' },
  kWh: { symbol: 'kWh', inLabel: 'inKilowattHour' },
  m3: { symbol: 'm³', inLabel: 'inCubicMetres' },
  GJ: { symbol: 'GJ', inLabel: 'inGigajoule' },
};

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputMeterReadingHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'placeholder' | 'value' /** used for controlled inputs */ | 'onChange' | 'onBlur' | 'onFocus'
>;

interface InputMeterReadingProps extends InputBaseProps {
  /**
   * Takes care of the label helper and unit suffix.
   */
  maximumFractionDigits?: number;
  placeholderDecimal?: string;
  unit: Unit;
}

interface Props extends InputMeterReadingProps, InputMeterReadingHTMLAttributes {
  defaultValue?: number | string;
  onChange?({ target, type }: ChangeHandler): void;
}

export const InputMeterReading = forwardRef<HTMLInputElement, Props>(
  (
    {
      unit,
      label,
      maximumFractionDigits = 3,
      isOptional,
      error,
      hint,
      isDisabled,
      isReadOnly,
      name,
      defaultValue = '',
      placeholder,
      placeholderDecimal,
      value,
      onBlur,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });
    const i18nOptionalText = useI18nTranslation('optional');
    const i18nInUnitText = useI18nTranslation(unitMap[unit].inLabel);
    const [defaultIntegerValue, defaultDecimalValue] = getSplittedValue(String(defaultValue), maximumFractionDigits);

    const mockRef = useRef<HTMLInputElement>(null);
    const localRefInteger = useRef<HTMLInputElement>(null);
    const localRefDecimal = useRef<HTMLInputElement>(null);
    const mergedRef = mergeRefs([ref, mockRef]);

    const numberInputOptions = { formatOptions: { useGrouping: false }, min: 0 };

    const { value: integerInputValue, ...integerProps } = useNumberInput(
      { defaultValue: defaultIntegerValue, ...numberInputOptions },
      localRefInteger,
    );
    const { value: decimalInputValue, ...decimalProps } = useNumberInput(
      { defaultValue: defaultDecimalValue, max: Number(`9`.repeat(maximumFractionDigits)), ...numberInputOptions },
      localRefDecimal,
    );

    const [integerValue, setIntegerValue] = useState(integerInputValue ?? defaultIntegerValue);
    const [decimalValue, setDecimalValue] = useState(decimalInputValue ?? defaultDecimalValue);

    useValidationErrorEvent({ error, name }, mockRef);

    useEffect(() => {
      setIntegerValue(integerInputValue);
    }, [integerInputValue]);

    useEffect(() => {
      setDecimalValue(decimalInputValue);
    }, [decimalInputValue]);

    useEffect(() => {
      if (!value) return;

      const [newIntegerValue, newDecimalValue] = getSplittedValue(String(value), maximumFractionDigits);

      setIntegerValue(newIntegerValue);
      setDecimalValue(newDecimalValue);
    }, [maximumFractionDigits, value]);

    useEffect(() => {
      const newValue = decimalValue ? `${integerValue || 0}.${decimalValue}` : String(integerValue);

      if (!mockRef?.current || mockRef.current.value === newValue) {
        return;
      }

      mockRef.current.value = newValue;

      if (onChange) {
        onChange({ target: mockRef.current, type: 'change' });
      }
    }, [integerValue, decimalValue, onChange]);

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
      const target = event.target as HTMLInputElement;
      const caretPos = target.selectionStart;
      const totalLength = target.value.length;

      if ([',', '.'].includes(event.key)) {
        event.preventDefault();

        if (localRefDecimal.current && caretPos === totalLength) {
          localRefDecimal.current.focus();
          localRefDecimal.current.select();

          return;
        }
      }

      integerProps.onKeyDown(event);
    };

    return (
      <div>
        <Stack direction="row" gap="3" alignX="end" alignY="end">
          <Stack.Item grow>
            <label htmlFor={inputId}>
              <Stack gap="2" alignY="center">
                <Text size="BodyS" weight="bold">
                  {label}
                  <VisuallyHidden>{i18nInUnitText}</VisuallyHidden>
                  {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
                </Text>
                <Stack direction="row" gap="2" alignY="end">
                  <Stack.Item grow>
                    <VisuallyHidden ariaLive="off">
                      <input
                        tabIndex={-1}
                        id={inputId}
                        aria-hidden="true"
                        ref={mergedRef}
                        name={name}
                        aria-invalid={error ? true : undefined}
                        aria-describedby={describedBy}
                        onFocus={() => {
                          if (localRefInteger) localRefInteger.current?.focus();
                        }}
                      />
                    </VisuallyHidden>
                    <InputStyled
                      ref={localRefInteger}
                      name={`${name}-visual`}
                      type="text"
                      inputMode="numeric"
                      aria-describedby={describedBy}
                      aria-invalid={error ? true : undefined}
                      disabled={isDisabled}
                      readOnly={isReadOnly}
                      isInvalid={!!error}
                      align="right"
                      value={integerValue}
                      placeholder={placeholder}
                      onChange={integerProps.onChange}
                      onFocus={integerProps.onFocus}
                      onMouseUp={integerProps.onMouseUp}
                      onKeyDown={onKeyDown}
                      onBlur={({ target, ...blurEventProps }) => {
                        // Manually call the the passed blur with the mocked ref
                        if (mockRef.current && onBlur) {
                          onBlur({ target: mockRef?.current, ...blurEventProps });
                        }
                      }}
                      autoComplete="off"
                      {...rest}
                    />
                    <VisuallyHidden ariaLive="assertive">{integerProps.ariaMessage}</VisuallyHidden>
                  </Stack.Item>
                  <Stack.Item>
                    <Text size="BodyS" weight="bold">
                      ,
                    </Text>
                  </Stack.Item>
                  {maximumFractionDigits > 0 ? (
                    <Stack.Item>
                      <InputStyled
                        ref={localRefDecimal}
                        name={`${name}-visual-decimal`}
                        type="text"
                        inputMode="numeric"
                        aria-describedby={describedBy}
                        aria-invalid={error ? true : undefined}
                        disabled={isDisabled}
                        readOnly={isReadOnly}
                        isInvalid={!!error}
                        maxLength={maximumFractionDigits}
                        value={decimalValue}
                        placeholder={placeholderDecimal}
                        onChange={decimalProps.onChange}
                        onFocus={decimalProps.onFocus}
                        onMouseUp={decimalProps.onMouseUp}
                        onKeyDown={decimalProps.onKeyDown}
                        autoComplete="off"
                        isDecimal
                        {...rest}
                      />
                      <VisuallyHidden ariaLive="assertive">{decimalProps.ariaMessage}</VisuallyHidden>
                    </Stack.Item>
                  ) : (
                    <DisabledDecimals>
                      <Stretch height>
                        <Stack direction="row" gap="1" alignX="justify">
                          <DisabledDecimal />
                          <DisabledDecimal />
                          <DisabledDecimal />
                        </Stack>
                      </Stretch>
                    </DisabledDecimals>
                  )}
                </Stack>
              </Stack>
            </label>
            {error ? <Error id={errorId}>{error}</Error> : null}
          </Stack.Item>
          <Stack.Item>
            <Text size="BodyS" color="textPrimary" weight="bold">
              {unitMap[unit].symbol}
            </Text>
            {error ? <div style={{ height: '41px' }} /> : null}
          </Stack.Item>
        </Stack>
        {hint ? (
          <Box paddingTop="2">
            <Hint id={hintId}>{hint}</Hint>
          </Box>
        ) : null}
      </div>
    );
  },
);

StyledInput.displayName = 'styled(input)';
InputMeterReading.displayName = 'InputMeterReading';
