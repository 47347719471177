import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_NextBestAction_ActionFeedbackDataRequestModel } from '@monorepo-types/dc';
import { ResponseModels_NextBestAction_NextBestActionResponseModel } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type GetNextBestActions = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  servingPointId?: number;
};
/**
 * GetNextBestActions
 * Get Next Best Actions
 * @returns ResponseModels_NextBestAction_NextBestActionResponseModel Success
 */
export function getNextBestActions(
  { businessUnit, label, customerId, servingPointId }: GetNextBestActions,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_NextBestAction_NextBestActionResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/nextbestaction`,
      query: { servingPointId },
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type PutNextBestActionFeedback = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody: RequestModels_NextBestAction_ActionFeedbackDataRequestModel;
};
/**
 * PutNextBestActionFeedback
 * Put Next Best Action Feedback
 * @returns any Success
 */
export function putNextBestActionFeedback(
  { businessUnit, label, customerId, requestBody }: PutNextBestActionFeedback,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/nextbestaction/feedback`,
      body: requestBody,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}
