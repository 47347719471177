import React, { FC, createContext, useContext } from 'react';

export type Locale = 'nl-NL' | 'en-NL' | 'nl-BE' | 'fr-BE' | 'en-GB';
export type Language = 'nl' | 'en' | 'fr';
export type CountryCode = 'NL' | 'BE' | 'GB';
export type I18n = {
  language: Language;
  languages: Language[];
  locale: Locale;
  locales: Locale[];
  countryCode: CountryCode;
};

type ApplicationProviderContext = {
  locale: Locale;
  locales: Locale[];
  language: Language;
  languages: Language[];
  searchParams: URLSearchParams;
  isEditMode: boolean | undefined;
};

// TODO: Find another solution for default value locale
const ApplicationContext = createContext<ApplicationProviderContext>({
  locale: 'nl-NL',
  locales: ['nl-NL'],
  language: 'nl',
  languages: ['nl'],
  searchParams: new URLSearchParams(),
  isEditMode: false,
});

type Props = {
  path?: string;
  i18n: I18n;
  children: React.ReactNode;
  isEditMode?: boolean;
};

export const useApplication = (): ApplicationProviderContext => useContext(ApplicationContext);

export const ApplicationProvider: FC<React.PropsWithChildren<Props>> = ({ children, path, i18n, isEditMode }) => {
  const searchParams = new URLSearchParams(path?.replace(/^[^?]*/, ''));
  return (
    <ApplicationContext.Provider value={{ ...i18n, searchParams, isEditMode }}>{children}</ApplicationContext.Provider>
  );
};
