// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSuccessIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="m11.67 15.842 5.944-5.967a.957.957 0 0 0 .006-1.337l.014.015a.923.923 0 0 0-1.318-.01l-5.972 5.974L8.26 12.43a.966.966 0 1 0-1.366 1.366l2.726 2.726a.97.97 0 0 0 1.37.003l.682-.683-.001-.001ZM12 22C6.478 22 2 17.522 2 12S6.478 2 12 2s10 4.478 10 10-4.478 10-10 10Z"
    />
  </Icon>
);
export default SvgSuccessIcon;
SvgSuccessIcon.displayName = 'SvgSuccessIcon';
