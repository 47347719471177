/* eslint-disable no-console */
import type { Level, LogContext } from './types';
import { envBoolean } from '../env';

const isBrowser = typeof window !== 'undefined';
const isPrettyPrintEnabled = envBoolean('LOG_PRETTY');

interface ConsoleLogger extends Console {
  dev: Console['log'];
  fatal: Console['error'];
}

const consoleLogger: ConsoleLogger = Object.assign({}, console, {
  dev: console.log,
  fatal: console.error,
});

const colors: Record<Level, string> = {
  trace: 'darkgrey',
  debug: 'darkslategray',
  info: 'teal',
  warn: 'tomato',
  error: 'red',
  fatal: 'darkred',
};

const badgeStyles = (level: Level): string =>
  `background-color: ${colors[level]}; padding: 2px 4px; border-radius: 3px; margin-right:5px; color: white;`;

const unstyle = 'color: unset; font-weight: normal;';

const suffixStyles = 'color: grey; font-weight: normal;';

const buildPrettyMessage = (uuid: string, message: string, error: Error | undefined, level: Level) => {
  const suffix = error instanceof Error ? error.message : undefined;
  if (suffix) {
    return [`%c${uuid}%c${message} %c(${suffix})`, badgeStyles(level), unstyle, suffixStyles];
  } else {
    return [`%c${uuid}%c${message}`, badgeStyles(level), unstyle];
  }
};

export const pretty = ({ uuid, message, context, error, level = 'info' }: LogContext) => {
  if (isBrowser && isPrettyPrintEnabled) {
    const label = buildPrettyMessage(uuid, message, error, level);
    const isFoldable =
      level === 'trace' ||
      Array.isArray(context) ||
      (Object.prototype.toString.call(context) === '[object Object]' && Object.keys(context).length);
    if (error || isFoldable) {
      console.groupCollapsed(...label);
      if (error) consoleLogger.error(error);
      if (context) console.table(context);
      if (level === 'trace') consoleLogger.trace();
      console.groupEnd();
    } else {
      consoleLogger.log(...label);
    }
  } else {
    // Not fancy, but still readable and "native"
    if (error) {
      consoleLogger.log(uuid, message + ':');
      context && consoleLogger.log(context);
      console.error(error);
    } else {
      consoleLogger[level](uuid, message + (context ? ':' : ''));
      context && consoleLogger.log(context);
    }
  }
};
