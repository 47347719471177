import { styled } from '../../stitches.config';
import { Text } from '../Text/Text';

export const StyledInput = styled('input', {
  appearance: 'none',
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  opacity: 0,
  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
});

export const DragArea = styled('div', {
  position: 'relative',
  outline: '$borderWidths$s dashed $colors$borderDividerMediumEmphasis',
  borderRadius: '$l',
  padding: '$4',
  '@md': {
    padding: '$6',
  },

  '&:hover': {
    [`& .file-picker-fake-button`]: {
      background: '$colors$buttonSecondaryHoverBackground',
    },

    [`&:has(input:active) .file-picker-fake-button`]: {
      background: '$colors$buttonSecondaryPressedBackground',
    },
  },

  variants: {
    hasFocus: {
      true: { outline: '$outlineFocus' },
    },
    hasDrag: {
      true: { background: '$backgroundSecondary' },
    },
  },
});

export const StyledListItem = styled('li', {
  outline: '1px solid $colors$outlineHover',
  borderRadius: '$l',
  padding: '$4',
  wordBreak: 'break-all',
});

export const StyledText = styled(Text, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
