import { Component, ErrorInfo, ReactNode } from 'react';

import * as globalAppInsights from '@common/application-insights';
import { I18nProvider, useTranslation } from '@i18n';
import { NotificationBox } from '@sparky';

interface Props {
  children: ReactNode;
  componentName: string;
}

interface State {
  hasError: boolean;
}

function TranslationFallback() {
  const { t } = useTranslation();
  return <NotificationBox isAlert={true} text={t('errorBoundary.fallbackMessage')} />;
}
class WithErrorLogging extends Component<Props, State> {
  appInsights = globalAppInsights.default;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to Application Insights
    const { componentName } = this.props;

    this.appInsights.trackException({
      exception: error,
      properties: {
        componentName,
        ...errorInfo,
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <I18nProvider dictionary={locale => import(`./../content/${locale}.json`)}>
          <TranslationFallback />
        </I18nProvider>
      );
    }

    return children;
  }
}

export default WithErrorLogging;
