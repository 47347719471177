import env from '@common/env';

import { getContentMask } from './SensitiveContentComponent';
import { DataLayerEvent, DefaultEvent, TrackingEvent } from './types';

type TRACKED_ELEMENT =
  | HTMLAnchorElement
  | HTMLButtonElement
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTableRowElement;

// because environment is a generic name give the exported getter a specific name
export const getDataLayerEnvironment = () => {
  // isApp is based on capacitor's internal util (getPlatformId)
  // https://github.com/ionic-team/capacitor/blob/12c6294b9eb82976b1322f00da9ba5a6004f7977/core/src/util.ts#L28-L38
  const isApp = typeof window !== 'undefined' && (!!window.androidBridge || window.webkit?.messageHandlers?.bridge);
  const target = isApp ? 'app' : 'web';
  const dpxEnvironment = env('ENVIRONMENT') || 'development';
  const container = env('CONTAINER') || 'local';
  return [target, dpxEnvironment, container].join('-');
};

const environment = getDataLayerEnvironment();

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

export const pushToDataLayer = (data: DataLayerEvent) => {
  // NOTE: this environment solution is made because there is no DTAP on GTM-side
  // this is not a final solution but allows for the splitting of traffic until
  // GTM has matured to allow for that in which case it does not need this
  // variable any more.
  window.dataLayer.push({ ...data, environment });
};

const maskSelectedContent = (event: DefaultEvent, element: HTMLElement) => {
  if (!event?.selected_content) return;

  event.selected_content = getContentMask(element) ?? event.selected_content;
};

const getLabel = (element: HTMLElement): string => {
  const labelledBy = element.getAttribute('aria-labelledby');
  if (labelledBy) {
    const labelElement = document.getElementById(labelledBy);
    if (labelElement) {
      return getLabel(labelElement);
    }
  }

  return element.getAttribute('aria-label') || getDataLabel(element) || element.textContent || 'unknown';
};

const getDataLabel = (element: HTMLElement, parentIterations = 0): string | undefined => {
  const dataLabel = element.dataset?.label;

  // Check if the element has a data-label attribute
  if (dataLabel) {
    return dataLabel;
  }

  // Check if there are any children with the data-label attribute
  const dataLabelElement = element.querySelector('[data-label]') as HTMLElement;
  if (dataLabelElement && dataLabelElement.dataset?.label) {
    return dataLabelElement.dataset?.label;
  }

  // Keep on searching for a parent with a data-label attribute for the given iterations
  const parentElement = element.parentElement;
  if (parentIterations > 0 && parentElement) {
    return getDataLabel(parentElement, parentIterations - 1);
  }
};

const getExpanded = (element: HTMLElement) => {
  const expanded = element.getAttribute('aria-expanded') ?? element.dataset.isOpened === 'true';
  if (expanded) {
    // the value is expanded after click/animation, so on click its the opposite
    return expanded === 'true' ? 'false' : 'true';
  }
};

const getSelectChangeEvent = (element: HTMLSelectElement, scope: string): DataLayerEvent => {
  const selected_option = element.options[element.selectedIndex];
  const selected_content = selected_option.dataset?.label || selected_option.text || element.value;

  const selectChangeEvent = {
    event: 'select_option',
    selected_content: `${element.name} ${selected_content}`,
    component_name: scope,
    component_type: element.name,
    dropdown_name: element.name,
  };

  maskSelectedContent(selectChangeEvent, element);

  return selectChangeEvent;
};

const bindSelectChangeEvent = (element: HTMLSelectElement, scope: string) => {
  if (element.dataset.hasChangeListener) {
    return;
  }

  const selectChangeCallback = () => {
    pushToDataLayer(getSelectChangeEvent(element, scope));
    element.removeEventListener('change', selectChangeCallback);
    delete element.dataset.hasChangeListener;
  };

  element.addEventListener('change', selectChangeCallback);
  element.dataset.hasChangeListener = 'true';
};

const getSelectEvent = (
  el: TRACKED_ELEMENT,
  scope: string,
  variant: string | undefined,
  eventType: string,
): DataLayerEvent | undefined => {
  const element = el as HTMLSelectElement;

  // add a change event listener to the select element to monitor changes (these can not be picked up by click events)
  bindSelectChangeEvent(element, scope);

  // make sure the select_dropdown isn't fired twice (windows fires a click event on option clicks as well)
  if (element.dataset.isOpened) {
    return;
  }

  if (eventType === 'click') {
    element.dataset.isOpened = 'true';
  }

  const blurCallback = () => delete element.dataset.isOpened;
  element.addEventListener('blur', () => {
    blurCallback();
    element.removeEventListener('blur', blurCallback);
  });

  return {
    event: 'select_dropdown',
    selected_content: element.name ?? element.textContent ?? 'unknown',
    selected_action: element.dataset.isOpened === 'true' ? 'open' : 'close',
    component_name: scope,
    component_type: element.name,
  };
};

const getButtonEvent = (element: TRACKED_ELEMENT, scope: string, variant: string | undefined): DataLayerEvent => {
  const buttonVariant = variant || element.dataset.variant;
  const isExpandable = element.getAttribute('aria-expanded') !== null;
  const isRadioButton = element.getAttribute('role') === 'radio';
  const selected_content = getLabel(element);
  const component_name = scope;
  const component_type = scope;
  const component_variant = buttonVariant ? { component_variant: buttonVariant } : {};

  if (isExpandable) {
    return {
      event: 'select_expandable',
      component_name,
      component_type,
      selected_action: getExpanded(element) === 'true' ? 'open' : 'close',
      selected_content,
    };
  }

  if (isRadioButton) {
    return {
      event: 'select_button',
      component_name,
      component_type,
      selected_content: (element as HTMLInputElement).value,
    };
  }
  return {
    event: 'select_button',
    component_name,
    component_type,
    selected_content,
    ...component_variant,
  };
};

const getAnchorEvent = (element: TRACKED_ELEMENT, scope: string, variant: string | undefined): DataLayerEvent => {
  const navigation_goal = element.getAttribute('href') ?? undefined;
  const component_variant = variant ? { component_variant: variant } : {};
  return {
    event: 'select_link',
    selected_content: getLabel(element),
    ...component_variant,
    component_type: scope,
    navigation_goal,
  };
};

const getInputEvent = (el: TRACKED_ELEMENT, scope: string): DataLayerEvent | undefined => {
  const element = el as HTMLInputElement;
  const elementName = element.name || element.getAttribute('placeholder') || 'unknown';
  const selected_action = String(element.checked);
  const component_type = scope;

  const label = getDataLabel(element, 3);

  switch (element.type) {
    case 'checkbox': {
      return {
        event: 'select_checkbox',
        selected_content: `${elementName} | ${label ?? element.labels?.item(0)?.innerText}`,
        component_name: scope,
        component_type: elementName,
        selected_action,
      };
    }

    case 'radio': {
      if (selected_action === 'false') {
        return;
      }

      if (['true', 'false'].includes(element.value)) {
        return {
          event: 'select_switch',
          selected_content: label ?? element.parentElement?.parentElement?.innerText ?? element.value,
          component_type: elementName,
          component_name: scope,
          selected_action: element.value,
        };
      }
      return {
        event: 'select_switch',
        selected_content: label ?? element.parentElement?.parentElement?.innerText ?? element.value,
        component_type: elementName,
        component_name: scope,
      };
    }

    default: {
      return {
        event: 'fill_field',
        field_action: 'start',
        field_name: elementName,
        component_type,
      };
    }
  }
};

const getTableRowEvent = (el: TRACKED_ELEMENT, scope: string): DataLayerEvent | undefined => {
  const element = el as HTMLTableRowElement;
  const isExpandable = element.getAttribute('aria-expanded') !== null;

  if (!isExpandable) {
    return;
  }

  const table = element.closest('table');
  const tableLabel =
    (table?.querySelector('caption') ?? table?.querySelector('thead'))?.textContent?.replace(/\s\s/g, ' ').trim() ||
    'table';
  const rowLabel = element.textContent || 'row';

  return {
    event: 'select_expandable',
    component_name: scope,
    component_type: 'table',
    selected_action: getExpanded(element) === 'true' ? 'open' : 'close',
    selected_content: `${tableLabel} - ${rowLabel}`,
  };
};

export const keepFromTracking = (event?: TrackingEvent) => {
  if (!event) return;

  event._tracked = true;
};

export const getEventToDataLayer = (
  event: TrackingEvent,
  scope = 'root',
  variant?: string,
): DataLayerEvent | undefined => {
  if (event._tracked) return;

  let tracked = false;
  let element = event.target as TRACKED_ELEMENT;
  let trackingEvent;

  const trackingEvents: Record<
    string,
    (
      element: TRACKED_ELEMENT,
      scope: string,
      variant: string | undefined,
      eventType: string,
    ) => DataLayerEvent | undefined
  > = {
    a: getAnchorEvent,
    button: getButtonEvent,
    select: getSelectEvent,
    input: getInputEvent,
    tr: getTableRowEvent,
    body: () => undefined,
  };

  while (!tracked && element) {
    const tagName = element.tagName?.toLowerCase();

    if (trackingEvents[tagName]) {
      trackingEvent = trackingEvents[tagName](element, scope, variant, event.type);
      maskSelectedContent(trackingEvent as DefaultEvent, element);

      tracked = true;
    }

    // Track interactions only once
    event._tracked = tracked;

    // Find ancestor until it's a tagName that hopefully has data we can push to the dataLayer
    element = element.parentNode as TRACKED_ELEMENT;
  }

  return trackingEvent;
};
