import { ButtonHTMLAttributes, FC } from 'react';
import React from 'react';

import { styled } from '../../stitches.config';
import { sparkyButtonClassName } from '../Button/Button';
import { StyledContent as StyledPopoverContent } from '../Popover/Popover';

export const StyledOverlayButton = styled('button', {
  background: 'transparent',
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  textAlign: 'inherit',
  color: 'inherit',

  '&::before': {
    content: '',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  /**
   * Focus styles for pseudo element rather than the button
   */
  '@supports selector(:focus-visible)': {
    '&:focus-visible': {
      outline: 'none',
      '&::before': {
        //this shouldnt be necessary, is this a bug?
        outline: '$outlines$outlineFocus',
      },
    },
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: 'none',
      '&::before': {
        //this shouldnt be necessary, is this a bug?
        outline: '$outlineFocus',
      },
    },
  },
});

/**
 * The `StyledOverlayBox` is used to wrap content inside a clickable (button or link) area while ensuring
 * semantichtml.
 *
 * Inspired by the Chakra LinkOverlay: https://chakra-ui.com/docs/navigation/link-overlay
 * More information on nested links: https://www.sarasoueidan.com/blog/nested-links
 */
export const StyledOverlayBox = styled('div', {
  position: 'relative',

  /**
   * Elevate the links (with href) and buttons (except overlay-button) up
   */
  [`& a[href]:not(.overlay-link), button:not(.overlay-button)`]: {
    position: 'relative',
    zIndex: 1,

    /**
     * Interactive elements nested inside a clickable area need a height of at least 44px to be acessible
     */
    [`&:not(.${sparkyButtonClassName})::before`]: {
      content: '',
      width: '100%',
      minHeight: '$targetMinHeight',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  // Make sure Popover appears above the elevated Link
  [`${StyledPopoverContent}`]: {
    zIndex: 2,
  },
});

interface ButtonProps extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  className?: never;
  children: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, className = '', ...props }, ref) => (
  <StyledOverlayButton ref={ref} {...props} className={`${className} overlay-button`}>
    {children}
  </StyledOverlayButton>
));

interface CompoundButtonOverlay {
  Button: typeof Button;
}

export const ButtonOverlay: FC<React.PropsWithChildren<{ className?: never }>> & CompoundButtonOverlay = ({
  className,
  children,
}) => <StyledOverlayBox className={className}>{children}</StyledOverlayBox>;

StyledOverlayButton.displayName = 'styled(OverlayButton)';
StyledOverlayBox.displayName = 'styled(OverlayBox)';
ButtonOverlay.Button = Button;
ButtonOverlay.Button.displayName = 'ButtonOverlay.Button';
