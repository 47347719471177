// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgWaterIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12.78 4.37a1 1 0 0 0-1.56 0C10.69 5 6 11 6 14a6 6 0 0 0 12 0c0-3-4.69-9-5.22-9.63ZM12 18a1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-4 4Z"
    />
  </Icon>
);
export default SvgWaterIcon;
SvgWaterIcon.displayName = 'SvgWaterIcon';
