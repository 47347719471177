// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgExternalIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M19 14v6c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1H5v12h12v-5c0-.55.45-1 1-1s1 .45 1 1Zm1.92-10.38A1.019 1.019 0 0 0 20 3h-6c-.55 0-1 .45-1 1s.45 1 1 1h3.59l-8.3 8.29a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29L19 6.41V10c0 .55.45 1 1 1s1-.45 1-1V4c0-.13-.03-.26-.08-.38Z" />
  </Icon>
);
export default SvgExternalIcon;
SvgExternalIcon.displayName = 'SvgExternalIcon';
