import { FC, PropsWithChildren, ReactElement } from 'react';

import { CardProps } from '../../types';
import { Box, Card, Divider, Heading, Stack } from '../index';

type CompoundBucketProps = FC<
  PropsWithChildren<
    {
      className?: never;
      as?: CardProps['as'];
    } & CompoundBucketHeaderProps
  >
> & {
  Content: typeof Card.Content;
  Actions: typeof Actions;
  Footer: typeof Footer;
};

type CompoundBucketHeaderProps =
  | {
      title: string;
      /**
       * The correct heading level of the bucket title inside the document.
       */
      headingLevel?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    }
  | { title: ReactElement; headingLevel?: never };

const Bucket: CompoundBucketProps = ({ children, className, as = 'div', title, headingLevel = 'h3' }) => {
  const customHeader = typeof title !== 'string';

  return (
    <Card as={as} corners={{ initial: 'square', sm: 'rounded' }} className={className}>
      <Box paddingX="6" paddingTop="5" paddingBottom="4">
        {customHeader ? (
          <>{title}</>
        ) : (
          <Heading as={headingLevel} size="3XS">
            {title}
          </Heading>
        )}
      </Box>
      <Divider />
      {children}
    </Card>
  );
};
const Footer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Divider />
      <Box padding="6">
        <Stack direction="column" gap="6">
          {children}
        </Stack>
      </Box>
    </>
  );
};

const Actions: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack.Item>
      <Stack direction={{ initial: 'column', md: 'row' }} gap="5">
        {children}
      </Stack>
    </Stack.Item>
  );
};

const Content: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box padding="6">
      <Stack direction="column" gap="6">
        {children}
      </Stack>
    </Box>
  );
};

Bucket.Content = Content;
Bucket.Actions = Actions;
Bucket.Footer = Footer;

export { Bucket };
