// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgYoutubeIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M9.942 14.635V9.01l5.408 2.822-5.408 2.802Zm11.872-6.598s-.196-1.38-.795-1.987c-.76-.797-1.615-.801-2.005-.848C16.214 5 12.011 5 12.011 5h-.008S7.802 5 5 5.202c-.392.047-1.244.05-2.005.848-.6.608-.795 1.987-.795 1.987S2 9.656 2 11.277v1.518c0 1.618.2 3.239.2 3.239s.196 1.38.795 1.987c.763.797 1.762.772 2.207.856 1.6.153 6.805.202 6.805.202s4.205-.006 7.007-.21c.392-.047 1.245-.05 2.005-.848.6-.608.795-1.987.795-1.987s.2-1.62.2-3.24v-1.518c0-1.62-.2-3.239-.2-3.239Z"
    />
  </Icon>
);
export default SvgYoutubeIcon;
SvgYoutubeIcon.displayName = 'SvgYoutubeIcon';
