import React from 'react';

import { InnerNotificationBox, NotificationBoxStyled, NotificationProps } from './NotificationBox';
import { styled } from '../../stitches.config';
import { PageGrid } from '../PageGrid/PageGrid';

type NotificationBarProps = Omit<NotificationProps, 'emphasis' | 'isElevated' | 'isAlert'>;

const NotificationBarStyled = styled(NotificationBoxStyled, {
  borderRadius: '$none',
  borderWidth: '$none',
  variants: {
    emphasis: {
      low: {
        padding: 0,
        paddingY: '$4',
      },
    },
  },
});

export const NotificationBar = React.forwardRef<HTMLDivElement, React.PropsWithChildren<NotificationBarProps>>(
  ({ variant = 'info', title, text }, ref) => {
    return (
      <NotificationBarStyled ref={ref} variant={variant} className={ownClassName} role="alert">
        <PageGrid>
          <PageGrid.Item gridColumn="1/-1">
            <InnerNotificationBox variant={variant} title={title} text={text} headingLevel="h2" />
          </PageGrid.Item>
        </PageGrid>
      </NotificationBarStyled>
    );
  },
);

const ownClassName = 'notification-bar-sparky';

NotificationBar.toString = () => `.${ownClassName}`;

NotificationBarStyled.displayName = 'styled(NotificationBox)';
NotificationBar.displayName = 'NotificationBar';
