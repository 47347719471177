import { pretty } from './pretty';
import { Level, LoggerMethod, LoggerMethods, LoggerTraceFn, LoggerExceptionFn } from './types';
import { LEVELS, extractError } from './util';
import env from '../env';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const configuredLevel = (env('LOG_LEVEL') || 'info').toLowerCase();
const level = (configuredLevel in LEVELS ? configuredLevel : 'info') as Level;
const levelVal = LEVELS[level];

class Logger implements LoggerMethods {
  // Placeholders for AI log functions
  loggerTraceFn: LoggerTraceFn = noop;
  loggerExceptionFn: LoggerExceptionFn = noop;

  fatal = this.createLogFn('fatal');
  error = this.createLogFn('error');
  warn = this.createLogFn('warn');
  info = this.createLogFn('info');
  debug = this.createLogFn('debug');
  trace = this.createLogFn('trace');
  // eslint-disable-next-line no-console
  dev = process.env.NODE_ENV === 'production' ? noop : console.log;

  setLoggerTraceFn(fn: LoggerTraceFn) {
    this.loggerTraceFn = fn;
  }

  setLoggerExceptionFn(fn: LoggerExceptionFn) {
    this.loggerExceptionFn = fn;
  }

  createLogFn(level: Level): LoggerMethod {
    if (levelVal > LEVELS[level]) return noop;
    return (uuid, message, ctx) => {
      const [error, context] = extractError(ctx);

      if (typeof window === 'undefined' && process.env.NODE_ENV === 'production') {
        // It's bad for performance to use the console from Node.js. We do send
        // logs to Application Insights. If something must be sent to the
        // console, in production, use console.* directly.
      } else {
        pretty({ uuid, message, context, error, level });
      }

      // To Application Insights
      if (LEVELS[level] < 50) {
        this.loggerTraceFn({ uuid, message, context, error, level });
      } else {
        this.loggerExceptionFn({ uuid, message, context, error, level });
      }
    };
  }
}

export default new Logger();

export { ErrorBoundary } from './ErrorBoundary';
