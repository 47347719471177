import { FC } from 'react';

import { styled } from '../../../stitches.config';

const Wrapper = styled('div', {
  width: '88px',
  height: '44px',
  position: 'relative',
  svg: {
    width: '88px',
  },
});

export const Logo: FC = () => {
  return (
    <Wrapper>
      <svg viewBox="0 0 88 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Woonenergie.nl</title>
        <path
          d="M13.6619 23.7746L13.3945 22.4375L11.9359 16.4088H10.0155L8.60553 22.4618L8.36244 23.7746H8.38674C7.82763 20.979 7.29282 18.8154 6.87956 16.4088H4.76465C5.46962 20.0309 6.15028 22.9723 7.12266 26.2541H9.28619L10.5017 20.9547L10.7691 19.3989H10.7934L11.1094 20.9547L12.4221 26.2541H14.5856C15.5094 23.0696 16.2387 19.9823 16.9193 16.4088H15.0718C14.6586 18.8884 14.1967 21.2221 13.6619 23.7746Z"
          fill="#C7D416"
        />
        <path
          d="M25.4512 22.7537C25.4512 25.4277 26.6666 26.4244 28.66 26.4244C30.8235 26.4244 32.1849 25.1603 32.1849 22.4377C32.1849 19.7636 30.9694 18.7913 28.976 18.7913C26.8125 18.7913 25.4512 20.0553 25.4512 22.7537ZM30.2887 22.778C30.2887 24.431 29.7296 25.0388 28.8545 25.0388C27.8821 25.0388 27.3716 24.504 27.3716 22.4377C27.3716 20.7846 27.9307 20.1769 28.8059 20.1769C29.7539 20.1769 30.2887 20.7117 30.2887 22.778Z"
          fill="#C7D416"
        />
        <path
          d="M40.2316 26.2542C40.2316 24.6498 40.2803 22.9968 40.2803 22.0973C40.2803 19.715 39.5267 18.7913 38.0438 18.7913C37.193 18.7913 36.4637 19.1559 35.8316 19.715L35.7101 18.9371H34.1543V26.2299H36.0261V20.8576C36.5123 20.4929 36.9985 20.2498 37.3874 20.2498C38.0681 20.2498 38.3841 20.6388 38.3841 22.389C38.3841 23.2399 38.3355 24.7471 38.3355 26.2299H40.2316V26.2542Z"
          fill="#C7D416"
        />
        <path
          d="M43.1729 26.2542C42.1276 31.3834 39.2591 35.8564 35.248 38.9679C35.4668 39.0409 35.6613 39.0895 35.8801 39.1381C39.8425 35.9536 42.6867 31.432 43.7077 26.2542H43.1729Z"
          fill="#C7D416"
        />
        <path
          d="M22.1211 43.4652C10.2824 43.4652 0.655899 33.8387 0.655899 22C0.655899 10.1614 10.2824 0.53485 22.1211 0.53485C26.8371 0.53485 31.1885 2.06634 34.7133 4.64313C34.8835 4.76468 35.078 4.91054 35.2481 5.03208C38.8945 7.85197 41.6172 11.8387 42.857 16.4332H43.4161C42.2006 11.8144 39.5266 7.77904 35.9045 4.86192C35.7343 4.71606 35.5642 4.59451 35.3697 4.44866C31.6747 1.65308 27.1045 4.47005e-05 22.1211 4.47005e-05C9.9907 -0.0242647 0.121094 9.86965 0.121094 22C0.121094 34.1304 9.9907 44 22.1211 44C27.0802 44 31.6747 42.347 35.3697 39.5514C35.1509 39.4785 34.9321 39.4298 34.7376 39.3569C31.1885 41.9337 26.8371 43.4652 22.1211 43.4652Z"
          fill="#C7D416"
        />
        <path
          d="M55.3029 26.2542C55.3029 24.6498 55.3515 22.9968 55.3515 22.0973C55.3515 19.715 54.598 18.7913 53.1151 18.7913C52.2643 18.7913 51.535 19.1559 50.9029 19.715L50.7814 18.9371H49.2256V26.2299H51.0974V20.8576C51.5836 20.4929 52.0698 20.2498 52.4587 20.2498C53.1394 20.2498 53.4554 20.6388 53.4554 22.389C53.4554 23.2399 53.4068 24.7471 53.4068 26.2299H55.3029V26.2542Z"
          fill="#16B5FF"
        />
        <path
          d="M47.5973 17.916V16.4088H43.4161H42.8569H42.5166V26.2541H43.173H43.7078H47.6702V24.7469H44.4857V21.9514H47.0625V20.5171H44.4857V17.916H47.5973Z"
          fill="#16B5FF"
        />
        <path
          d="M66.2915 20.0796L66.1456 18.937H64.5898V26.2298H66.4617V21.5867C67.118 20.7602 67.4583 20.4685 67.8716 20.4685C68.0175 20.4685 68.2119 20.4928 68.5036 20.5658L68.7467 18.9127C68.5036 18.7912 68.2606 18.7426 68.0418 18.7426C67.5313 18.8155 67.0451 19.1072 66.2915 20.0796Z"
          fill="#16B5FF"
        />
        <path
          d="M69.5244 22.7537C69.5244 24.6255 70.4239 26.157 72.1012 26.157C72.8062 26.157 73.4382 25.8167 73.9487 25.4277V26.2785C73.9487 27.5669 73.1222 27.7614 70.2537 27.2752L70.1078 28.3205C71.0802 28.7095 71.931 28.904 72.7089 28.904C74.4349 28.904 75.8205 28.3205 75.8205 25.9625V19.1073C75.1156 18.9371 74.1432 18.7913 73.2924 18.7913C71.1045 18.7913 69.5244 20.0067 69.5244 22.7537ZM73.9244 24.1393C73.5355 24.4797 73.025 24.7471 72.636 24.7471C71.8824 24.7471 71.4205 23.9692 71.4205 22.462C71.4205 20.9305 72.0769 20.1283 73.0007 20.1283C73.3167 20.1283 73.6327 20.1769 73.9001 20.2741V24.1393H73.9244Z"
          fill="#16B5FF"
        />
        <path
          d="M78.6885 16.0442C77.7891 16.0442 77.5703 16.2873 77.5703 17.0409C77.5703 17.7945 77.7891 18.0132 78.6885 18.0132C79.588 18.0132 79.8068 17.7458 79.8068 17.0409C79.8068 16.2873 79.588 16.0442 78.6885 16.0442Z"
          fill="#16B5FF"
        />
        <path d="M79.613 18.9613H77.7412V26.2541H79.613V18.9613Z" fill="#16B5FF" />
        <path
          d="M87.3189 22.9725C87.5134 19.8366 86.2736 18.7913 84.4018 18.7913C82.3355 18.7913 81.0957 20.2012 81.0957 22.8023C81.0957 25.4763 82.3598 26.4244 84.2802 26.4244C85.3498 26.4244 86.3951 26.1084 87.2703 25.6465L87.0029 24.5769C86.152 24.8686 85.2769 24.9901 84.6206 24.9901C83.5753 24.9901 82.9918 24.431 82.9918 23.0697V22.9725H87.3189ZM84.3288 20.0553C85.1067 20.0553 85.52 20.6145 85.52 21.7813V21.8056H83.0161C83.0891 20.7846 83.5266 20.0553 84.3288 20.0553Z"
          fill="#16B5FF"
        />
        <path
          d="M21.6596 18.8156C21.5137 18.7913 21.3436 18.7913 21.1977 18.7913C19.0342 18.7913 17.6729 20.0553 17.6729 22.7537C17.6729 25.4277 18.8883 26.4244 20.906 26.4244C21.2706 26.4244 21.611 26.3758 21.927 26.3029C22.0972 26.2542 22.2673 26.2056 22.4375 26.157C23.7016 25.6465 24.4309 24.4553 24.4309 22.4377C24.4309 20.2498 23.6043 19.2045 22.2187 18.8885C22.0242 18.8642 21.8298 18.8399 21.6596 18.8156ZM21.0519 25.0388C20.0795 25.0388 19.569 24.504 19.569 22.4377C19.569 20.7846 20.1281 20.1769 21.0032 20.1769C21.9513 20.1769 22.4861 20.7117 22.4861 22.778C22.4861 24.431 21.9027 25.0388 21.0519 25.0388Z"
          fill="#C7D416"
        />
        <path
          d="M34.713 4.61877C28.4169 6.66076 23.4821 12.3005 22.2666 18.9127C22.4368 18.9613 22.6069 19.0099 22.7771 19.0828C23.9683 12.495 28.9517 6.90385 35.2478 5.00772C35.0776 4.88618 34.9075 4.74032 34.713 4.61877Z"
          fill="#16B5FF"
        />
        <path
          d="M63.0091 22.9724C63.2035 19.8365 61.9638 18.7911 60.0919 18.7911C59.4356 18.7911 58.8765 18.937 58.3903 19.2044C57.0533 10.453 49.4688 3.74365 40.3527 3.74365C38.6268 3.74365 36.9494 3.98674 35.345 4.44862C35.5152 4.59448 35.7096 4.71603 35.8798 4.86188C37.3141 4.49724 38.7969 4.27846 40.3527 4.27846C49.2986 4.27846 56.7129 10.9392 57.9041 19.569C57.1748 20.2254 56.7859 21.3193 56.7859 22.8022C56.7859 24.1635 57.1262 25.0873 57.7096 25.6464C56.008 33.6685 48.8853 39.7215 40.377 39.7215C38.8212 39.7215 37.3384 39.527 35.9041 39.1381C35.6853 39.0895 35.4909 39.0165 35.2721 38.9679C29.292 37.169 24.4544 31.9668 22.9715 25.8652C22.8257 25.9624 22.6555 26.0596 22.4854 26.1326C24.0168 32.2342 28.8058 37.4364 34.7373 39.3569C34.9561 39.4298 35.1505 39.4784 35.3693 39.5513C36.9494 40.0132 38.6268 40.2563 40.3527 40.2563C49.0555 40.2563 56.324 34.1547 58.1715 26.011C58.6577 26.3027 59.2654 26.4243 59.9704 26.4243C61.04 26.4243 62.0853 26.1083 62.9604 25.6464L62.693 24.5768C61.8422 24.8685 60.9671 24.99 60.3107 24.99C59.2654 24.99 58.682 24.4309 58.682 23.0696V22.9724H63.0091ZM60.019 20.0552C60.7969 20.0552 61.2102 20.6144 61.2102 21.7812V21.8055H58.7063C58.7792 20.7845 59.2168 20.0552 60.019 20.0552Z"
          fill="#16B5FF"
        />
      </svg>
    </Wrapper>
  );
};
