import React, { FC, useRef } from 'react';

import { StickyNavLinks } from './types';
import { InputSelect } from '../InputSelect/InputSelect';

interface Props {
  links: StickyNavLinks;
}

export const StickyNavMobile: FC<Props> = ({ links }) => {
  const inputSelectRef = useRef<HTMLSelectElement>(null);

  const onInputChange = function (event: React.ChangeEvent<HTMLSelectElement>) {
    window.location.href = event.target.value;
  };

  return (
    <InputSelect
      isOptional={false}
      label=""
      name="select"
      onChange={onInputChange}
      options={links?.map(link => ({ label: link.text, value: link.href })) ?? []}
      placeholder=""
      ref={inputSelectRef}
      value={links?.find(link => link.active)?.href ?? ''}
    />
  );
};

StickyNavMobile.displayName = 'StickyNavMobile';
