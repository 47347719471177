import React from 'react';

import ReactIs from 'react-is';

import { css, styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';

const styledCss = css({
  variants: {
    width: {
      true: { width: '100%' },
      false: { width: 'fit-content' },
      auto: { width: 'auto' },
    },
    height: {
      true: { height: '100%' },
      false: { height: 'fit-content' },
      auto: { height: 'auto' },
    },
  },
  defaultVariants: {
    width: true,
    height: true,
  },
});

const StyledStretch = styled('div', styledCss);

type Props = TransformStitchesToSparky<typeof StyledStretch>;

export const Stretch: React.FC<React.PropsWithChildren<Props>> = ({ children, width, height }) => {
  const variantProps = extractVariantProps({ width, height });
  const stretchProps = styledCss(variantProps);

  const arrayChildren = React.Children.toArray(children);
  const stretchedChildren = React.Children.map(arrayChildren, child => {
    if (ReactIs.isElement(child)) {
      return React.cloneElement(child, { className: stretchProps.className });
    }

    return <StyledStretch {...variantProps}>{child}</StyledStretch>;
  });

  /**
   * Because of a TS issue (https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356), we need to wrap the children in a fragment.
   * ESlint doesn't like this. But we do.
   */

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{stretchedChildren}</>;
};

StyledStretch.displayName = 'styled(Stretch)';
