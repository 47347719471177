import { MessageFormatter, pluralTypeHandler } from '@ultraq/icu-message-formatter';

import type { Locale } from '@common/application';

import { Address } from './types';

const dateFormats: Record<string, Intl.DateTimeFormatOptions> = {
  dayOfMonthNumeric: {
    day: 'numeric',
  },
  dayOfWeekShort: {
    weekday: 'short',
  },
  dayOfWeekLong: {
    weekday: 'long',
  },
  long: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  medium: {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  },
  hourShort: {
    hour: '2-digit',
  },
  hourLong: {
    hour: '2-digit',
    minute: '2-digit',
  },
  short: {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  },
  monthLong: {
    month: 'long',
  },
  monthShort: {
    month: 'short',
  },
  year: {
    year: 'numeric',
  },
  period: {
    month: 'long',
    year: 'numeric',
  },
};

const numberFormats: Record<string, Intl.NumberFormatOptions> = {
  '::currency/EUR': {
    style: 'currency',
    currency: 'EUR',
  },
  euroNoFractionDigits: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  euroHighPrecision: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 7,
  },
  euroThreeDecimals: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  },
  euroFiveDecimals: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 5,
    maximumFractionDigits: 5,
  },
  noFractionDigits: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  oneFractionDigit: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  twoFractionDigits: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  threeFractionDigits: {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  },
  fourFractionDigits: {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  },
};

const listFormats: Record<string, Intl.ListFormatOptions> = {
  and: { style: 'long', type: 'conjunction' },
  or: { style: 'long', type: 'disjunction' },
};

const unitFormats: Record<string, string> = {
  gas: 'm³',
  electricity: 'kWh',
  redelivery: 'kWh',
  warmth: 'GJ',
  produced: 'kWh',
  tapWater: 'm³',
  cooling: 'GJ',
};

type AddressFormats = 'medium' | 'long' | 'streetAddress' | 'postalCodeCity';

/*Based on AddressFinder*/
const emptySuffixString = '-';

class CachedFormatter extends MessageFormatter {
  date: Record<string, Intl.DateTimeFormat>;
  number: Record<string, Intl.NumberFormat>;
  list: Record<string, Intl.ListFormat>;
  unit: Record<number, keyof typeof unitFormats>;
  address: Record<string, AddressFormats>;

  constructor(locale: string, typeHandlers: FormatHandlers) {
    super(locale, typeHandlers);
    this.date = {};
    this.number = {};
    this.list = {};
    this.unit = {};
    this.address = {};
  }
}

const formatters: Partial<Record<Locale, CachedFormatter>> = {};

export const getFormatter = (locale: Locale): CachedFormatter => {
  // @ts-ignore TS should infer this can't be undefined
  if (locale in formatters) return formatters[locale];

  // @ts-ignore TypeScript does not like self-referencing in initializer
  const formatter = new CachedFormatter(locale, {
    plural: pluralTypeHandler,
    date: (value: string, config: keyof typeof dateFormats, locale: Locale) => {
      if (!formatter.date[config]) {
        formatter.date[config] = new Intl.DateTimeFormat(locale, dateFormats[config]);
      }
      return formatter.date[config].format(new Date(value));
    },
    number: (value: string, config: keyof typeof numberFormats, locale: Locale) => {
      if (!formatter.number[config]) {
        formatter.number[config] = new Intl.NumberFormat(locale, numberFormats[config]);
      }
      return formatter.number[config].format(Number(value));
    },
    list: (value: string[], config: keyof typeof listFormats = 'and', locale: Locale) => {
      const options = config ? listFormats[config] : listFormats.and;
      if ('ListFormat' in Intl) {
        if (!formatter.list[config]) {
          formatter.list[config] = new Intl.ListFormat(locale, options);
        }
        return formatter.list[config].format(value);
      }
      return value.join(', ');
    },
    unit: (value: number, config: keyof typeof unitFormats, locale: Locale) => {
      const noFractionDigits = new Intl.NumberFormat(locale, numberFormats.noFractionDigits);
      const twoFractionDigits = new Intl.NumberFormat(locale, numberFormats.twoFractionDigits);

      const formattedValue =
        value !== 0 && Number.isInteger(value) ? noFractionDigits.format(value) : twoFractionDigits.format(value);

      const unit = unitFormats[config];

      return `${formattedValue} ${unit}`;
    },
    address: (
      { street, houseNumber, houseNumberSuffix, city, postalCode }: Address = {},
      type: AddressFormats,
      locale: Locale,
    ) => {
      const validatedSuffix =
        houseNumberSuffix && houseNumberSuffix !== emptySuffixString ? houseNumberSuffix.toUpperCase() : '';
      switch (type) {
        case 'long': {
          if (!street || !houseNumber || !city || !postalCode) return '';
          return `${street} ${houseNumber}${validatedSuffix}, ${postalCode} ${city}`;
        }
        case 'medium':
          if (!street || !houseNumber || !city) return '';
          return `${street} ${houseNumber}${validatedSuffix}, ${city}`;
        case 'streetAddress':
          if (!street || !houseNumber) return '';
          return `${street} ${houseNumber}${validatedSuffix}`;
        case 'postalCodeCity':
          if (!city || !postalCode) return '';
          return `${postalCode} ${city}`;
        default:
          return '';
      }
    },
  });

  formatters[locale] = formatter;
  return formatter;
};
