// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgBoilerIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M17 3H7c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1Zm-4.69 12.89A.51.51 0 0 1 12 16a.51.51 0 0 1-.31-.11C11.578 15.799 9 13.69 9 11c0-2.69 2.578-4.799 2.69-4.89.09-.07.2-.11.31-.11.11 0 .22.04.31.11.112.091 2.69 2.2 2.69 4.89 0 2.69-2.578 4.799-2.69 4.89ZM9.01 22c-.55 0-1-.45-1-1v-1h2v1c0 .55-.45 1-1 1Zm6 0c-.55 0-1-.45-1-1v-1h2v1c0 .55-.45 1-1 1ZM12 15s-1-1.12-1-2.5 1-2.5 1-2.5 1 1.12 1 2.5-1 2.5-1 2.5Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgBoilerIcon;
SvgBoilerIcon.displayName = 'SvgBoilerIcon';
