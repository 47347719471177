// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHeatPumpIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M7 3h10c.55 0 1 .45 1 1v14c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1Zm9.3 5.41a.571.571 0 0 0-.64-.47l.01-.01-3.19.46c-.3.05-.51.33-.47.64.04.28.28.48.55.48h.08l3.19-.46c.3-.05.51-.33.47-.64ZM11.19 6.3l-1.2 2.99a.556.556 0 0 0 .52.77c.22 0 .43-.13.52-.35l1.2-2.99a.556.556 0 0 0-.31-.73.556.556 0 0 0-.73.31ZM8.42 8.66a.568.568 0 0 0-.79-.09c-.24.19-.28.55-.09.79l1.99 2.53a.57.57 0 0 0 .79.09c.24-.19.28-.55.09-.79L8.42 8.66Zm-.14 5.39h.09l3.19-.46c.3-.05.51-.33.47-.64a.571.571 0 0 0-.64-.48l-3.19.46c-.3.05-.51.33-.47.64.04.28.28.48.55.48Zm4.55 1.64 1.2-2.99a.556.556 0 0 0-.31-.73.556.556 0 0 0-.73.31l-1.2 2.99a.556.556 0 0 0 .52.77c.22 0 .43-.13.52-.35Zm3.21-2.15c.12 0 .25-.04.35-.12.24-.19.28-.55.09-.79l-1.99-2.53a.568.568 0 0 0-.79-.09c-.24.19-.28.55-.09.79l1.99 2.53c.11.14.27.21.44.21ZM8.01 21c0 .55.45 1 1 1s1-.45 1-1v-1h-2v1Zm6 0c0 .55.45 1 1 1s1-.45 1-1v-1h-2v1Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgHeatPumpIcon;
SvgHeatPumpIcon.displayName = 'SvgHeatPumpIcon';
