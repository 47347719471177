/**
 * Adaptation of the original Placeholder component:
 *
 * - https://github.com/Sitecore/jss/blob/v21.0.1/packages/sitecore-jss-react/src/components/Placeholder.tsx
 * - https://github.com/Sitecore/jss/blob/v21.0.1/packages/sitecore-jss-react/src/components/PlaceholderCommon.tsx
 * - https://docs.acc.eneco.nl/docs/decisions/sitecore-jss/#jss-react--nextjs-sdks
 */

import { FC, createElement, createContext, useContext, useMemo } from 'react';

import type { HtmlElementRendering } from '@sitecore-jss/sitecore-jss-nextjs';

import { delve } from '@common/delve';
import logger from '@common/log';
import { NbaFeedbackWrapperComponent } from '@next-best-action';
import type {
  ComponentRendering,
  PlaceholderData,
  SitecoreComponent,
  ComponentProps,
  PlaceholderProps,
  UseContent,
} from '@sitecore/types/lib';
import { LayoutServiceData, RouteData } from '@sitecore/types/lib';
import { TrackingProvider } from '@tracking';

import { useComponentFactory, useLayoutData } from './SitecoreContext';
import type { ComponentFactory } from './SitecoreContext';
import WithErrorLogging from './WithErrorLogging';
import { usePlaceholder } from '..';
import { HiddenRendering } from '../components/HiddenRendering';

const RenderingContext = createContext<ComponentRendering | undefined>(undefined);

const getContentGetter =
  <T extends ComponentRendering>(rendering: T) =>
  (path: string) => {
    const value = delve(rendering.fields ?? {}, path);
    if (value) return value;
    logger.error('D6jsNU', 'Content not found in rendering', { path, rendering });
    return `[${path}]`;
  };

export const useContent = <T extends ComponentRendering>(
  ancestorRendering?: RouteData | ComponentRendering,
): UseContent<T, T['fields']> => {
  const currentRendering = useContext(RenderingContext);
  const rendering = (currentRendering ?? ancestorRendering) as T;
  const f = useMemo(() => getContentGetter<T>(rendering), [rendering]);
  return { f, ...rendering };
};

export const RenderingProvider: FC<React.PropsWithChildren<{ rendering: ComponentRendering }>> = ({
  children,
  rendering,
}) => <RenderingContext.Provider value={rendering}>{children}</RenderingContext.Provider>;

const getPlaceholderDataFromRendering = (name: string, rendering: RouteData | ComponentRendering): PlaceholderData => {
  if (rendering?.placeholders && name in rendering.placeholders) {
    return rendering.placeholders[name] as PlaceholderData;
  } else {
    return [];
  }
};

// This should probably exclude the TrackingProvider for the EE as well, but for now it's just the NBAFeedbackWrapperComponent
const addComponentTracking = (component: SitecoreComponent, context: LayoutServiceData['sitecore']['context']) => {
  // If Sitecore is in edit or preview mode, don't wrap the component with tracking
  if (context.pageState !== 'normal') return component;

  const { params: { nba_feedback = '' } = {} } = component.props;
  return <NbaFeedbackWrapperComponent nbaFeedback={String(nba_feedback)}>{component}</NbaFeedbackWrapperComponent>;
};

export const wrapComponent = (component: SitecoreComponent, context: LayoutServiceData['sitecore']['context']) => {
  const { componentName } = component.props;

  // Don't wrap HTML element renderings
  if (!componentName) return component;

  return (
    <TrackingProvider key={component.key} scope={componentName}>
      <RenderingProvider rendering={component.props}>
        <WithErrorLogging componentName={componentName}>{addComponentTracking(component, context)}</WithErrorLogging>
      </RenderingProvider>
    </TrackingProvider>
  );
};

const isSitecoreExperienceEditorRendering = (
  rendering: HtmlElementRendering | ComponentRendering,
): rendering is HtmlElementRendering => {
  return !(rendering as ComponentRendering).componentName && (rendering as HtmlElementRendering).name !== undefined;
};

export const isSitecoreComponent = (component: unknown): component is SitecoreComponent => !!component;

export const getComponentsForRendering = (
  placeholderData: PlaceholderData,
  componentFactory: ComponentFactory,
  context: LayoutServiceData['sitecore']['context'],
) => {
  return (placeholderData ?? [])
    .map((rendering, index) => {
      const key = (rendering as ComponentRendering).uid ?? `component-${index}`;

      // HTML element rendering for Experience Editor
      if (isSitecoreExperienceEditorRendering(rendering)) {
        const { attributes } = rendering;
        if ((rendering as HtmlElementRendering).name === 'div') {
          return HiddenRendering();
        }
        const props = attributes;
        if (!props.className && attributes.class) props.className = attributes.class;
        if (attributes.chrometype === 'placeholder') {
          props.phkey = attributes.key;
        }
        delete props.class;

        return createElement(rendering.name, {
          // Explicitly NOT setting `key` here (breaks (dynamic) placeholders inside the EE)
          ...props,
          // Explicitly not parsing or sanitizing this HTML (extra metadata for editor)
          dangerouslySetInnerHTML: {
            __html: rendering.contents,
          },
        });
      }

      if (rendering.datasourceRequired && !rendering.fields) {
        return null;
      }

      const component = componentFactory(rendering.componentName);
      if (!component) {
        logger.error('Lwaar8', 'Component not found by factory', rendering);
        return null;
      }
      const props: ComponentProps = { ...rendering, context };
      return createElement(component, { key, ...props });
    })
    .filter(isSitecoreComponent)
    .map(component => wrapComponent(component, context));
};

export const usePlaceholderComponentsForRendering = (name: string) => {
  const componentFactory = useComponentFactory();
  const { context, route } = useLayoutData();
  const ensuredRendering = useContent(route);
  const { getScope, getScopedComponents } = usePlaceholder();
  const placeholderData = getPlaceholderDataFromRendering(name, ensuredRendering);
  const scopedComponentData = getScope(name) ? getScopedComponents(name) : null;
  const componentData = scopedComponentData || placeholderData;
  const components = getComponentsForRendering(componentData, componentFactory, context);
  return components;
};

export const Placeholder: FC<PlaceholderProps> = ({ name }) => {
  const components = usePlaceholderComponentsForRendering(name);
  return <>{components}</>;
};
