import React, { FC, useMemo, SyntheticEvent, useState, Fragment, useRef, useEffect } from 'react';

import { useApplication } from '@common/application/Provider';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Unstyled } from '@custom-components/Unstyled';

import { getEventToDataLayer, pushToDataLayer } from './dataLayer';
import { useStatefulTimer } from './hooks/useStatefulTimer';
import { useTracking } from './hooks/useTracking';
import { TrackingContext } from './TrackingProvider.context';
import { DataLayerEvent, ValidationTrackingEvent } from './types';

export const TrackingProvider: FC<
  React.PropsWithChildren<{ initialEvents?: Array<DataLayerEvent>; scope: string }>
> = ({ children, initialEvents, scope }) => {
  const { isEditMode } = useApplication();
  const statefulTimer = useStatefulTimer();
  const { trackValidationError } = useTracking(scope);

  const ref = useRef<HTMLDivElement>(null);

  const [trackingVariant, setTrackingVariant] = useState<string | undefined>();

  const handlers = useMemo(
    () => ({
      click: (event: SyntheticEvent) => {
        const dataLayerEvent = getEventToDataLayer(event, scope, trackingVariant);
        if (dataLayerEvent) pushToDataLayer(dataLayerEvent);
      },
      focus: (event: SyntheticEvent) => {
        const dataLayerEvent = getEventToDataLayer(event, scope);
        if (!!dataLayerEvent && dataLayerEvent.event === 'fill_field') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldName = (dataLayerEvent as any).field_name as string;
          statefulTimer.setStart(`${scope}.${fieldName}`);
        }
      },
      blur: (event: SyntheticEvent) => {
        const dataLayerEvent = getEventToDataLayer(event, scope);
        if (dataLayerEvent && dataLayerEvent.event === 'fill_field') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldName = (dataLayerEvent as any).field_name as string;
          const seconds = statefulTimer.getSecondsSinceFieldStart(`${scope}.${fieldName}`);
          pushToDataLayer({
            event: 'fill_field',
            component_type: scope,
            field_name: fieldName,
            field_action: 'finish',
            field_focustime: seconds,
          });
        }
      },
    }),
    [scope, trackingVariant],
  );

  const validationErrorHandler = (event: ValidationTrackingEvent) => {
    const { name, error } = event.detail;
    if (event._tracked || !name || !error) return;

    trackValidationError({
      [name]: {
        message: error,
      },
    });

    event._tracked = true;
  };

  useEffect(() => {
    const trackingProvider = ref.current;

    if (trackingProvider) {
      trackingProvider.addEventListener('validationError', validationErrorHandler as EventListener);
      return () => trackingProvider.removeEventListener('validationError', validationErrorHandler as EventListener);
    }
  }, []);

  useEffect(() => {
    if (!initialEvents) return;
    initialEvents?.forEach(pushToDataLayer);
  }, [initialEvents]);

  if (isEditMode) return <Fragment>{children}</Fragment>;

  return (
    <TrackingContext.Provider value={{ scope, setTrackingVariant }}>
      <Unstyled data-scope={scope} onBlur={handlers.blur} onClick={handlers.click} onFocus={handlers.focus} ref={ref}>
        {children}
      </Unstyled>
    </TrackingContext.Provider>
  );
};
