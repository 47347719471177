import { FC, ReactNode } from 'react';

import { useI18nTranslation } from '../../providers/i18n';
import { Text } from '../Text/Text';

interface Props {
  htmlFor: string;
  children: ReactNode;
  isOptional?: boolean;
}

/** Internal use only (InputText, InputTelephone, etc.) */
export const Label: FC<React.PropsWithChildren<Props>> = ({ htmlFor, children, isOptional }) => {
  const i18nOptionalText = useI18nTranslation('optional');

  return (
    <label htmlFor={htmlFor}>
      <Text size="BodyS" weight="bold">
        {children}
        {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
      </Text>
    </label>
  );
};
