import React, { FormHTMLAttributes, forwardRef } from 'react';

/**
 * Form component has `noValidate` applied on the form element by default because it's expected that we use javascript
 * to validate our forms on the client side rather than native browser validation.
 */
interface Props extends Omit<FormHTMLAttributes<HTMLFormElement>, 'noValidate'> {
  children: React.ReactNode;
}

export const Form = forwardRef<HTMLFormElement, Props>(({ children, ...rest }, ref) => {
  return (
    <form noValidate ref={ref} {...rest}>
      {children}
    </form>
  );
});

Form.displayName = 'Form';
