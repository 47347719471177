import React, { AnchorHTMLAttributes, FC, useId } from 'react';

import { ConditionalWrapper } from '../../internal/components/ConditionalWrapper';
import { styled } from '../../stitches.config';
import { Box } from '../Box/Box';
import { Heading } from '../Heading/Heading';
import { LinkOverlay } from '../LinkOverlay/LinkOverlay';
import { PageGrid } from '../PageGrid/PageGrid';
import { Text } from '../Text/Text';

export const MessageBarStyled = styled('div', {
  paddingY: '$4',

  [`&, & ${Heading}`]: {
    color: '$messagebarText',
  },
  [`& ${Heading}`]: { typography: '$headingXs' },
  [`& ${Text}`]: { typography: '$bodyS' },

  variants: {
    wrapped: {
      false: {
        backgroundColor: '$messagebarBackground',
      },
    },
  },
});

export const LinkOverlayStyled = styled(LinkOverlay, {
  backgroundColor: '$messagebarBackground',

  '&:hover': {
    backgroundColor: '$messagebarHoverBackground',
  },

  [`&:has(.overlay-link:active) ${PageGrid}`]: {
    marginBottom: '-2px',
    marginTop: '2px',
  },
});

type Props = {
  headingLevel?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  text: React.ReactNode;
  title?: string;
};

type WrapperProps = {
  contentId: string;
} & Pick<Props, 'href' | 'target'>;

const LinkOverlayWrapper: FC<React.PropsWithChildren<WrapperProps>> = ({ children, contentId, href, target }) => (
  <LinkOverlayStyled>
    <LinkOverlay.Link aria-labelledby={contentId} href={href} {...(target ? { target } : null)}>
      {}
    </LinkOverlay.Link>
    {children}
  </LinkOverlayStyled>
);
export const MessageBar: FC<React.PropsWithChildren<Props>> = ({ href, headingLevel = 'h4', target, title, text }) => {
  const contentId = useId();

  const wrapperProps: WrapperProps = {
    contentId,
    href,
    target: target,
  };

  return (
    <ConditionalWrapper shouldWrap={!!href} wrapper={LinkOverlayWrapper} {...wrapperProps}>
      <MessageBarStyled id={contentId} wrapped={!!href}>
        <PageGrid>
          <PageGrid.Item gridColumn="1/-1">
            {title && <Heading as={headingLevel}>{title}</Heading>}
            <Box paddingTop="1">{typeof text === 'string' ? <Text>{text}</Text> : <div>{text}</div>}</Box>
          </PageGrid.Item>
        </PageGrid>
      </MessageBarStyled>
    </ConditionalWrapper>
  );
};

MessageBarStyled.displayName = 'styled(MessageBar)';
MessageBar.displayName = 'MessageBar';
