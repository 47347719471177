// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgBoostChargingIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="m19.03 7.39.68-.68A.996.996 0 1 0 18.3 5.3l-.68.68a8.951 8.951 0 0 0-3.66-1.75c.02-.07.04-.14.04-.22v-1c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v1c0 .08.03.15.04.22-1.36.3-2.6.91-3.66 1.75L5.7 5.3a.996.996 0 1 0-1.41 1.41l.68.68A8.963 8.963 0 0 0 3 13c0 4.96 4.04 9 9 9s9-4.04 9-9c0-2.12-.74-4.07-1.97-5.61ZM12 20c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7Zm6-7c0 1.6-.62 3.11-1.76 4.24-.05.05-.11.07-.17.11-.17.11-.35.18-.54.18-.26 0-.51-.1-.71-.29l-3.54-3.54a.996.996 0 1 1 1.41-1.41l1.99 1.99a2.98 2.98 0 0 0 .24-1.89c0-.04-.02-.07-.03-.11a2.96 2.96 0 0 0-.14-.45c-.01-.02-.03-.05-.04-.07-.07-.15-.15-.3-.24-.44 0-.01-.02-.02-.03-.03A2.985 2.985 0 0 0 11.98 10c-.28 0-.5-.22-.5-.5s.22-.5.5-.5c1.1 0 2.09.45 2.82 1.17.35.35.62.76.82 1.2.01.03.03.05.04.08.08.2.14.41.19.62.01.05.03.09.04.14.05.26.08.53.08.8 0 .72-.21 1.41-.56 2.02l.74.74c.54-.81.83-1.76.83-2.76 0-.34-.04-.68-.1-1-.01-.06-.04-.12-.06-.19-.06-.26-.13-.51-.23-.75a1.09 1.09 0 0 0-.06-.11c-.12-.26-.24-.51-.4-.74l-.01-.01a5.002 5.002 0 0 0-4.13-2.19c-.28 0-.5-.22-.5-.5s.22-.5.5-.5c1.65 0 3.15.67 4.23 1.75h.01c.84.84 1.4 1.88 1.63 3.02v.02c.03.16.06.33.08.5 0 .03.01.06.01.1.02.2.03.4.03.61L18 13Z" />
  </Icon>
);
export default SvgBoostChargingIcon;
SvgBoostChargingIcon.displayName = 'SvgBoostChargingIcon';
