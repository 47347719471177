import type { Locale } from "@common/application";
import type { Dictionary } from "@i18n";

const overloadTranslations: Record<Locale, Dictionary> = {
  'nl-NL': {
    title: 'Server Overbelast',
    error: 'De server is momenteel overbelast. Probeer het later opnieuw.',
  },
  'en-NL': {
    title: 'Server Overloaded',
    error: 'Server is currently overloaded. Please try again later.',
  },
  'nl-BE': {
    title: 'Server Overbelast',
    error: 'De server is momenteel overbelast. Probeer het later opnieuw.',
  },
  'fr-BE': {
    title: 'Serveur Surchargé',
    error: 'Le serveur est actuellement surchargé. Veuillez réessayer plus tard.',
  },
  'en-GB': {
    title: 'Server Overloaded',
    error: 'Server is currently overloaded. Please try again later.',
  },
} as const;

export const getOverloadDictionary = (locale: Locale) => Promise.resolve(overloadTranslations[locale]);