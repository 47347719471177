// Borrowed from https://stackoverflow.com/a/58084026
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const once = <A extends any[], R, T>(fn: (this: T, ...arg: A) => R): ((this: T, ...arg: A) => R | undefined) => {
  let done = false;
  return function (this: T, ...args: A) {
    if (done) {
      return void 0;
    } else {
      done = true;
      return fn.apply(this, args);
    }
  };
};
