import env from '../env';
import logger from '../log';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const client = typeof window !== 'undefined' ? require('./browser') : require('./node');

// connect application insights to logger
logger.setLoggerTraceFn(client.trackTrace);
logger.setLoggerExceptionFn(client.trackException);

// Logging this here so we can pretty-print without a circular dependency
if (process.env.NODE_ENV === 'production') {
  logger.info('fs4b0O', `Build number: ${env('BUILDNUMBER')}`);
} else if (process.env.NODE_ENV !== 'test') {
  logger.info('cjmHnY', 'Environment variables', env());
}

export default client.getClient();
