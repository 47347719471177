// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgFacebookIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M16.63 11.99h-2.705v9.974H9.911V11.99H8V8.466h1.909v-2.28c0-1.631.75-4.186 4.05-4.186l2.974.011v3.423h-2.157c-.354 0-.853.183-.853.96v2.072h3.06l-.353 3.524Z"
    />
  </Icon>
);
export default SvgFacebookIcon;
SvgFacebookIcon.displayName = 'SvgFacebookIcon';
