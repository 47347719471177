import { useCallback, useEffect, useState } from 'react';

const DEFAULT_COOLDOWN_DURATION = 60_000; // 1 minute in milliseconds
const OVERLOAD_KEY = 'overload_timestamp';
const COOLDOWN_DURATION_KEY = 'cooldown_duration';

export const useOverloadNotification = () => {
  const [isOverloaded, setIsOverloaded] = useState(false);

  const checkOverloadStatus = useCallback(() => {
    const overloadTimestamp = localStorage.getItem(OVERLOAD_KEY);
    const cooldownDuration = Number(localStorage.getItem(COOLDOWN_DURATION_KEY)) || DEFAULT_COOLDOWN_DURATION;
    if (overloadTimestamp) {
      const timeSinceOverload = Date.now() - parseInt(overloadTimestamp, 10);
      if (timeSinceOverload < cooldownDuration) {
        setIsOverloaded(true);
        setTimeout(() => {
          setIsOverloaded(false);
          localStorage.removeItem(OVERLOAD_KEY);
          localStorage.removeItem(COOLDOWN_DURATION_KEY);
        }, cooldownDuration - timeSinceOverload);
      } else {
        localStorage.removeItem(OVERLOAD_KEY);
        localStorage.removeItem(COOLDOWN_DURATION_KEY);
      }
    }
  }, []);

  useEffect(() => {
    checkOverloadStatus(); // Check status immediately on mount
    window.addEventListener('storage', checkOverloadStatus);
    return () => window.removeEventListener('storage', checkOverloadStatus);
  }, [checkOverloadStatus]);

  const showOverloadNotification = useCallback((retryAfter?: number) => {
    const cooldownDuration = retryAfter ? retryAfter * 1000 : DEFAULT_COOLDOWN_DURATION;
    localStorage.setItem(OVERLOAD_KEY, Date.now().toString());
    localStorage.setItem(COOLDOWN_DURATION_KEY, cooldownDuration.toString());
    checkOverloadStatus(); // This will set isOverloaded to true and schedule the timeout
  }, [checkOverloadStatus]);

  const hideOverloadNotification = useCallback(() => {
    setIsOverloaded(false);
    localStorage.removeItem(OVERLOAD_KEY);
    localStorage.removeItem(COOLDOWN_DURATION_KEY);
  }, []);

  const isInCooldown = useCallback(() => {
    const overloadTimestamp = localStorage.getItem(OVERLOAD_KEY);
    const cooldownDuration = Number(localStorage.getItem(COOLDOWN_DURATION_KEY)) || DEFAULT_COOLDOWN_DURATION;
    if (overloadTimestamp) {
      return Date.now() - parseInt(overloadTimestamp, 10) < cooldownDuration;
    }
    return false;
  }, []);

  return {
    isOverloaded,
    showOverloadNotification,
    hideOverloadNotification,
    isInCooldown,
  };
};