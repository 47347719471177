import { FC, ReactNode } from 'react';

import { Text } from '../Text/Text';

interface Props {
  // The id prop is less strict than in Input* components to provide a better DX and
  // make the code more readable.
  id?: string;
  children: ReactNode;
}

/** Internal use only (InputText, InputTelephone, etc.) */
export const Hint: FC<React.PropsWithChildren<Props>> = ({ id, children }) => {
  return (
    <Text size="BodyS" color="textPrimary" id={id}>
      {children}
    </Text>
  );
};
