import React, { forwardRef, InputHTMLAttributes, useImperativeHandle, useState } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import {
  DateRange,
  DayPickerBase,
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
  DaySelectionMode,
} from 'react-day-picker';

import {
  ButtonIconContainer,
  CalendarContainer,
  InputWrapper,
  StyledInput,
  DatepickerWrapper,
} from './InputDatepicker.styles';
import { useMediaQuery } from '../../hooks';
import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { CalendarIcon, CloseIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { Box } from '../Box/Box';
import { Datepicker } from '../Datepicker/Datepicker';
import { StyledContent, StyledOverlay, StyledPageGrid } from '../Dialog/Dialog';
import { IconButton } from '../IconButton/IconButton';
import { InputBaseProps } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

export const InputDatepicker = forwardRef<HTMLInputElement, Props>(
  (
    {
      mode = 'single',
      name,
      label,
      placeholder,
      hint,
      error,
      isOptional,
      isDisabled,
      isReadOnly,
      defaultValue,
      value,
      defaultMonth,
      fromDate,
      toDate,
      toYear,
      onChange,
      onBlur,
      onFocus,
      onDaySelect,
      ...props
    },
    ref,
  ) => {
    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });
    const {
      optional: i18nOptionalText,
      openCalendar: i18nOpenCalendar,
      closeCalendar: i18nCloseCalendar,
    } = useI18nTranslations();
    const isMediumBreakpoint = useMediaQuery('md');
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
    const [selected, setSelected] = useState<Date[] | Date | DateRange | undefined>(
      defaultValue || value ? parse(`${defaultValue ?? value}`, 'dd-MM-y', new Date()) : undefined,
    );
    const [inputValue, setInputValue] = useState(defaultValue ?? value ?? '');

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);
    useValidationErrorEvent({ error, name }, inputRef);

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
      setInputValue(event.target.value);
      onChange?.(event);
    };

    const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = event => {
      const {
        currentTarget: { value },
      } = event;
      const parsableDate = value.match(/\d{1,2}-\d{1,2}-\d{4}/g);

      if (!parsableDate) {
        onBlur?.(event);
        return;
      }

      let selectedDates;
      let dateString = '';

      switch (mode) {
        case 'multiple':
          if (Array.isArray(parsableDate)) {
            selectedDates = parsableDate.map(date => parse(date, 'dd-MM-y', new Date())).filter(date => isValid(date));
            dateString = selectedDates.map(date => format(date, 'dd-MM-y')).join(', ');
            setSelected(selectedDates);
          }
          break;
        case 'range':
          if (Array.isArray(parsableDate) && parsableDate.length <= 2) {
            const datesSelected = parsableDate
              .map(date => parse(date, 'dd-MM-y', new Date()))
              .filter(date => isValid(date));

            selectedDates = { from: datesSelected?.[0], to: datesSelected?.[1] };
            dateString = `${format(datesSelected?.[0], 'dd-MM-y')} / ${format(datesSelected?.[1], 'dd-MM-y')}`;
          }
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const selectedDate = parse(parsableDate[0], 'dd-MM-y', new Date());

          selectedDates = isValid(selectedDate) ? selectedDate : undefined;
          dateString = format(selectedDate, 'dd-MM-y');
          break;
      }

      setInputValue(dateString);
      setSelected(selectedDates);

      event.target.value = dateString;

      // Fire change event with new formatted value
      onChange?.({
        target: event.target,
        type: 'change',
      });
      onBlur?.(event);
    };

    const handleInputFocus: React.FocusEventHandler<HTMLInputElement> = event => {
      setIsDatepickerOpen(true);

      onFocus?.(event);
    };

    const handleDaySelect = (selectedDate?: Date | Date[] | DateRange) => {
      setSelected(selectedDate);
      onDaySelect?.(selectedDate);

      if (!selectedDate) {
        setInputValue('');
        return;
      }

      let value;

      switch (mode) {
        case 'multiple':
          value = (selectedDate as Date[]).map(date => format(date, 'dd-MM-y')).join(', ');

          break;
        case 'range':
          // eslint-disable-next-line no-case-declarations
          const { from, to } = selectedDate as DateRange;

          if (!from) {
            return;
          }

          value = `${format(from, 'dd-MM-y')} / ${to ? format(to, 'dd-MM-y') : ''}`;

          break;
        default:
          value = format(selectedDate as Date, 'dd-MM-y');
          setIsDatepickerOpen(false);

          break;
      }

      setInputValue(value);
    };

    const onClose = () => {
      setIsDatepickerOpen(false);
    };

    const datepickerProps = {
      mode,
      selected,
      defaultMonth,
      fromDate,
      toDate,
      toYear,
      initialFocus: isDatepickerOpen,
      onSelect: handleDaySelect,
      onClose: isMediumBreakpoint ? onClose : undefined,
      ...props,
    };

    return (
      <div>
        <label htmlFor={inputId}>
          <Stack gap="2" alignY="center">
            <Text size="BodyS" weight="bold">
              {label}
              {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
            </Text>
            <InputWrapper isInvalid={!!error} isDisabled={isDisabled}>
              <Stack direction="row" gap="2">
                <Stack.Item grow>
                  <StyledInput
                    ref={inputRef}
                    id={inputId}
                    name={name}
                    type="text"
                    inputMode="numeric"
                    defaultValue={defaultValue}
                    value={inputValue}
                    placeholder={placeholder}
                    aria-describedby={describedBy}
                    aria-invalid={error ? true : undefined}
                    disabled={isDisabled}
                    readOnly={isReadOnly}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    autoComplete="off"
                  />
                </Stack.Item>
                <ButtonIconContainer>
                  {isMediumBreakpoint ? (
                    <IconButton
                      label={isDatepickerOpen ? i18nCloseCalendar : i18nOpenCalendar}
                      onClick={() => {
                        setIsDatepickerOpen(!isDatepickerOpen);
                      }}>
                      <CalendarIcon />
                    </IconButton>
                  ) : (
                    <DialogPrimitive.Root onOpenChange={setIsDatepickerOpen} open={isDatepickerOpen}>
                      <DialogPrimitive.Trigger asChild>
                        <IconButton
                          label={isDatepickerOpen ? i18nCloseCalendar : i18nOpenCalendar}
                          onClick={() => {
                            setIsDatepickerOpen(!isDatepickerOpen);
                          }}>
                          <CalendarIcon />
                        </IconButton>
                      </DialogPrimitive.Trigger>
                      <DialogPrimitive.Portal>
                        <StyledOverlay>
                          <StyledPageGrid>
                            <StyledContent width="wide">
                              <Box paddingRight="2" paddingTop="2">
                                <DialogPrimitive.Close asChild>
                                  <IconButton label={i18nCloseCalendar} size="large">
                                    <CloseIcon size="large" />
                                  </IconButton>
                                </DialogPrimitive.Close>
                              </Box>
                              <Box paddingBottom="6" overflow="auto">
                                <DialogPrimitive.Description asChild>
                                  <DatepickerWrapper>
                                    <Datepicker {...datepickerProps} numberOfMonths={1} />
                                  </DatepickerWrapper>
                                </DialogPrimitive.Description>
                              </Box>
                            </StyledContent>
                          </StyledPageGrid>
                        </StyledOverlay>
                      </DialogPrimitive.Portal>
                    </DialogPrimitive.Root>
                  )}
                </ButtonIconContainer>
              </Stack>
              <CalendarContainer hasError={!!error} css={isDatepickerOpen ? { zIndex: 2 } : { display: 'none' }}>
                {isMediumBreakpoint ? (
                  <FocusTrap
                    active={isDatepickerOpen}
                    focusTrapOptions={{
                      onPostDeactivate: onClose,
                    }}>
                    <div role="dialog">
                      <Datepicker {...datepickerProps} numberOfMonths={mode === 'range' ? 2 : 1} />
                    </div>
                  </FocusTrap>
                ) : null}
              </CalendarContainer>
            </InputWrapper>
          </Stack>
        </label>
        {error ? <Error id={errorId}>{error}</Error> : null}

        {hint ? (
          <Box paddingTop="2">
            <Hint id={hintId}>{hint}</Hint>
          </Box>
        ) : null}
      </div>
    );
  },
);

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputTextHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'autoComplete' | 'placeholder' | 'defaultValue' | 'value' /** used for controlled inputs */ | 'onBlur' | 'onFocus'
>;

export interface DayPickerProps<T extends DaySelectionMode = Exclude<DaySelectionMode, 'default'>>
  extends DayPickerBase {
  mode?: T;
  selected?: T extends 'single'
    ? DayPickerSingleProps['selected']
    : T extends 'multiple'
      ? DayPickerMultipleProps['selected']
      : T extends 'range'
        ? DayPickerRangeProps['selected']
        : DayPickerDefaultProps['selected'];
  onSelect?: T extends 'single'
    ? DayPickerSingleProps['onSelect']
    : T extends 'multiple'
      ? DayPickerMultipleProps['onSelect']
      : T extends 'range'
        ? DayPickerRangeProps['onSelect']
        : never;
}

interface Props extends Omit<DayPickerProps, 'locale'>, InputBaseProps, InputTextHTMLAttributes {
  mode?: Exclude<DaySelectionMode, 'default'>;
  /**
   * Earliest selectable date
   */
  fromDate?: Date;
  /**
   * Last selectable date
   */
  toDate?: Date;
  /**
   * Last selectable year
   */
  toYear?: number;
  /**
   * The initial month to show in the calendar
   */
  defaultMonth?: Date;
  disableWeekends?: boolean;
  onChange?({ target, type }: Pick<React.ChangeEvent<HTMLInputElement>, 'target' | 'type'>): void;
  /**
   * Fired with the selected value as type Date Date | Date[] | DateRange, when the datepicker is used to select a day.
   */
  onDaySelect?(date?: Date | Date[] | DateRange): void;
}
