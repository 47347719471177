// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSolarChargingIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M8 4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1 0 2.21-1.79 4-4 4S8 6.21 8 4Zm3 7v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1Zm8-6h1c.55 0 1-.45 1-1s-.45-1-1-1h-1c-.55 0-1 .45-1 1s.45 1 1 1ZM4 5h1c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1Zm16 14H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1ZM4.93 11.07c.2.2.45.29.71.29.26 0 .51-.1.71-.29l1.41-1.41a.996.996 0 1 0-1.41-1.41L4.94 9.66a.996.996 0 0 0 0 1.41h-.01Zm11.31-1.41 1.41 1.41c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41l-1.41-1.41a.996.996 0 1 0-1.41 1.41h-.01Z" />
  </Icon>
);
export default SvgSolarChargingIcon;
SvgSolarChargingIcon.displayName = 'SvgSolarChargingIcon';
