// Borrowed from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/css/flexgap.js
// Also see https://caniuse.com/flexbox-gap and Eneco's actual visitor stats for Safari <14.1
// This check only works when document.body does not have `display: none` (set by Next.js)
// Search for "noflexgap" or "no-flexbox-gap" to find how/where this is used.

function supportsFlexGap() {
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1;
  flex.parentNode && flex.parentNode.removeChild(flex);
  if (!isSupported) document.documentElement.classList.add('no-flexbox-gap');
}

export const supportsFlexboxGap = '(' + supportsFlexGap.toString() + ')();';
