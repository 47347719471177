import { createContext, FC, PropsWithChildren, useContext, useState, useEffect } from 'react';

import { useApplication } from '@common/application';
import logger from '@common/log';
import {
  DefaultLayoutPlaceholderName,
  NoGridLayoutPlaceholderName,
  PlaceholderData,
  PlaceholderResponse,
  PlaceholderSearchParams,
} from '@sitecore/types/lib';

import { useLayoutData } from './SitecoreContext';
import { serializePersonalizationParams } from '../util/personalization';
type AnyPlaceholderName = NoGridLayoutPlaceholderName | DefaultLayoutPlaceholderName | string;

type ScopedComponentsByPlaceholder = Record<string, { scope: Scope | null; components: PlaceholderData | null }>;

type ComplexScope = Record<string, string | null>;
type ScopeParameter = ComplexScope | string[] | string;

type PlaceholderContextValue = {
  scopes: ScopedComponentsByPlaceholder;
  setScope: (
    placeholder: DefaultLayoutPlaceholderName | NoGridLayoutPlaceholderName,
    scope: ScopeParameter | null,
  ) => void;
  getScope: (placeholder: AnyPlaceholderName) => Scope | null;
  hasScopedComponents: (placeholder: AnyPlaceholderName) => boolean;
  getScopedComponents: (placeholder: AnyPlaceholderName) => PlaceholderData | null;
};

class Scope {
  private readonly scope: ComplexScope;
  private readonly serialized: string;

  constructor(input: ScopeParameter) {
    this.scope = {};
    if (typeof input === 'string') {
      this.scope[input] = null;
    } else if (Array.isArray(input)) {
      input.forEach(scopeItem => {
        if (scopeItem) {
          this.scope[scopeItem] = null;
        }
      });
    } else if (input) {
      Object.entries(input).forEach(([key, value]) => {
        if (key) {
          this.scope[key] = value?.toString() || null;
        }
      });
    }
    this.serialized = serializePersonalizationParams(this.scope);
  }

  /**
   * Returns serialized, request safe string of all scopes (and scope-value combinations) joined by the | (pipe) character
   */
  toString() {
    return this.serialized;
  }

  asScope(): ComplexScope {
    return { ...this.scope };
  }

  equals(compare: Scope | null) {
    return compare?.serialized === this.serialized;
  }
}

const PlaceholderContext = createContext<PlaceholderContextValue | null>(null);

export function usePlaceholder() {
  const context = useContext(PlaceholderContext);

  if (!context) {
    // return a dummy context to prevent errors
    logger.dev('usePlaceholder can only be used within a <PlaceholderProvider> if you want to use scoped placeholders');

    return {
      scopes: {},
      setScope: () => {
        throw new Error('setScope can only be used within a <PlaceholderProvider>');
      },
      getScope: () => null,
      hasScopedComponents: () => false,
      getScopedComponents: () => [],
    };
  }

  return context;
}

type Props = {
  fetchPlaceholder: ({}: PlaceholderSearchParams, idToken?: string | null) => Promise<PlaceholderResponse>;
  idToken?: string | null;
  currentRoutePath?: string;
};

export const PlaceholderProvider: FC<PropsWithChildren<Props>> = ({
  children,
  fetchPlaceholder,
  idToken,
  currentRoutePath,
}) => {
  const { context, route } = useLayoutData();
  const { locale } = useApplication();

  const path = context.itemPath || context.basePath;
  const possiblePlaceholderNames: string[] = Object.keys(route.placeholders || {});
  const [scopedPlaceholders, setScopedPlaceholders] = useState<ScopedComponentsByPlaceholder>({});
  const pendingScopes: { [key: string]: Scope | null } = {};

  useEffect(() => {
    if (currentRoutePath) {
      // we clear the scoped placeholder data when we change route
      setScopedPlaceholders({});
    }
  }, [currentRoutePath]);

  const fetchComponents = async (placeholder: string, scope: string | null = '') => {
    if (path && scope != null) {
      try {
        const { layoutData: placeholderData } = await fetchPlaceholder(
          {
            path,
            placeholderName: placeholder,
            locale,
            scope,
          },
          idToken,
        );
        if (placeholderData?.elements) {
          return placeholderData.elements || placeholderData.elements['default'];
        }
      } catch (error) {
        logger.error('PLCHLD', 'Fetching placeholder data failed', error);
      }
    }
    return null;
  };

  // If you want to use scope in a placeholder that is not present in the layout
  // you can extend it manually in the types defined in the types.ts file
  const setScope = async (
    placeholder: DefaultLayoutPlaceholderName | NoGridLayoutPlaceholderName,
    scope: ScopeParameter | null,
  ) => {
    if (!possiblePlaceholderNames.includes(placeholder)) {
      logger.warn('PLCHLD', `Placeholder ${placeholder} does not exist on the current route`);
      return;
    }
    const properScope = scope ? new Scope(scope) : null;
    if (!properScope?.equals(getScope(placeholder)) && !properScope?.equals(pendingScopes[placeholder])) {
      pendingScopes[placeholder] = properScope;

      const components = await fetchComponents(placeholder, properScope?.toString() || null);
      setScopedPlaceholders({ ...scopedPlaceholders, ...{ [placeholder]: { scope: properScope, components } } });
      delete pendingScopes[placeholder];
    }
  };
  const getScope = (placeholder: AnyPlaceholderName) => {
    return scopedPlaceholders[placeholder]?.scope || null;
  };
  const getScopedComponents = (placeholder: AnyPlaceholderName) => {
    if (Object.hasOwn(scopedPlaceholders, placeholder)) {
      return scopedPlaceholders[placeholder]?.components || null;
    }
    return null;
  };

  const hasScopedComponents = (placeholder: AnyPlaceholderName) => {
    return !!getScope(placeholder) && !!getScopedComponents(placeholder)?.length;
  };

  return (
    <PlaceholderContext.Provider
      value={{
        setScope,
        getScope,
        getScopedComponents,
        hasScopedComponents,
        scopes: scopedPlaceholders,
      }}>
      {children}
    </PlaceholderContext.Provider>
  );
};
