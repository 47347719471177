import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

/**
 * Tokens specifically on colored backgrounds
 *
 * should possibly be revisited with a larger topic around a11y and contrast
 */

export const enecoNavlinkOncolorColors = {
  navLinkSecondaryOnColorDefault: eneco.linkColors.linkPrimary,
  navLinkSecondaryOnColorHover: eneco.linkColors.linkPrimary,
  navLinkSecondaryOnColorFocusOutline: eneco.borderColors.borderFocus,
  navLinkTertiaryOnColorDefault: eneco.linkColors.linkSecondary,
  navLinkTertiaryOnColorHover: eneco.linkColors.linkPrimary,
  navLinkTertiaryOnColorFocusOutline: eneco.borderColors.borderFocus,
};

export const oxxioNavlinkOncolorColors = {
  navLinkSecondaryOnColorDefault: oxxio.neutralColors.neutralWhite,
  navLinkSecondaryOnColorHover: oxxio.neutralColors.neutralWhite,
  navLinkSecondaryOnColorFocusOutline: oxxio.neutralColors.neutralWhite,
  navLinkTertiaryOnColorDefault: oxxio.neutralColors.neutralWhite,
  navLinkTertiaryOnColorHover: oxxio.neutralColors.neutralWhite,
  navLinkTertiaryOnColorFocusOutline: oxxio.neutralColors.neutralWhite,
};

export const woonenergieNavlinkOncolorColors = {
  navLinkSecondaryOnColorDefault: woonenergie.neutralColors.neutral200,
  navLinkSecondaryOnColorHover: woonenergie.neutralColors.neutralWhite,
  navLinkSecondaryOnColorFocusOutline: woonenergie.neutralColors.neutralWhite,
  navLinkTertiaryOnColorDefault: woonenergie.neutralColors.neutral200,
  navLinkTertiaryOnColorHover: woonenergie.neutralColors.neutralWhite,
  navLinkTertiaryOnColorFocusOutline: woonenergie.neutralColors.neutralWhite,
};
