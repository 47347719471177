// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDiscountIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="m21.41 13-10-10c-.38-.38-.88-.59-1.41-.59H4.41c-1.1 0-2 .9-2 2V10c0 .53.21 1.04.59 1.41l10 10a1.983 1.983 0 0 0 2.82 0l5.59-5.59c.78-.78.78-2.05 0-2.83V13Zm-15-4.59c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm8.43 9.73c-2 0-3.12-.63-3.47-2.56h-.82l.15-.97h.57c0-.17-.01-.33-.01-.5 0-.18.01-.33.01-.48h-.73l.17-.97h.66c.34-1.92 1.45-2.51 3.47-2.51.65 0 1.31.1 1.85.23l-.25 1.27c-.39-.11-.95-.18-1.42-.18-1.35 0-1.91.22-2.12 1.19h2.64l-.15.97H12.8c0 .15-.01.32-.01.49 0 .17 0 .33.01.48h2.37l-.17.97h-2.1c.2.99.76 1.23 2.12 1.23.48 0 1.06-.08 1.44-.19l.26 1.3c-.55.13-1.21.22-1.88.22v.01Z" />
  </Icon>
);
export default SvgDiscountIcon;
SvgDiscountIcon.displayName = 'SvgDiscountIcon';
