// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgChevronRightIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M8.314 6.316a1.07 1.07 0 0 0 0 1.515l4.17 4.17-4.17 4.17a1.07 1.07 0 1 0 1.516 1.515l4.932-4.933a1.07 1.07 0 0 0 0-1.515L9.83 6.305a1.08 1.08 0 0 0-1.516.01Z"
    />
  </Icon>
);
export default SvgChevronRightIcon;
SvgChevronRightIcon.displayName = 'SvgChevronRightIcon';
