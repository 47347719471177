import React, { createContext, ReactNode, useContext, useId } from 'react';

import { Root as RadioGroupRoot } from '@radix-ui/react-radio-group';
import type { RadioGroupProps as PrimitiveGroupProps } from '@radix-ui/react-radio-group';

import { useInputIds } from '../../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../../hooks/useValidationErrorEvent';
import { useI18nTranslation } from '../../../providers/i18n';
import { mergeRefs } from '../../../util';
import { Error as ErrorComponent } from '../../Input/InputError';
import { Hint } from '../../Input/InputHint';
import { Stack } from '../../Stack/Stack';
import { Text } from '../../Text/Text';

interface RadioGroupBaseProps {
  children: React.ReactNode;
  name: string;
  hint?: ReactNode;
  error?: string;
  isOptional?: boolean;
}

type RootProps = Pick<PrimitiveGroupProps, 'defaultValue' | 'value' | 'onValueChange'>;
type StackProps = Pick<React.ComponentProps<typeof Stack>, 'direction' | 'wrap' | 'alignY'>;

type LabelProps =
  | {
      label: string;
      'aria-labelledby'?: never;
    }
  | {
      'aria-labelledby': string;
      label?: never;
    };

type RadioGroupProps = RadioGroupBaseProps & RootProps & StackProps & LabelProps;

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      children,
      label,
      name,
      value,
      onValueChange,
      defaultValue,
      error,
      hint,
      isOptional,
      direction = 'row',
      wrap = true,
      alignY,
      'aria-labelledby': ariaLabelledby,
    },
    ref,
  ) => {
    const { errorId, hintId, inputId } = useInputIds({ error, hint });
    const groupLabelId = useId();
    const i18nOptionalText = useI18nTranslation('optional');

    const localRef = React.useRef<HTMLDivElement>(null);
    // Merge the local ref and forwarded ref so we can forward it *and* use it locally
    const mergedRef = mergeRefs([ref, localRef]);

    useValidationErrorEvent({ error, name }, localRef);

    return (
      <RadioGroupRoot
        ref={mergedRef}
        name={name}
        onValueChange={onValueChange}
        value={value}
        defaultValue={defaultValue}
        required={!isOptional}
        aria-labelledby={ariaLabelledby || groupLabelId}
        id={inputId}
        orientation={direction === 'row' ? 'horizontal' : 'vertical'}
        aria-describedby={hintId}
        loop>
        <RadioGroupContext.Provider value={{ ariaDescribedby: errorId }}>
          <Stack gap="3">
            {label && (
              <Text size="BodyS" weight="bold" id={groupLabelId}>
                {label}
                {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
              </Text>
            )}
            <Stack direction={direction} gap="3" wrap={wrap} alignY={alignY}>
              {children}
            </Stack>
            {error ? (
              <ErrorComponent id={errorId} hasArrow={false}>
                {error}
              </ErrorComponent>
            ) : null}
            {hint ? <Hint id={hintId}>{hint}</Hint> : null}
          </Stack>
        </RadioGroupContext.Provider>
      </RadioGroupRoot>
    );
  },
);

interface RadioGroupContextValue {
  ariaDescribedby?: string;
}

const RadioGroupContext = createContext<RadioGroupContextValue | undefined>(undefined);

export const useRadioGroup = () => {
  const context = useContext(RadioGroupContext);

  if (context === undefined) {
    throw new Error(
      `useRadioCard must be used within a RadioGroupContext.Provider. Make sure to place your Radio component inside a RadioGroup.`,
    );
  }

  return context;
};

/**
 * Note on ariaDescribedby and RadioGroupContext
 *
 * When a group of radio buttons is invalid and requires an error, it should be announced by assistive technology. This
 * can be done by describing the group with the error, using aria-describedby. However, when using VoiceOver the error
 * will not be announced (see https://russmaxdesign.github.io/accessible-forms/fieldset-error02.html). On error, when a
 * form is submitted, a radio input will be focussed when it's the first error in the form. The error won't be announced
 * by VoiceOver.
 *
 * We can circumvent this by adding aria-describedby to every radio input instead of the radio group. The downside will
 * be that all screen readers will read the error for every radio input, but the upside is that we're not excluding
 * VoiceOver. For hints we only need to describe the radio group, and not every single radio input because hints are not
 * blocking the user from continuing.
 */
