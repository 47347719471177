import React from 'react';

import { Box, Heading, Text } from '@sparky';

export const SelfservicePageTitle = ({ title, subTitle }: { title: string; subTitle?: string }) => {
  return (
    <Box
      paddingLeft={{ initial: '2', sm: '0' }}
      paddingTop={{ initial: '10', md: '16' }}
      paddingBottom={{ initial: '8', md: '12' }}>
      <Heading as="h1" size={{ initial: 'S', sm: 'M', lg: 'L' }}>
        {title}
      </Heading>
      {subTitle && (
        <Box paddingTop={6}>
          <Text>{subTitle}</Text>
        </Box>
      )}
    </Box>
  );
};
