import React from 'react';

import * as Separator from '@radix-ui/react-separator';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';

const DividerStyled = styled(Separator.Root, {
  backgroundColor: '$borderDividerLowEmphasis',
  display: 'block',

  variants: {
    /** The thickness of the divider  */
    weight: {
      regular: {
        height: '$borderWidths$s',
      },
      heavy: {
        height: '$borderWidths$m',
      },
    },
    emphasis: {
      low: {
        backgroundColor: '$borderDividerLowEmphasis',
      },
      medium: {
        backgroundColor: '$borderDividerMediumEmphasis',
      },
      high: {
        backgroundColor: '$borderDividerHighEmphasis',
      },
    },
    /** Empasis of the divider */
  },
  defaultVariants: {
    weight: 'regular',
    emphasis: 'low',
  },
});

interface Props extends TransformStitchesToSparky<typeof DividerStyled> {
  className?: never;
  isDecorative?: boolean;
}

const stitchesClassName = 'sparky-divider';

export const Divider = React.forwardRef<HTMLDivElement, Props>(
  ({ weight = 'regular', emphasis = 'low', className = '', isDecorative = true }, ref) => {
    const variantProps = extractVariantProps({ weight, emphasis });
    return (
      <DividerStyled
        {...variantProps}
        className={`${stitchesClassName} ${className}`}
        ref={ref}
        decorative={isDecorative}
      />
    );
  },
);

Divider.toString = () => `.${stitchesClassName}`;
DividerStyled.displayName = 'styled(Divider)';
Divider.displayName = 'Divider';
