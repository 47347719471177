// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSendIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M22 12a1 1 0 0 1-.64.93l-17.976 7a.998.998 0 0 1-.999-.16 1 1 0 0 1-.339-1l.999-5a1 1 0 0 1 .978-.77h6.991a.998.998 0 0 0 .707-1.706.998.998 0 0 0-.707-.293h-6.99a.998.998 0 0 1-1-.8l-.998-5a1.001 1.001 0 0 1 .34-1 .998.998 0 0 1 .659-.2.888.888 0 0 1 .36.07l17.976 7A.999.999 0 0 1 22 12Z" />
  </Icon>
);
export default SvgSendIcon;
SvgSendIcon.displayName = 'SvgSendIcon';
