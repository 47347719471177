import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { StickyNavDesktop } from './StickyNav.desktop';
import { StickyNavMobile } from './StickyNav.mobile';
import { StickyNavLinks } from './types';
import { useMediaQuery } from '../../hooks';
import { styled } from '../../stitches.config';

const StyledNav = styled('nav', {
  position: 'sticky',
  top: 0,

  '@md': {
    top: '$4',
  },
});

interface Props {
  links: StickyNavLinks;
}

export const StickyNav: FC<Props> = ({ links = [] }) => {
  const isDesktop = useMediaQuery('md');
  const observedAnchors: { [index: string]: number } = useMemo(() => ({}), []);
  const stickyNavRef = useRef<HTMLElement>(null);
  const [linksState, setLinksState] = useState(links);

  const setActiveLink = useCallback(() => {
    const mostVisibleAnchor = Object.keys(observedAnchors).reduce((a, b) =>
      observedAnchors[a] > observedAnchors[b] ? a : b,
    );
    setLinksState(linksState =>
      linksState.map(link => ({
        ...link,
        active: link.href.includes(mostVisibleAnchor),
      })),
    );
  }, [observedAnchors]);

  const findAnchorTarget = useCallback(
    (selector: string): Promise<Element | null> =>
      new Promise(resolve => {
        // Make sure the element isn't already there, e.g. already part of the static HTML or SSR
        const element = document.querySelector(selector);

        if (element) {
          return resolve(element);
        }

        // If not, utilise a mutation observer to later find the element once it's added
        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            if (!mutation.addedNodes) return;

            Array.from(mutation.addedNodes).forEach(node => {
              if (node.parentNode?.querySelector && node.parentNode?.querySelector(selector)) {
                observer.disconnect();
                return resolve(node.parentNode?.querySelector(selector));
              }
            });
          });
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      }),
    [],
  );

  useEffect(() => {
    if (!stickyNavRef.current) return;

    // Find all anchor links within current StickyNav component so we can start looking for corresponding targets
    links
      .filter(link => link.href.includes('#'))
      .forEach(async link => {
        const anchor = link.href.split('#')[1];
        const anchorTarget = await findAnchorTarget(`#${anchor}`);

        // If this link has no target or the target has already been observed, skip it
        if (!anchorTarget || Object.prototype.hasOwnProperty.call(observedAnchors, anchor)) {
          return;
        }

        observedAnchors[anchor] = 0;

        const observer = new IntersectionObserver(
          (entries: IntersectionObserverEntry[]) => {
            const entry = entries.filter(entry => entry.isIntersecting).pop();
            observedAnchors[anchor] = entry?.intersectionRatio || 0;

            setActiveLink();
          },
          {
            threshold: [0.25, 0.5, 0.75, 1],
          },
        );
        observer.observe(anchorTarget);
      });
  }, [findAnchorTarget, links, observedAnchors, setActiveLink, stickyNavRef]);

  const NavContent = isDesktop ? StickyNavDesktop : StickyNavMobile;

  return (
    <StyledNav ref={stickyNavRef}>
      <NavContent links={linksState}></NavContent>
    </StyledNav>
  );
};

StickyNav.displayName = 'StickyNav';
StyledNav.displayName = 'styled(nav)';
