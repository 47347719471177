import { Indicator } from '@radix-ui/react-radio-group';

import { styled } from '../../stitches.config';

/**
 * Renders when the radio item is in a checked state.
 */
export const RadioIndicator = styled(Indicator, {
  width: '1.2rem',
  height: '1.2rem',
  borderRadius: '$round',
  backgroundColor: '$controlsActive',
});
