import { ReactNode, useId } from 'react';

const joinIds = (ids: (string | undefined)[]): string | undefined => {
  return ids.filter(id => id !== undefined).join(' ') || undefined;
};

interface InputIds {
  inputId: string;
  describedBy?: string;
  errorId?: string;
  hintId?: string;
}

// TODO: Use JSDoc to describe usage for return values, is that possible?
/** Internal use only (InputText, InputTelephone, etc.) */
export const useInputIds = ({ error, hint }: { error?: string; hint?: ReactNode }): InputIds => {
  const inputId = useId();

  const errorId = error ? `${inputId}-error` : undefined;
  const hintId = hint ? `${inputId}-hint` : undefined;
  const describedBy = joinIds([errorId, hintId]);

  return {
    inputId,
    describedBy,
    errorId,
    hintId,
  };
};
