import React, { VFC, FC, useState, useEffect, useCallback } from 'react';
import { createContext, useContext } from 'react';

import { useRouter } from '@dxp-next';
import { Box, NotificationBox } from '@sparky';
import { NotificationBoxProps } from '@sparky/types';

type NotificationContent = (Omit<NotificationBoxProps, 'isAlert' | 'isElevated'> & { route?: string }) | null;

const NotificationContext = createContext<{
  notification: NotificationContent;
  setNotification: (notification: NotificationContent) => void;
} | null>(null);

const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) throw new Error('useNotification must be wrapped inside NotificationContextProvider');
  return context;
};

export const useRedirectAndNotify = () => {
  const { pushHref } = useRouter();
  const { setNotification } = useNotification();

  return useCallback(
    (notification: NotificationContent & { route: string }) => {
      setNotification(notification);
      pushHref(notification.route);
    },
    [setNotification, pushHref],
  );
};

export const useSetNotification = () => {
  const { setNotification } = useNotification();
  return setNotification;
};

export const NotificationContextProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [notification, setNotification] = useState<NotificationContent>(null);

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const Notification: VFC = () => {
  const router = useRouter();
  const { notification, setNotification } = useNotification();
  const showNotificationOnCurrentRoute = !notification?.route || notification.route === router.activePath;

  useEffect(() => {
    if (!showNotificationOnCurrentRoute) return;

    const onBeforeRouteChange = () => setNotification(null);
    router.events.on('beforeHistoryChange', onBeforeRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', onBeforeRouteChange);
    };
  }, [router, setNotification, showNotificationOnCurrentRoute]);

  if (!notification || !showNotificationOnCurrentRoute) return null;

  const { route, ...notificationProps } = notification;

  return (
    <Box paddingBottom="6">
      <NotificationBox isElevated={true} isAlert={true} {...notificationProps} />
    </Box>
  );
};
