import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';

import { pushToDataLayer } from '../dataLayer';
import { TrackingContext } from '../TrackingProvider.context';

type ValidationErrorField = {
  type?: string | number;
  message?: string;
};

type ValidationErrorParam = {
  [key: string]: ValidationErrorField | ValidationErrorParam;
};

type TrackPageViewOptions = {
  pageName: string;
  previousPage: string;
};

type ViewComponentProps = {
  /** label of the child component, tracking event will be sent as ${variant} - ${label} */
  label: string;
  /** variant of the child component, tracking event will be sent as ${variant} - ${label}*/
  variant?: string;
  /** name of the child component to be tracked, should be equal to the name of the child */
  type: string;
  /** Any additional info to be sent with the tracking event */
  additionalInfo?: string;
};

type trackFormInteractionStartOptions = {
  trackSeperateFieldsAsFunnels?: boolean;
};

type FunnelOptions = {
  funnel?: string;
  step?: string;
  channel?: 'web' | 'app';
};

type FunnelOptionsStep = {
  funnel?: string;
  step: string;
  channel?: 'web' | 'app';
};

type ErrorOptions = {
  errorCode?: number;
  errorOrigin?: string;
  errorType?: string;
  message: string;
};

type UseTrackingContext = {
  scope: string;
  trackError: (options: ErrorOptions) => void;
  trackValidationError: (errors: ValidationErrorParam) => void;
  trackViewComponent: (viewComponentProps: ViewComponentProps) => void;
  trackFunnelStart: (funnelOptions?: FunnelOptions) => void;
  trackFunnelStep: (funnelOptions: FunnelOptionsStep) => void;
  trackFunnelCompleted: (funnelOptions?: FunnelOptions) => void;
  trackFormInteractionStart: (
    formEvent?: React.FocusEvent<HTMLFormElement>,
    trackSeperateFieldsAsFunnels?: trackFormInteractionStartOptions,
  ) => void;
  trackFormInteractionCompleted: (funnelOptions?: FunnelOptions) => void;
  trackPageView: ({ pageName, previousPage }: TrackPageViewOptions) => void;
  resetFunnel: (funnel?: string) => void;
  setTrackingVariant: Dispatch<SetStateAction<string | undefined>>;
  trackFirstTimeOpen: () => void
};

enum FunnelSessionState {
  STARTED = 'started',
  RESTARTED = 'restarted',
  COMPLETED = 'completed',
}

type FunnelSession = Record<string, FunnelSessionState>;

const sessionKey = 'ENECO_GA_FUNNEL_SESSIONS';

const getFunnelsInSession = (): FunnelSession => {
  const funnelSession = sessionStorage.getItem(sessionKey) || '';
  return funnelSession ? JSON.parse(funnelSession) : {};
};

const updateFunnelSession = (funnel: string, state: FunnelSessionState) => {
  const funnelSession = getFunnelsInSession();
  if (funnel && state) {
    funnelSession[funnel] = state;
    sessionStorage.setItem(sessionKey, JSON.stringify(funnelSession));
  }
};

const removeFunnelFromSession = (funnel: string) => {
  const state = getFunnelsInSession();
  delete state[funnel];
  sessionStorage.setItem(sessionKey, JSON.stringify(state));
};

const isFunnelInSession = (funnel: string, state?: FunnelSessionState): boolean => {
  const funnelSession = getFunnelsInSession();
  if (state) return funnel in funnelSession && funnelSession[funnel] === state;
  return funnel in funnelSession;
};

export const useTracking = (scope?: string): UseTrackingContext => {
  const { scope: sitecoreScope, setTrackingVariant } = useContext(TrackingContext);
  const [trackedErrors, setTrackedErrors] = useState<{ [key: string]: string[] }>({});
  const _scope = scope ?? sitecoreScope;

  const trackError = useCallback(
    (options: ErrorOptions) => {
      const { errorCode, errorOrigin, message, errorType } = options;
      pushToDataLayer({
        event: 'present_error',
        component_type: _scope,
        error_message: message ?? 'unknown',
        ...(errorCode && { error_code: errorCode }),
        ...(errorOrigin && { error_origin: errorOrigin }),
        ...(errorType && { error_type: errorType }),
      });
    },
    [_scope],
  );

  const trackValidationError = useCallback(
    (errors: ValidationErrorParam) => {
      const isValidationErrorParam = (
        error: ValidationErrorField | ValidationErrorParam,
      ): error is ValidationErrorParam => error && error.message === undefined && error.type === undefined;

      Object.entries(errors).forEach(([key, error]) => {
        if (isValidationErrorParam(error)) trackValidationError(error);

        const { message } = error;
        const errorKey = key + message;
        if (!trackedErrors[errorKey]) {
          trackedErrors[errorKey] = [];
        }

        if (!!message && typeof message === 'string' && !trackedErrors[errorKey].includes(message)) {
          trackedErrors[errorKey] = [...trackedErrors[errorKey], message];
          pushToDataLayer({
            event: 'present_error',
            component_type: _scope,
            error_message: message,
            error_origin: key,
            error_type: 'Validation Error',
          });
          setTrackedErrors({ ...trackedErrors });
        }
      });
    },
    [_scope, trackedErrors],
  );

  const trackViewComponent = useCallback(
    ({ variant = 'default', type, label, additionalInfo }: ViewComponentProps) => {
      pushToDataLayer({
        event: 'view_component',
        component_name: _scope,
        component_variant: `${variant} - ${label}`,
        component_type: type,
        ...(additionalInfo && { additional_info: additionalInfo }),
      });
    },
    [_scope],
  );

  const trackFunnelStart = useCallback(
    (props?: FunnelOptions) => {
      const funnelName = props?.funnel || _scope;
      const stepName = props?.step || `${funnelName} start`;

      const isInSession = isFunnelInSession(funnelName);
      const isInSessionAndCompleted = isFunnelInSession(funnelName, FunnelSessionState.COMPLETED);

      pushToDataLayer({
        event: 'completion_funnel',
        component_type: sitecoreScope,
        funnel_name: funnelName,
        funnel_start: isInSession ? 0 : 1,
        funnel_completion: 0,
        funnel_step: stepName,
        funnel_channel: props?.channel || 'web',
      });

      const funnelState = !isInSessionAndCompleted ? FunnelSessionState.STARTED : FunnelSessionState.RESTARTED;
      updateFunnelSession(funnelName, funnelState);
    },
    [_scope, sitecoreScope],
  );

  const trackFunnelStep = useCallback(
    (props: FunnelOptionsStep) => {
      const funnelName = props?.funnel || _scope;
      const stepName = props.step;

      pushToDataLayer({
        event: 'completion_funnel',
        component_type: sitecoreScope,
        funnel_start: 0,
        funnel_completion: 0,
        funnel_name: funnelName,
        funnel_step: stepName,
        funnel_channel: props?.channel || 'web',
      });
    },
    [_scope, sitecoreScope],
  );

  const trackFunnelCompleted = useCallback(
    (props?: FunnelOptions) => {
      const funnelName = props?.funnel || _scope;
      const stepName = props?.step || `${funnelName} complete`;

      const isCompletedInSession = isFunnelInSession(funnelName, FunnelSessionState.COMPLETED);
      const isRestartedSession = isFunnelInSession(funnelName, FunnelSessionState.RESTARTED);

      if (isCompletedInSession) return;

      pushToDataLayer({
        event: 'completion_funnel',
        component_type: sitecoreScope,
        funnel_name: funnelName,
        funnel_start: 0,
        funnel_completion: isRestartedSession ? 0 : 1,
        funnel_step: stepName,
        funnel_channel: props?.channel || 'web',
      });

      if (!isCompletedInSession) {
        updateFunnelSession(funnelName, FunnelSessionState.COMPLETED);
      }
    },
    [_scope, sitecoreScope],
  );

  const resetFunnel = useCallback(
    (funnel?: string) => {
      const funnelName = funnel || _scope;
      if (isFunnelInSession(funnelName)) {
        removeFunnelFromSession(funnelName);
      }
    },
    [_scope],
  );

  const trackFormInteractionStart = useCallback(
    (formEvent?: React.FocusEvent<HTMLFormElement>, props?: trackFormInteractionStartOptions) => {
      const funnelSet: string[] = [_scope];

      if (props?.trackSeperateFieldsAsFunnels && formEvent?.target.name) {
        funnelSet.push(`${_scope} - ${formEvent.target.name}`);
      }

      funnelSet.forEach(funnel => {
        if (!isFunnelInSession(funnel) || isFunnelInSession(funnel, FunnelSessionState.COMPLETED)) {
          trackFunnelStart({ funnel });
        }
      });
    },
    [_scope, trackFunnelStart],
  );

  const trackFormInteractionCompleted = useCallback(
    (props?: FunnelOptions) => {
      const funnelName = props?.funnel || _scope;

      const funnelsToClose = [
        ...new Set([
          ...Object.entries(getFunnelsInSession())
            .filter(([funnel, state]) => state !== FunnelSessionState.COMPLETED && funnel.startsWith(funnelName))
            .map(([funnel]) => funnel),
        ]),
      ];

      funnelsToClose.forEach(funnel => {
        trackFunnelCompleted({ funnel });
      });
    },
    [trackFunnelCompleted],
  );

  const trackPageView = useCallback(({ pageName, previousPage }: TrackPageViewOptions) => {
    pushToDataLayer({
      event: 'pageview',
      page_name: pageName,
      previous_page: previousPage,
    });
  }, []);

  const trackFirstTimeOpen = useCallback(() => {
    pushToDataLayer({
      event: 'first_smartmeterapp_open'
    });
  }, [])

  return {
    scope: _scope,
    trackError,
    trackFunnelStart,
    trackFunnelCompleted,
    trackFormInteractionStart,
    trackFormInteractionCompleted,
    trackPageView,
    trackValidationError,
    trackViewComponent,
    trackFunnelStep,
    resetFunnel,
    setTrackingVariant,
    trackFirstTimeOpen,
  };
};
