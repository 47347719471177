import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';

// Takes a LinkFieldValue object and combines the href, anchor, and querystring properties into a single string while
// adding a leading ? and # to the querystring and anchor as needed.
export const getCombinedLinkFieldValueHref = (
  link: Pick<LinkFieldValue, 'anchor' | 'href' | 'querystring'>,
): string => {
  if (!link) return '';

  return `${link.href ?? ''}${link.querystring ? `?${link.querystring.replace(/^\?/, '')}` : ''}${
    link.anchor ? `#${link.anchor.replace(/^#/, '')}` : ''
  }`;
};
