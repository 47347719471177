// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHeartIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M16 3c-1.5 0-2.91.55-4 1.54A5.918 5.918 0 0 0 8 3C4.69 3 2 5.69 2 9c0 1.57.61 3.06 1.72 4.2l7.58 7.51c.2.19.45.29.7.29.25 0 .51-.1.7-.29l7.56-7.49A6.014 6.014 0 0 0 21.99 9c0-3.31-2.69-6-6-6H16Z" />
  </Icon>
);
export default SvgHeartIcon;
SvgHeartIcon.displayName = 'SvgHeartIcon';
