type Props = { [key: string]: string };

/**
 * Filters the given properties and only returnes the ones starting with `data-`
 *
 * @param props List of component properties
 * @returns Filtered list of properties starting with `data-`
 */
export function extractDataProps(props: Props): Props {
  return Object.fromEntries(Object.entries(props).filter(([key]) => key.startsWith('data-')));
}
