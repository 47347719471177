import { FC, ReactNode } from 'react';

import { styled } from '@stitches/react';

import { TabLink } from './TabLink';
import { Stack } from '../Stack/Stack';

type CompoundBucketProps = FC<{
  /** Label used to distinguish the navigation from other navigation */
  ariaLabel?: string;
  children: ReactNode;
  className?: never;
  fullWidth?: boolean;
}> & {
  Item: typeof TabLink;
};

const StyledNav = styled('nav', {
  borderBottom: `$borderWidths$s solid $borderDividerLowEmphasis`,
  overflowX: 'auto',
  scrollbarWidth: 'none',

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
      false: {
        width: 'fit-content',
      },
    },
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [`& ${Stack} > li:first-of-type`]: {
    marginLeft: 'auto',
  },
  [`& ${Stack} > li:last-of-type`]: {
    marginRight: 'auto',
  },
});

const stitchesClassName = 'sparky-tabnavigation';

const TabNavigation: CompoundBucketProps = ({ ariaLabel, className = '', children, fullWidth = true }) => (
  <StyledNav aria-label={ariaLabel} className={`${stitchesClassName} ${className}`} fullWidth={fullWidth}>
    <Stack as="ul" direction="row">
      {children}
    </Stack>
  </StyledNav>
);

TabNavigation.toString = () => `.${stitchesClassName}`;

StyledNav.displayName = 'styled(TabNavigation)';
TabNavigation.displayName = 'TabNavigation';

TabNavigation.Item = TabLink;

export { TabNavigation };
