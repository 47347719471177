import React from 'react';

import { addDays } from '@common/date';
import { capitalizeFirstLetter } from '@common/string';
import { Locale } from 'date-fns';
import { startOfISOWeek } from 'date-fns/startOfISOWeek';
import { startOfWeek } from 'date-fns/startOfWeek';
import { useDayPicker } from 'react-day-picker';

import { Text } from '../../Text/Text';

export const HeadRow = () => {
  const {
    classNames,
    styles,
    locale,
    weekStartsOn,
    ISOWeek,
    formatters: { formatWeekdayName },
  } = useDayPicker();

  const weekdays = getWeekdays(locale, weekStartsOn, ISOWeek).map(weekday =>
    (formatWeekdayName(weekday, { locale }) as string).toLowerCase(),
  );

  return (
    <tr style={styles.head_row} className={classNames.head_row}>
      {weekdays.map((weekday, i) => (
        <th key={i} scope="col" className={classNames.head_cell} style={styles.head_cell}>
          <Text key={i} size="BodyS" color="textLowEmphasis" weight="bold">
            {capitalizeFirstLetter(weekday)}
          </Text>
        </th>
      ))}
    </tr>
  );
};

function getWeekdays(
  locale?: Locale,
  /** The index of the first day of the week (0 - Sunday). */
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  /** Use ISOWeek instead of locale/ */
  ISOWeek?: boolean,
): Date[] {
  const start = ISOWeek ? startOfISOWeek(new Date()) : startOfWeek(new Date(), { locale, weekStartsOn });
  const days: Date[] = [];

  for (let i = 0; i < 7; i++) {
    const day = addDays(start, i);
    days.push(day);
  }

  return days;
}
