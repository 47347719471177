// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgLinkedInIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M22 21.15h-4.287v-6.91c0-1.736-.621-2.922-2.173-2.922-1.184 0-1.89.803-2.2 1.575-.115.276-.143.659-.143 1.046v7.213H8.91s.057-11.706 0-12.919l4.286.003v1.828c.573-.88 1.59-2.132 3.865-2.132 2.821 0 4.938 1.846 4.938 5.817v7.407L22 21.15ZM4.395 6.469h-.027C2.928 6.469 2 5.473 2 4.234 2 2.967 2.956 2 4.421 2c1.467 0 2.372.965 2.397 2.234 0 1.239-.93 2.235-2.426 2.235h.003ZM2.25 8.23l4.29.002v12.92H2.25V8.232Z"
    />
  </Icon>
);
export default SvgLinkedInIcon;
SvgLinkedInIcon.displayName = 'SvgLinkedInIcon';
