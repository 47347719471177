import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

/**
 * RadioCard specific tokens
 */

export const enecoRibbonColors = {
  ribbonBackgroundMediumEmphasis: eneco.backgroundColoredColors.backgroundVarOne,
  ribbonColorMediumEmphasis: eneco.textColors.textOnBackgroundVarOne,

  ribbonBackgroundHighEmphasis: eneco.secondaryColors.purple900,
  ribbonColorHighEmphasis: eneco.textColors.textInverted,
};

export const oxxioRibbonColors = {
  ribbonBackgroundMediumEmphasis: oxxio.secondaryColors.yellow65,
  ribbonColorMediumEmphasis: oxxio.textColors.textPrimary,

  ribbonBackgroundHighEmphasis: oxxio.backgroundColors.backgroundDark,
  ribbonColorHighEmphasis: oxxio.textColors.textInverted,
};

export const woonenergieRibbonColors = {
  ribbonBackgroundMediumEmphasis: woonenergie.brandSupport.lightLime,
  ribbonColorMediumEmphasis: woonenergie.textColors.textPrimary,

  ribbonBackgroundHighEmphasis: woonenergie.brandGradients.brandGradient,
  ribbonColorHighEmphasis: woonenergie.textColors.textInverted,
};
