import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

/**
 * Header specific tokens
 */

export const enecoHeaderColors = {
  navBackgroundPrimary: eneco.neutralColors.neutral100,
  navBackgroundSecondary: eneco.neutralColors.neutral200,
  navBackgroundTertiary: eneco.neutralColors.neutralWhite,
  navTextLabel: eneco.brandColors.brandDarkRed,
};

export const enecoHeaderDividerHeight = {
  navDividerHeight: eneco.borderWidths.none,
};

export const oxxioHeaderColors = {
  navBackgroundPrimary: oxxio.neutralColors.neutralWhite,
  navBackgroundSecondary: oxxio.brandColors.brandDarkBlue,
  navBackgroundTertiary: oxxio.neutralColors.neutral900,
  navTextLabel: oxxio.neutralColors.neutralWhite,
};

export const oxxioHeaderDividerHeight = {
  navDividerHeight: oxxio.borderWidths.s,
};

export const woonenergieHeaderColors = {
  navBackgroundPrimary: woonenergie.neutralColors.neutralWhite,
  navBackgroundSecondary: woonenergie.brandGradients.brandGradient,
  navBackgroundTertiary: woonenergie.brandColors.brandLightBlue,
  navTextLabel: woonenergie.neutralColors.neutralWhite,
};

export const woonenergieHeaderDividerHeight = {
  navDividerHeight: woonenergie.borderWidths.none,
};
