export { supportsFlexboxGap } from '../supports-flexbox-gap';
export { createVariants } from './vanilla-extract';

type classObject = { [key: string]: boolean };

/**
 * This utility will accept strings as arguments and returns a string with all the arguments concatenated and separated by a
 * space. Useful for generating a list of conditional classNames.
 *
 * @param args A list of strings
 * @returns A string with all the arguments concatenated and separated by a space
 *
 * @example
 * const isVisible = true;
 * const isAlert = false;
 * const isDisabled = { isDisabled: true, isHidden: false, isNotification: true }
 *
 * const classNames = getClassNames('notification', isVisible && 'visible', isAlert && 'alert', { isDisabled: true });
 *
 * // classNames = 'notification visible isDisabled isNotification'
 */
export function getClassNames(...args: Array<boolean | string | classObject | undefined>): string {
  let classNames = '';

  for (const arg of args) {
    if (typeof arg === 'string') {
      classNames += ` ${arg}`;
    } else if (typeof arg === 'object' && arg !== null) {
      classNames += Object.keys(arg).reduce((acc, key) => `${acc}${arg[key] ? ` ${key}` : ''}`, '');
    }
  }

  return classNames.trim();
}
