// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgStandbyConsumptionIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12.5 2C9.586 2 7.527 3.423 7.527 3.423L6 9h13l-1.527-5.577S15.413 2 12.5 2ZM12 11c-2.978 0-7.21.54-7.495.59-1.134.28-2.05.56-2.77.82C.633 12.75 0 13 0 13h24s-.633-.25-1.735-.59c-.72-.26-1.658-.54-2.803-.82 0 0-4.386-.59-7.462-.59ZM6.938 14C4.763 14 3 15.79 3 18s1.763 4 3.938 4c2.174 0 3.937-1.79 3.937-4 0-.183 0-.366-.034-.537.36-.08.743-.149 1.159-.137.416 0 .799.057 1.159.137-.034.171-.034.354-.034.537 0 2.21 1.763 4 3.938 4C19.236 22 21 20.21 21 18s-1.763-4-3.938-4c-1.653 0-3.07 1.029-3.656 2.503A4.386 4.386 0 0 0 12 16.286c-.619 0-1.046.103-1.406.217C10.009 15.029 8.59 14 6.938 14ZM17 15a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-7 3a3 3 0 1 0-6 0 3 3 0 0 0 6 0Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgStandbyConsumptionIcon;
SvgStandbyConsumptionIcon.displayName = 'SvgStandbyConsumptionIcon';
