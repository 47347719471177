import React, { FC } from 'react';

import { isLoadingStyle } from '../../components/Button/Button.css';
import { styled, keyframes } from '../../stitches.config';
import { theme } from '../../themes';
import { SvgIcon, IconProps } from '../IconWrapper';

const rotate = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const dash = keyframes({
  '0%': {
    'stroke-dasharray': '1, 200',
    'stroke-dashoffset': 0,
  },
  '50%': {
    'stroke-dasharray': '90, 200',
    'stroke-dashoffset': '-35px',
  },
  '100%': {
    'stroke-dasharray': '90, 200',
    'stroke-dashoffset': '-125px',
  },
});

const StyledSpinnerIcon = styled(
  'svg',
  {
    animation: `${rotate} 2s linear infinite;`,
    'transform-origin': 'center center',

    '& circle': {
      animation: `${dash} 1.5s ease infinite`,
      'stroke-dasharray': `89, 200`,
      'stroke-dashoffset': 0,
      'stroke-linecap': 'round',
      'stroke-miterlimit': 10,
      'stroke-width': '6px',
    },

    [`.${isLoadingStyle} &`]: {
      position: 'absolute',
      color: theme.colors.buttonLoadingDefaultText,
    },
  },
  SvgIcon,
);

const stitchesClassName = 'sparky-spinner';

const SpinnerIcon: FC<IconProps> = ({ color = 'currentColor', size }) => (
  <StyledSpinnerIcon
    viewBox="25 25 50 50"
    size={size}
    aria-hidden="true"
    className={stitchesClassName}
    css={{ stroke: color === 'currentColor' ? 'currentColor' : `$${color}` }}>
    <circle fill="none" cx="50" cy="50" r="20" />
  </StyledSpinnerIcon>
);

export default SpinnerIcon;

SpinnerIcon.toString = () => `.${stitchesClassName}`;
