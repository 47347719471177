// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgChevronRightFatIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12.369 12 7.545 7.176a1.86 1.86 0 0 1 2.631-2.631l6.14 6.14a1.86 1.86 0 0 1 0 2.63l-6.14 6.14a1.86 1.86 0 0 1-2.631-2.631L12.369 12Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgChevronRightFatIcon;
SvgChevronRightFatIcon.displayName = 'SvgChevronRightFatIcon';
