// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgGasIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12.63 2.22a1 1 0 0 0-1.26 0C11.15 2.4 6 6.608 6 11.997c0 5.388 5.15 9.596 5.37 9.776a1 1 0 0 0 1.26 0c.22-.18 5.37-4.388 5.37-9.776 0-5.389-5.15-9.597-5.37-9.777ZM12 19.994a8.476 8.476 0 0 1-2-4.998 8.476 8.476 0 0 1 2-4.999 8.476 8.476 0 0 1 2 4.999 8.476 8.476 0 0 1-2 4.998Z"
    />
  </Icon>
);
export default SvgGasIcon;
SvgGasIcon.displayName = 'SvgGasIcon';
