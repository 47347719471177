import { useState } from 'react';

import { useAsync } from 'react-use';

import type { Locale } from '@common/application';
import logger from '@common/log';

import { Dictionary } from './types';

type Fn = Dictionary | ((locale: Locale) => Promise<Dictionary>);

type DictionaryState = Dictionary | null;

const useDictionary = (
  dictionaryOrFn: Fn,
  locale: Locale,
): {
  dictionary: DictionaryState;
  isLoading: boolean;
} => {
  const initialDictionary = typeof dictionaryOrFn !== 'function' ? dictionaryOrFn : null;
  const [dictionary, setDictionary] = useState<DictionaryState>(initialDictionary);
  const [isLoading, setIsLoading] = useState<boolean>(!initialDictionary);

  useAsync(async () => {
    if (typeof dictionaryOrFn === 'function') {
      try {
        setIsLoading(true);
        const response = await dictionaryOrFn(locale);
        // Accept __esModule and POJOs
        setDictionary((response.default ?? response) as Dictionary);
        setIsLoading(false);
      } catch (error) {
        // Only a network error could take us here (Webpack dev mode throws for incorrect paths)
        logger.error('VpvrHU', 'Error fetching dictionary', error);
        setIsLoading(false);
      }
    }
  }, [locale]);

  return { isLoading, dictionary };
};

export default useDictionary;
