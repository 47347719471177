import fetch from 'cross-fetch';

import { OpenAPI } from './OpenAPI';
import { catchErrors, getHeaders, getRequestBody, getResponseBody, getUrl } from './request-helpers';
import type { ApiRequestConfig, ApiRequestOptions, ApiResult } from './types';

function sendRequest(options: ApiRequestOptions, url: URL): Promise<Response> {
  const request: RequestInit = {
    method: options.method,
    headers: getHeaders(options),
    body: getRequestBody(options),
  };
  if (OpenAPI.WITH_CREDENTIALS) {
    request.credentials = 'include';
  }

  return fetch(url.toString(), request);
}

/**
 * Request using fetch client
 * @param options The request options from the the service
 * @returns ApiResult['body']
 * @throws ApiError
 */
export async function request(
  options: ApiRequestOptions,
  requestConfig?: ApiRequestConfig,
): Promise<ApiResult['body']> {
  const url = getUrl(options, requestConfig);

  const response = await sendRequest(options, url);
  const responseBody = await getResponseBody(response);

  const result: ApiResult = {
    url,
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
    body: responseBody,
    headers: response.headers,
  };

  catchErrors(options, result);

  return result.body;
}
