// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgInstagramIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M18.07 6.7a1.14 1.14 90 1 1-1.14-1.14 1.14 1.14 90 0 1 1.14 1.14Zm3.61 5.14v.01l-.06 4.13a5.7 5.7 90 0 1-5.63 5.63l-4.15.07h-.01l-4.13-.06a5.7 5.7 90 0 1-5.63-5.63L2 11.84v-.01l.06-4.13a5.7 5.7 90 0 1 5.63-5.63L11.84 2h.01l4.13.06a5.7 5.7 90 0 1 5.63 5.63Zm-1.83 0-.06-4.12a3.87 3.87 90 0 0-3.83-3.83l-4.12-.06-4.12.06a3.87 3.87 90 0 0-3.83 3.83l-.06 4.12.06 4.12a3.87 3.87 90 0 0 3.83 3.83l4.12.06 4.12-.06a3.87 3.87 90 0 0 3.83-3.83Zm-2.96 0a5.05 5.05 90 1 1-5.05-5.05 5.06 5.06 90 0 1 5.05 5.05Zm-1.83 0a3.22 3.22 90 1 0-3.22 3.22 3.23 3.23 90 0 0 3.22-3.22Z" />
  </Icon>
);
export default SvgInstagramIcon;
SvgInstagramIcon.displayName = 'SvgInstagramIcon';
