import { FC } from 'react';

import { styled } from '../../stitches.config';
import { Text } from '../Text/Text';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

interface Props {
  // The id prop is less strict than in Input* components to provide a better DX and
  // make the code more readable.
  id?: string;
  children: string;
  hasArrow?: boolean;
  className?: never;
}

export const Arrow = styled('div', {
  //creates an arrow of roughly 12x6 pixels
  width: '8px',
  height: '8px',
  marginTop: '2px',
  marginLeft: '$3',
  marginBottom: '-5px',
  backgroundColor: '$formErrorMessageBackground',
  transform: 'rotate(45deg)',
});

const StyledError = styled('div', {
  backgroundColor: '$formErrorMessageBackground',
  paddingX: '$3',
  paddingY: '$2',
  borderRadius: '$s',
  boxShadow: '$s',
});

/** Internal use only (InputText, InputTelephone, etc.) */
export const Error: FC<React.PropsWithChildren<Props>> = ({ id, children, hasArrow = true, className = '' }) => {
  const endsWithPunctuation = /[.?!]$/.test(children);

  return (
    <div className={className}>
      {hasArrow && <Arrow />}
      <StyledError id={id} role="alert">
        <Text color="textInverted" size="BodyS">
          {children}
          {endsWithPunctuation ? null : <VisuallyHidden>{'.'}</VisuallyHidden>}
        </Text>
      </StyledError>
    </div>
  );
};

Arrow.displayName = 'Arrow';
StyledError.displayName = 'styled(Error)';
