// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgLogoutIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M11 5H5v14h6c.55 0 1 .45 1 1s-.45 1-1 1H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h6c.55 0 1 .45 1 1s-.45 1-1 1Zm6.86 3.86 2.79 2.79c.19.19.19.51-.01.7l-2.79 2.79c-.31.32-.85.1-.85-.35V13h-7c-.55 0-1-.45-1-1s.45-1 1-1h7V9.21c0-.45.54-.67.86-.35Z"
    />
  </Icon>
);
export default SvgLogoutIcon;
SvgLogoutIcon.displayName = 'SvgLogoutIcon';
