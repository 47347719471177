import { FC, createContext } from 'react';

import { Session } from 'next-auth';
import { SWRConfig } from 'swr';

import { useApplication } from '@common/application';
import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createOverloadMiddleware, useOverloadNotification, OverloadErrorPage } from '@overload/index';

import { OpenAPI } from '../client/OpenAPI';
import { Label } from '../client/types';

export type AuthenticationContext = {
  label: Label;
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  customerId: number | null;
  accountId: number | null;
  isAuthenticated: boolean;
};

export const DigitalCoreContext = createContext({} as AuthenticationContext);

type Props = {
  customerContext: {
    customerId?: string;
    accessToken?: string;
    accountId?: number;
  };
  label: Label;
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  user?: Session['user'] | null;
  idToken?: Session['idToken'] | null;
};

const DEFAULT_SWR_CONFIG = {
  dedupingInterval: 5_000,
  loadingTimeout: 3_000,
  errorRetryCount: 5,
  errorRetryInterval: 5_000,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const DigitalCoreProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  customerContext,
  label,
  businessUnit,
  user,
  idToken,
}) => {
  const { locale } = useApplication();
  const { showOverloadNotification, isInCooldown, isOverloaded } = useOverloadNotification();

  const { accountId, customerId: contextCustomerId } = customerContext;
  const customerId = user?.customerId ?? contextCustomerId;

  OpenAPI.TOKEN = idToken ?? undefined;
  OpenAPI.HEADERS['accept-language'] = locale;

  const overloadMiddleware = createOverloadMiddleware(showOverloadNotification, isInCooldown);

  return (
    <DigitalCoreContext.Provider
      value={{
        label: label,
        businessUnit: businessUnit,
        customerId: customerId ? Number(customerId) : null,
        accountId: accountId ?? null,
        isAuthenticated: Boolean(idToken && customerId),
      }}>
      <SWRConfig
        value={{
          ...DEFAULT_SWR_CONFIG,
          use: [overloadMiddleware],
        }}>
        {isOverloaded ? <OverloadErrorPage /> : children}
      </SWRConfig>
    </DigitalCoreContext.Provider>
  );
};

export default DigitalCoreProvider;
