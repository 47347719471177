import { FC } from 'react';

import { StackContainer } from '@custom-components/selfservice';
import { Placeholder } from '@sitecore/common';
import { Stack, SkipToMain, Grid, Stretch } from '@sparky';

const DefaultLayout: FC = () => (
  <>
    <Placeholder name="jss-meta" />

    <StackContainer>
      <>
        <SkipToMain.SkipLink />
        <header>
          <Placeholder name="jss-header" />
          <Placeholder name="jss-navigationbar" />
          <Placeholder name="jss-notifications" />
        </header>

        <Stack.Item grow>
          <Stretch>
            <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" gridTemplateAreas="none">
              <SkipToMain.Main>
                <Placeholder name="jss-main" />
              </SkipToMain.Main>
            </Grid>
          </Stretch>
        </Stack.Item>

        <footer>
          <Placeholder name="jss-footer" />
        </footer>
      </>
    </StackContainer>
  </>
);

export default DefaultLayout;
