import { isValid } from '@common/date';
import { capitalizeFirstLetter } from '@common/string';
import { useDayPicker, useNavigation } from 'react-day-picker';

import { YearsDropdown } from './YearsDropdown';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';
import { Icon } from '../../../icons/IconWrapper';
import { useI18nTranslations } from '../../../providers/i18n';
import { styled } from '../../../stitches.config';
import { Box } from '../../Box/Box';
import { Divider } from '../../Divider/Divider';
import { Grid } from '../../Grid/Grid';
import { IconButton } from '../../IconButton/IconButton';
import { Stack } from '../../Stack/Stack';
import { Text } from '../../Text/Text';

const DisabledButton = styled('div', {
  width: '$targetMinWidth',
  height: '$targetMinHeight',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  [`& ${Icon}`]: { fill: '$iconSecondary' },
});

export const Caption = ({ displayMonth }: { displayMonth: Date }) => {
  const {
    locale,
    formatters: { formatMonthCaption },
    numberOfMonths,
    fromDate,
    toDate,
    toYear,
    mode,
  } = useDayPicker();
  const { nextMonth: i18nNextMonth, previousMonth: i18nPreviousMonth } = useI18nTranslations();
  const { goToMonth, nextMonth, previousMonth, currentMonth, displayMonths } = useNavigation();
  const hasYearsDropdown = numberOfMonths < 2 && mode !== 'range' && isValid(fromDate) && (isValid(toDate) || toYear);

  const isFirstDisplayMonth = numberOfMonths > 1 ? displayMonths[0].getMonth() === displayMonth.getMonth() : true;
  const isLastDisplayMonth =
    numberOfMonths > 1 ? displayMonths[displayMonths.length - 1].getMonth() === displayMonth.getMonth() : true;

  return (
    <>
      <Box padding="6">
        <Grid gridTemplateColumns={hasYearsDropdown ? '1fr max-content' : '1fr'} gap="10">
          <Grid.Item>
            <Stack gap="1" direction="row" alignY="center" alignX="justify">
              {isFirstDisplayMonth && previousMonth ? (
                <IconButton label={i18nPreviousMonth} onClick={() => goToMonth(previousMonth)}>
                  <ChevronLeftIcon />
                </IconButton>
              ) : (
                <DisabledButton>{isFirstDisplayMonth ? <ChevronLeftIcon /> : null}</DisabledButton>
              )}
              <Text size="BodyL" weight="bold">
                {capitalizeFirstLetter(formatMonthCaption(displayMonth, { locale }) as string)}
                {numberOfMonths > 1 ? ` ${currentMonth.getFullYear()}` : ''}
              </Text>
              {isLastDisplayMonth && nextMonth ? (
                <IconButton label={i18nNextMonth} onClick={() => goToMonth(nextMonth)}>
                  <ChevronRightIcon />
                </IconButton>
              ) : (
                <DisabledButton>{isLastDisplayMonth ? <ChevronRightIcon /> : null}</DisabledButton>
              )}
            </Stack>
          </Grid.Item>
          {hasYearsDropdown && (
            <Grid.Item alignSelf="end">
              <YearsDropdown />
            </Grid.Item>
          )}
        </Grid>
      </Box>
      <Divider />
    </>
  );
};
