import { useRef } from 'react';

import { format } from 'date-fns/format';
import { useDayPicker, useDayRender } from 'react-day-picker';

import { DayButton } from '../Datepicker.styles';

export const Day = ({ displayMonth, date }: { displayMonth: Date; date: Date }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { locale } = useDayPicker();
  const { isHidden, isButton, buttonProps, divProps, activeModifiers } = useDayRender(date, displayMonth, buttonRef);

  if (isHidden) {
    return <div aria-hidden="true"></div>;
  }

  if (!isButton) {
    return <div {...divProps} />;
  }

  return (
    <DayButton
      name="day"
      ref={buttonRef}
      isDisabled={activeModifiers.disabled}
      isToday={activeModifiers.today}
      isRangeBound={activeModifiers.range_start || activeModifiers.range_end}
      isRangeMiddle={activeModifiers.range_middle}
      {...buttonProps}
      role="button"
      type="button"
      aria-label={format(date, 'd MMMM y', { locale })}
    />
  );
};
