export const handleDownload = (data: string | Blob, filename: string) => {
  if ('msSaveOrOpenBlob' in window.navigator) {
    // @ts-ignore msSaveOrOpenBlob exists in (older) Edge/IE
    window.navigator.msSaveOrOpenBlob(data, filename);
  } else {
    const dataToDownload = typeof data === 'string' ? data : URL.createObjectURL(data);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = dataToDownload;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(dataToDownload);
    }, 100); // use setTimeout to force next render thread
  }
};
