import React, { FC, ReactNode } from 'react';

import * as Collapsible from '@radix-ui/react-collapsible';

import { ChevronDownIcon } from '../../icons';
import { Icon } from '../../icons';
import { keyframes, styled } from '../../stitches.config';
import { ButtonOverlay } from '../ButtonOverlay/ButtonOverlay';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
});

const StyledExpandable = styled(Collapsible.Root, {
  display: 'flex',
  flexDirection: 'column',
});

export const IndicatorButton = styled(ButtonOverlay.Button, {
  /**
   * Visually place Indicator at the end of the trigger
   */
  order: 1,

  /**
   * Custom button styles
   */
  borderRadius: '$round',

  variants: {
    isTransparent: {
      true: {
        backgroundColor: 'transparent',
        $$buttonColor: '$colors$expandableTransparentIndicatorColor',
      },
      false: {
        backgroundColor: '$expandableIndicatorBackground',
        $$buttonColor: '$colors$expandableDefaultIndicatorColor',
      },
    },
  },

  [`& ${Icon}`]: {
    fill: '$$buttonColor',

    '@safeMotion': {
      transition: '$easeMedium',
    },
  },
  '&[data-state=open]': {
    [`& ${Icon}`]: {
      '[data-state=open] &': {
        transform: 'rotate(180deg)',
      },
    },
  },
});

const TriggerHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledContent = styled(Collapsible.Content, {
  overflow: 'hidden',

  '@safeMotion': {
    '[data-state=open] > &': { animation: `${slideDown} $transitions$easeMedium` },
    '[data-state=closed] > &': { animation: `${slideUp} $transitions$easeMedium` },
  },
});

const ChildrenContainer = styled('div', {
  flex: 'auto',
  marginRight: '$space$3',
});

interface CompoundExpandableProps {
  Content: typeof Content;
  Trigger: typeof Trigger;
}

interface TriggerBasicProps {
  className?: never;
  isTransparent?: boolean;
}

type TriggerLabelProps =
  | {
      label: string;
      'aria-labelledby'?: never;
    }
  | {
      'aria-labelledby': string;
      label?: never;
    };

type TriggerProps = TriggerBasicProps & TriggerLabelProps;

type StateProps =
  | {
      isOpen?: never;
      setOpen?: never;
      defaultOpen?: boolean;
    }
  | {
      /** Controlled state of the expandable */
      isOpen: boolean;
      /** State setter for the controlled state. Required in order to use built-in Expandable controls. */
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
      defaultOpen?: never;
    };
type ExpandableProps = {
  children: ReactNode;
} & StateProps;

export const Expandable: FC<ExpandableProps> & CompoundExpandableProps = ({
  children,
  setOpen,
  isOpen,
  defaultOpen,
}) => {
  return (
    <StyledExpandable defaultOpen={defaultOpen} onOpenChange={setOpen} open={isOpen}>
      {children}
    </StyledExpandable>
  );
};

export const Content: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

export const Trigger: FC<React.PropsWithChildren<TriggerProps>> = ({
  label,
  'aria-labelledby': ariaLabelledby,
  children,
  className,
  isTransparent = false,
}) => {
  return (
    <ButtonOverlay>
      <TriggerHeader className={className}>
        <Collapsible.Trigger asChild>
          <IndicatorButton aria-labelledby={ariaLabelledby} isTransparent={isTransparent}>
            {label ? <VisuallyHidden>{label}</VisuallyHidden> : null}
            <ChevronDownIcon />
          </IndicatorButton>
        </Collapsible.Trigger>
        <ChildrenContainer>{children}</ChildrenContainer>
      </TriggerHeader>
    </ButtonOverlay>
  );
};

Expandable.Content = Content;
Expandable.Trigger = Trigger;

IndicatorButton.displayName = 'styled(IndicatorButton)';
TriggerHeader.displayName = 'TriggerHeader';
StyledExpandable.displayName = 'styled(Collapsible.Root)';
StyledContent.displayName = 'styled(Collapsible.Content)';
ChildrenContainer.displayName = 'ChildrenContainer';
Expandable.displayName = 'Expandable';
Expandable.Content.displayName = 'Expandable.Content';
Expandable.Trigger.displayName = 'Expandable.Trigger';
