import React from 'react';

import { startOfMonth, startOfYear } from '@common/date';
import { isValid } from 'date-fns';
import { setYear } from 'date-fns/setYear';
import { useDayPicker, useNavigation } from 'react-day-picker';

import { InputSelect } from '../../InputSelect/InputSelect';

export const YearsDropdown: React.FC = () => {
  const { goToMonth, currentMonth: displayMonth } = useNavigation();
  const {
    fromDate,
    toDate,
    toYear: toYearProp,
    locale,
    formatters: { formatYearCaption },
    labels: { labelYearDropdown },
  } = useDayPicker();

  // Dropdown should appear only when both fromDate and toDate are set and valid
  if (!fromDate || !isValid(fromDate)) return null;
  if (!toYearProp && !isValid(toDate)) return null;

  const fromYear = fromDate.getFullYear();
  const toYear = toYearProp ?? toDate?.getFullYear();
  const years: { label: string; value: string }[] = [];

  if (!toYear) return null;

  for (let year = fromYear; year <= toYear; year++) {
    const currentYear = setYear(startOfYear(new Date()), year);

    years.push({
      label: `${formatYearCaption(currentYear, { locale })}`,
      value: `${currentYear.getFullYear()}`,
    });
  }

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
    const newMonth = setYear(startOfMonth(displayMonth), Number(e.target.value));
    goToMonth(newMonth);
  };

  return (
    <InputSelect
      name="years"
      type="filter"
      label="Years"
      filteredElement="Calendar"
      visuallyHideLabel
      aria-label={labelYearDropdown()}
      onChange={handleChange}
      defaultValue={displayMonth.getFullYear().toString()}
      options={years}
    />
  );
};
