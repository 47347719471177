// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgCompareBarsIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M21 19h-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1s.45 1 1 1h5v14h-2V8c0-.55-.45-1-1-1H9c-.55 0-1 .45-1 1s.45 1 1 1h5v10h-2v-7c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v7H3c-.55 0-1 .45-1 1s.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1Z" />
  </Icon>
);
export default SvgCompareBarsIcon;
SvgCompareBarsIcon.displayName = 'SvgCompareBarsIcon';
