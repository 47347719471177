import { Level, Context } from './types';

type Levels = Record<Level, number>;

export const LEVELS: Levels = {
  trace: 10,
  debug: 20,
  info: 30,
  warn: 40,
  error: 50,
  fatal: 60,
};

export const extractError = (context?: Context): [Error | undefined, unknown] => {
  if (context?.error instanceof Error) {
    const { error, ...ctx } = context;
    return [context.error, ctx];
  } else if (context instanceof Error) {
    return [context, undefined];
  } else {
    return [undefined, context];
  }
};
