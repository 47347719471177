import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

/**
 * ProgressIndicator specific tokens
 */

/** Colors - Format: [component][action][property][tone?][state?] */
export const enecoProgressIndicatorTokensColors = {
  progressIndicatorItemDefault: eneco.neutralColors.neutral400,
  progressIndicatorItemDisabled: eneco.neutralColors.neutral300,
};

export const oxxioProgressIndicatorTokensColors = {
  progressIndicatorItemDefault: oxxio.neutralColors.neutral400,
  progressIndicatorItemDisabled: oxxio.neutralColors.neutral200,
};

export const woonenergieProgressIndicatorTokensColors = {
  progressIndicatorItemDefault: woonenergie.neutralColors.neutral400,
  progressIndicatorItemDisabled: woonenergie.neutralColors.neutral200,
};
