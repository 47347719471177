import { SeverityLevel } from '@microsoft/applicationinsights-common';

export const SEVERITY_LEVELS = {
  fatal: SeverityLevel.Critical,
  error: SeverityLevel.Error,
  warn: SeverityLevel.Warning,
  info: SeverityLevel.Information,
  debug: SeverityLevel.Verbose,
  trace: SeverityLevel.Verbose,
  dev: SeverityLevel.Verbose,
};

type SerializedError = {
  type: string;
  message: string;
  trace: string;
  [key: string]: unknown;
};

// Only meant to be used from  `trackTrace` (not `trackException`)
export const serializeError = (error?: Error) => {
  if (!error) return undefined;
  const obj: SerializedError = {
    type: error.constructor.name,
    message: error.message,
    // @ts-ignore Non-standard property, and rather useless for minified code
    trace: error.trace,
  };
  for (const key in error) {
    if (obj[key] === undefined) {
      obj[key] = error[key as keyof typeof error];
    }
  }
  return obj;
};
