/**
 * We're polyfilling matchMedia's addEventListener and removeEventListener for Safari (and thus iOS) < 14.
 *
 * See browser compatibility of both here:
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener#browser_compatibility
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/change_event#browser_compatibility
 */

export const matchMedia = (query: string) => {
  const matchMedia = window.matchMedia(query);

  // check if addEventListener is in matchMedia, otherwise polyfill it
  if (!matchMedia.addEventListener) {
    matchMedia.addEventListener = (_type: string, listener: (event: Event) => unknown) => {
      return matchMedia.addListener(listener);
    };
  }

  //check if removeEventListener is in matchMedia, otherwise polyfill it
  if (!matchMedia.removeEventListener) {
    matchMedia.removeEventListener = (_type: string, listener: (event: Event) => unknown) => {
      return matchMedia.removeListener(listener);
    };
  }

  return matchMedia;
};
