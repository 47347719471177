// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSunIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M4 12a1 1 0 0 1-1 1H2a1 1 0 0 1 0-2h1a1 1 0 0 1 1 1Zm18-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2ZM12 4a1 1 0 0 0 1-1V2a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1ZM4.93 17.66l-.71.7a1.004 1.004 0 0 0 1.42 1.42l.7-.71a1 1 0 0 0-1.41-1.41ZM19.07 6.34l.71-.7a1.005 1.005 0 0 0-.71-1.714 1.004 1.004 0 0 0-.71.294l-.7.71a1 1 0 0 0 1.41 1.41Zm-14.14 0a1 1 0 0 0 1.41-1.41l-.7-.71a1.004 1.004 0 0 0-1.42 1.42l.71.7ZM18 12a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-3-.72a.51.51 0 0 0-.5-.28h-1.81L13 8.56a.5.5 0 0 0-.31-.56.5.5 0 0 0-.59.16l-3 4a.5.5 0 0 0 .4.8h1.81L11 15.44a.5.5 0 0 0 .31.52.429.429 0 0 0 .19 0 .511.511 0 0 0 .4-.2l3-4a.51.51 0 0 0 .1-.48Zm4.12 6.38a1 1 0 0 0-1.41 1.41l.7.71a1.004 1.004 0 1 0 1.42-1.42l-.71-.7ZM12 20a1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Z"
    />
  </Icon>
);
export default SvgSunIcon;
SvgSunIcon.displayName = 'SvgSunIcon';
