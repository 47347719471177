// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgWarmthIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M5.887 20.46a.999.999 0 0 1-.88.54.927.927 0 0 1-.449-.11 5.308 5.308 0 0 1-2.547-4.892 8.75 8.75 0 0 1 1.638-4.511 7.098 7.098 0 0 0 1.359-3.491A3.385 3.385 0 0 0 3.55 4.885a1 1 0 0 1 .909-1.78 5.309 5.309 0 0 1 2.547 4.891 8.75 8.75 0 0 1-1.638 4.511 7.097 7.097 0 0 0-1.359 3.491 3.385 3.385 0 0 0 1.458 3.111 1 1 0 0 1 .42 1.35Zm4.575-1.35a3.38 3.38 0 0 1-1.459-3.112 7.097 7.097 0 0 1 1.359-3.49A8.751 8.751 0 0 0 12 7.995a5.319 5.319 0 0 0-2.547-4.892.998.998 0 0 0-1.398 1.193 1 1 0 0 0 .489.588 3.38 3.38 0 0 1 1.458 3.111 7.098 7.098 0 0 1-1.358 3.491 8.75 8.75 0 0 0-1.638 4.511 5.318 5.318 0 0 0 2.547 4.892.927.927 0 0 0 .45.11.997.997 0 0 0 .878-.54 1.002 1.002 0 0 0-.42-1.35Zm4.994 0a3.38 3.38 0 0 1-1.458-3.112 7.098 7.098 0 0 1 1.358-3.49 8.75 8.75 0 0 0 1.638-4.512 5.317 5.317 0 0 0-2.547-4.892.997.997 0 0 0-1.398 1.193 1 1 0 0 0 .49.588 3.38 3.38 0 0 1 1.458 3.111 7.097 7.097 0 0 1-1.359 3.491A8.751 8.751 0 0 0 12 15.998a5.32 5.32 0 0 0 2.547 4.892.927.927 0 0 0 .45.11.997.997 0 0 0 .879-.54 1.002 1.002 0 0 0-.42-1.35Zm4.994 0a3.38 3.38 0 0 1-1.458-3.112 7.097 7.097 0 0 1 1.358-3.49 8.75 8.75 0 0 0 1.639-4.512 5.319 5.319 0 0 0-2.547-4.892.998.998 0 0 0-1.398 1.193 1 1 0 0 0 .489.588 3.38 3.38 0 0 1 1.458 3.111 7.098 7.098 0 0 1-1.358 3.491 8.751 8.751 0 0 0-1.639 4.511 5.318 5.318 0 0 0 2.547 4.892.927.927 0 0 0 .45.11.997.997 0 0 0 .879-.54 1.002 1.002 0 0 0-.42-1.35Z"
    />
  </Icon>
);
export default SvgWarmthIcon;
SvgWarmthIcon.displayName = 'SvgWarmthIcon';
