import React, { ReactNode, SyntheticEvent, useCallback, useState } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { CloseIcon } from '../../icons';
import { useI18nTranslation } from '../../providers/i18n';
import { styled, keyframes } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { Box } from '../Box/Box';
import { Heading } from '../Heading/Heading';
import { IconButton } from '../IconButton/IconButton';
import { PageGrid } from '../PageGrid/PageGrid';

type StateProps =
  | {
      isOpen?: never;
      setOpen?: never;
      /** Interactable element that will trigger the opening of the Dialog */
      trigger: ReactNode;
    }
  | {
      /** Controlled state of the modal */
      isOpen: boolean;
      /** State setter for the controlled state. Required in order to use built-in Dialog controls. */
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
      /** Interactable element that will trigger the opening of the Dialog */
      trigger?: ReactNode;
    };

export type SharedProps = {
  /** Description that will be announced to screenreaders and be visible in the Dialog */
  description?: ReactNode;
  /** Function that is triggerered when the user closes the dialog */
  onClose?(event?: SyntheticEvent): void;
  /** Title that will be shown as the header of the Dialog. */
  title?: ReactNode;
} & StateProps;

type Props = SharedProps & DialogVariants;

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeOut = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

const popIn = keyframes({
  '0%': { transform: 'translateY(10%)', opacity: 0 },
  '90%': { opacity: 1.0 },
  '100%': { opacity: 1 },
});

const popOut = keyframes({
  '0%': { opacity: 1 },
  '15%': { opacity: 0.9 },
  '100%': { transform: 'translateY(10%)', opacity: 0 },
});

const popInBottom = keyframes({
  '0%': { transform: 'translateY(10%)', opacity: 0 },
  '90%': { opacity: 1.0 },
  '100%': { transform: 'translateY(0%)', opacity: 1 },
});

const popOutBottom = keyframes({
  '0%': { transform: 'translateY(0%)', opacity: 1 },
  '15%': { opacity: 0.9 },
  '100%': { transform: 'translateY(10%)', opacity: 0 },
});

export const overlayStyles = {
  $$fadeDuration: '0.01ms',
  zIndex: 2,
  '@safeMotion': {
    $$fadeDuration: '350ms',
  },
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',

  backgroundColor: '$backgroundScrim',

  '@supports (backdrop-filter: initial)': {
    'backdrop-filter': 'grayscale(30%) blur(2px)',
  },

  animationFillMode: 'forwards',

  '&[data-state="open"]': {
    animation: `${fadeIn} $$fadeDuration`,
  },
  '&[data-state="closed"]': {
    animation: `${fadeOut} $$fadeDuration`,
  },
};

export const StyledOverlay = styled(DialogPrimitive.Overlay, overlayStyles);

export const StyledPageGrid = styled(PageGrid, {
  height: '100vh',
  alignItems: 'center',
});

const maxModalHeight = '85vh';

export const contentStyles = {
  $$popInAnimation: popInBottom,
  $$popOutAnimation: popOutBottom,
  $$popDuration: '0.01ms',

  backgroundColor: '$backgroundPrimary',
  borderRadius: '$l $l $none $none',
  bottom: 0,
  boxShadow: '$s',
  display: 'flex',
  flexDirection: 'column',
  left: 0,
  maxHeight: maxModalHeight,
  position: 'fixed',
  width: '100vw',
  zIndex: 3,

  '@md': {
    $$popInAnimation: popIn,
    $$popOutAnimation: popOut,

    borderRadius: '$l',
    gridColumn: '3/11',
    minHeight: '320px',
    position: 'static',
    width: 'auto',
  },

  '@safeMotion': {
    $$popDuration: '250ms',
  },

  '&:focus': {
    outline: 'none',
  },

  '&[data-state="open"]': {
    animation: `$$popInAnimation $$popDuration ease-out`,
  },

  '&[data-state="closed"]': {
    animation: `$$popOutAnimation $$popDuration ease-in`,
  },

  variants: {
    fullscreen: {
      true: {
        '@mdMax': {
          borderRadius: '$none',
          height: '100vh',
          /**
           * safe-area-inset-* values defined in the specification are used to help ensure content is visible
           * even to viewers using non‑rectangular displays. In particular the safe-area-inset-top used
           * to make sure the content doesn't overlap with the camera block and the status bar on iOS devices.
           * https://developer.mozilla.org/en-US/docs/Web/CSS/env
           * For non ios devices, the value is 0.
           */
          paddingTop: 'env(safe-area-inset-top)',
          maxHeight: '100vh',
        },
      },
    },
    /** The width of the modal, only affects viewports above @lg  */
    width: {
      regular: {
        '@lg': {
          gridColumn: '4/10',
        },
      },
      wide: {
        '@lg': {
          gridColumn: '3/11',
        },
      },
    },
  },

  defaultVariants: {
    width: 'regular',
  },
};

export const StyledContent = styled(DialogPrimitive.Content, contentStyles, {
  [`& > :first-child`]: {
    marginLeft: 'auto',
  },
});

type DialogVariants = TransformStitchesToSparky<typeof StyledContent>;

export const ScrollContainer = styled(Box, {
  flex: '1 1 auto',
});

export const Dialog = (({ children, description, fullscreen, isOpen, onClose, setOpen, title, trigger, width }) => {
  const closeText = useI18nTranslation('close');
  const [hasHandledClose, setHasHandledClose] = useState(false);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen?.(isOpen);
      if (isOpen) setHasHandledClose(false);
      if (!isOpen && !hasHandledClose) onClose?.();
    },
    [hasHandledClose, onClose, setOpen],
  );

  const onCloseHandler = useCallback(
    (event: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement, Event>) => {
      onClose?.(event);
      setHasHandledClose(true);
    },
    [onClose, setHasHandledClose],
  );

  return (
    <DialogPrimitive.Root onOpenChange={onOpenChange} open={isOpen}>
      {trigger ? <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger> : null}
      <DialogPrimitive.Portal>
        <StyledOverlay>
          <StyledPageGrid>
            <StyledContent fullscreen={fullscreen} width={width}>
              <Box paddingRight="2" paddingTop="2">
                <DialogPrimitive.Close asChild>
                  <IconButton label={closeText} onClick={onCloseHandler}>
                    <CloseIcon size="small" />
                  </IconButton>
                </DialogPrimitive.Close>
              </Box>
              <ScrollContainer paddingX="6" paddingBottom="6" overflow="auto">
                {title && (
                  <Box paddingBottom={{ initial: '2', md: '4' }}>
                    <DialogPrimitive.Title asChild>
                      {typeof title === 'string' ? (
                        <Heading as="h2" size="S">
                          {title}
                        </Heading>
                      ) : (
                        title
                      )}
                    </DialogPrimitive.Title>
                  </Box>
                )}
                {description && (
                  <DialogPrimitive.Description asChild>
                    <Box paddingBottom={{ initial: '4', md: '5' }}>{description}</Box>
                  </DialogPrimitive.Description>
                )}
                {children}
              </ScrollContainer>
            </StyledContent>
          </StyledPageGrid>
        </StyledOverlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}) as DialogProps;

interface DialogProps extends React.FC<React.PropsWithChildren<Props>> {
  Close: typeof DialogPrimitive.Close;
}

Dialog.Close = DialogPrimitive.Close;
Dialog.Close.displayName = 'Dialog.Close';
StyledOverlay.displayName = 'styled(Overlay)';
StyledPageGrid.displayName = 'styled(PageGrid)';
StyledContent.displayName = 'styled(DialogContent)';
ScrollContainer.displayName = 'styled(ScrollContainer)';

StyledOverlay.toString = () => '.dialog-overlay';
