import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';
import { keyframes, styled } from '../../stitches.config';

const OpacityFadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const OpacityFadeOut = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

export const TransitionOpacity = styled('div', {
  $$animationDuration: '0.01ms',
  '@safeMotion': {
    $$animationDuration: '.3s',
  },
  opacity: '1',
  variants: {
    in: {
      true: {
        animation: `${OpacityFadeIn} $$animationDuration ease-in forwards`,
        opacity: 1,
      },
      false: {
        animation: `${OpacityFadeOut} $$animationDuration ease-in forwards`,
        opacity: 0,
      },
    },
  },
});

const SlideFromRight = keyframes({
  '0%': {
    transform: 'translateX($$animationScope)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
});

const SlideFromRightReverse = keyframes({
  '0%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
  '100%': {
    transform: 'translateX($$animationScope)',
    opacity: 0,
  },
});

const StyledTransitionSlide = styled('div', {
  $$animationDuration: '0.01ms',
  $$animationScope: '100%',
  '@safeMotion': {
    $$animationDuration: '.3s',
  },
  opacity: '0',
  display: 'flex',
  flexDirection: 'column',
  variants: {
    isEntering: {
      true: {
        animation: `${SlideFromRight} $$animationDuration ease-in forwards`,
      },
      false: {
        animation: `${SlideFromRightReverse} $$animationDuration ease-in forwards`,
      },
    },
    absolute: {
      true: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: '$backgroundPrimary',
      },
    },
  },
});

interface TransitionSlideProps {
  isEntering?: boolean;
  isAbsolute?: boolean;
  onEnterFinished?: () => void;
  onLeaveFinished?: () => void;
}
export const TransitionSlide: FC<PropsWithChildren<TransitionSlideProps>> = ({
  isEntering,
  isAbsolute,
  onEnterFinished,
  onLeaveFinished,
  children,
}) => {
  return (
    <StyledTransitionSlide
      isEntering={isEntering}
      absolute={isAbsolute}
      onAnimationEnd={isEntering ? onEnterFinished : onLeaveFinished}>
      {children}
    </StyledTransitionSlide>
  );
};

const Expand = keyframes({
  '0%': { display: 'initial', overflow: 'hidden', maxHeight: 'var(--transition-height-end)' },
  '99%': {
    maxHeight: 'var(--transition-height-start)',
    overflow: 'hidden',
    display: 'initial',
  },
  '100%': {
    overflow: 'initial',
    maxHeight: 'none',
  },
});

const Collapse = keyframes({
  '0%': {
    overflow: 'initial',
    maxHeight: 'none',
  },
  '1%': { display: 'initial', overflow: 'hidden', maxHeight: 'var(--transition-height-start)' },
  '99%': { display: 'initial', overflow: 'hidden', maxHeight: 'var(--transition-height-end)' },
  '100%': { display: 'initial', overflow: 'hidden', maxHeight: 'var(--transition-height-end)' },
});

export const StyledTransitionHeight = styled('div', {
  $$animationDuration: '0.01ms',
  '@safeMotion': {
    $$animationDuration: '.3s',
  },
  /** TODO Add support for breakpoint values */
  '@lg': {
    maxHeight: 'var(--transition-height-start)',
  },
  variants: {
    hideWhenCollapsed: {
      true: {},
    },
    in: {
      true: {
        animation: `${Expand} $$animationDuration ease-in-out forwards`,
      },
      false: {
        animation: `${Collapse} $$animationDuration ease-in-out forwards`,
      },
    },
    removeMargin: {
      true: {},
    },
    reverse: { true: {} },
  },
  compoundVariants: [
    {
      removeMargin: true,
      in: true,
      css: {
        marginTop: '0 !important',
        marginLeft: '0 !important',
      },
    },
    {
      removeMargin: true,
      in: false,
      css: {
        marginTop: '0 !important',
        marginLeft: '0 !important',
      },
    },
  ],
});

const TransitionHeightChild: FC<PropsWithChildren<TransitionHeightProps>> = ({
  removeMargin,
  hideWhenCollapsed,
  startHeight,
  endHeight,
  in: inProp,
  children,
}) => {
  const transitionRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (!transitionRef.current) {
      return;
    }

    const style = transitionRef.current.style;
    const offsetHeight = transitionRef.current.scrollHeight ? `${transitionRef.current.scrollHeight}px` : undefined;

    style.setProperty('--transition-height-start', startHeight ?? offsetHeight ?? 'auto');
    style.setProperty('--transition-height-end', endHeight ?? '0');
  }, [endHeight, inProp, startHeight]);

  return (
    <StyledTransitionHeight
      ref={transitionRef}
      in={inProp}
      removeMargin={removeMargin}
      hideWhenCollapsed={hideWhenCollapsed}>
      {children}
    </StyledTransitionHeight>
  );
};

export const TransitionHeight: FC<PropsWithChildren<TransitionHeightProps>> = props => {
  const [showChild, setShowChild] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return <div>{props.children}</div>;
  }

  return <TransitionHeightChild {...props} />;
};

interface TransitionHeightProps {
  removeMargin?: boolean;
  hideWhenCollapsed?: boolean;
  startHeight?: string;
  endHeight?: string;
  in?: boolean;
}
