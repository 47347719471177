// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgWarmthWarmWaterIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12.258 5.667c.386.669.69 1.573.69 2.763 0 1.594-.78 2.893-1.47 4.04-.627 1.039-1.218 2.024-1.218 3.126 0 1.313.51 2.073.893 2.459.214.212.387.308.406.317l-.003.003.012.006a.893.893 0 0 1-.412 1.684h-.018a.874.874 0 0 1-.394-.09l-.015-.008c-.211-.118-2.27-1.275-2.27-4.38 0-1.595.78-2.902 1.47-4.049l.005-.008c.625-1.045 1.213-2.028 1.213-3.118 0-.736-.16-1.299-.369-1.718-.373-.742-.896-1.033-.93-1.05a.904.904 0 0 1-.335-.313.9.9 0 0 1-.05-.905.892.892 0 0 1 1.191-.394l.015.008c.13.074.969.545 1.588 1.627ZM7.457 19.58a.893.893 0 0 1-1.183.395c-.09-.045-2.284-1.183-2.284-4.38V15.587c0-1.588.773-2.883 1.46-4.034l.01-.015c.625-1.037 1.215-2.02 1.217-3.118C6.673 6.368 5.423 5.67 5.37 5.644a.908.908 0 0 1-.475-.805.872.872 0 0 1 .098-.413.886.886 0 0 1 1.183-.394c.09.045 2.284 1.182 2.284 4.389 0 .198-.012.392-.035.582-.157 1.327-.824 2.444-1.425 3.452l-.01.015c-.625 1.037-1.216 2.021-1.217 3.122.002 2.056 1.254 2.754 1.307 2.78a.908.908 0 0 1 .377 1.21ZM18.313 3.942c.134 0 .268.063.349.17C18.904 4.409 21 7.069 21 8.422a2.695 2.695 0 0 1-2.687 2.687 2.695 2.695 0 0 1-2.687-2.687c0-1.353 2.095-4.013 2.337-4.308a.441.441 0 0 1 .35-.17Zm-.896 5.733c0 .296.242.537.537.537 1.183 0 2.15-.967 2.15-2.15a.54.54 0 0 0-.537-.537.539.539 0 0 0-.538.538c0 .59-.483 1.075-1.075 1.075a.539.539 0 0 0-.537.537Zm-1.792 10.39a.874.874 0 0 1-.394-.09c-.09-.044-2.284-1.182-2.284-4.38 0-1.594.78-2.893 1.47-4.039a.891.891 0 0 1 1.226-.305c.421.251.565.807.305 1.228-.627 1.039-1.218 2.024-1.218 3.126 0 2.06 1.254 2.758 1.308 2.785a.893.893 0 0 1-.412 1.684v-.009Zm4.085-.09c.125.063.26.09.394.09h.009a.893.893 0 0 0 .412-1.684c-.054-.027-1.308-.725-1.308-2.785 0-.878.377-1.666.78-2.383a.895.895 0 0 0-1.559-.878c-.502.887-1.012 1.97-1.012 3.26 0 3.198 2.194 4.336 2.284 4.38Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgWarmthWarmWaterIcon;
SvgWarmthWarmWaterIcon.displayName = 'SvgWarmthWarmWaterIcon';
