// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgColdWaterIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M12.78 4.37A.99.99 0 0 0 12 4c-.3 0-.59.14-.78.37C10.69 5.04 6 10.97 6 14c0 3.31 2.69 6 6 6s6-2.69 6-6c0-3.03-4.69-8.96-5.22-9.63Zm2.19 10.65a.6.6 0 1 1-.6 1.04l-1.77-1.02V17c0 .33-.27.6-.6.6-.33 0-.6-.27-.6-.6v-1.96l-1.77 1.02a.6.6 0 1 1-.6-1.04L10.8 14l-1.77-1.02a.595.595 0 0 1-.22-.82c.17-.29.53-.39.82-.22l1.77 1.02V11c0-.33.27-.6.6-.6.33 0 .6.27.6.6v1.96l1.77-1.02c.29-.17.65-.07.82.22.17.29.07.65-.22.82L13.2 14l1.77 1.02Z" />
  </Icon>
);
export default SvgColdWaterIcon;
SvgColdWaterIcon.displayName = 'SvgColdWaterIcon';
