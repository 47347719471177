/**
 * This file is the entry to load a theme, by default the eneco theme is loaded.
 * this file can be replaced using the executor option fileReplacements in the project.json
 * For example use the following to load a oxxio theme:
 *  
    "build": {
      "executor": "@nx/next:build",      
      "options": {        
        "fileReplacements": [
          {
            "replace": "libs/sparky/src/themes/index",
            "with": "libs/sparky/src/themes/oxxio/index"
          }
        ]
      },
    }
 */
export * from './eneco';
