import React, { createContext, ReactNode } from 'react';

import { NbaDataType } from './types';

type NbaDataProviderProps = {
  children: ReactNode;
  data?: NbaDataType;
};

export const NbaDataContext = createContext<NbaDataType | undefined>(undefined);

export const NbaDataProvider: React.FC<NbaDataProviderProps> = ({ children, data }) => {
  return <NbaDataContext.Provider value={data}>{children}</NbaDataContext.Provider>;
};
