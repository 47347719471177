import { Item } from '@radix-ui/react-radio-group';

import { styled } from '../../stitches.config';

/**
 * An item that can be checked. An input will also render when used within a form to ensure events propagate
 * correctly. It doesn't contain focus or hover styles so you need to make sure those are added when you
 * use this component!
 */
export const RadioBase = styled(Item, {
  height: '2rem',
  width: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
  borderRadius: '$round',
  border: '$borderWidths$s solid $formBorderDefault',
  backgroundColor: '$controlsKnob',
  cursor: 'pointer',
  minWidth: '2rem',
});
