import React, { forwardRef, ReactNode } from 'react';

import reactToText from 'react-to-text';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { StyledLink } from '../TextLink/TextLink';

const StyledTag = styled(StyledLink, {
  //Need to target because of specificity
  'button&, a&': {
    paddingX: '$4',
    paddingY: '$1',
    whiteSpace: 'nowrap',
    typography: '$bodyS',
    display: 'inline-block',
    borderRadius: '$round',
    border: '$borderWidths$s solid $borderDividerHighEmphasis',
  },

  '@safeMotion': {
    transition: 'background-color $easeQuick, box-shadow $easeQuick, border-color $easeQuick, color $easeQuick',
  },

  '&:focus-visible': {
    outline: '$outlineFocus',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineFocus',
    },
  },

  '&:active': {
    border: '$borderWidths$s solid $borderDividerHighEmphasis',
  },

  variants: {
    isCurrent: {
      true: {
        'button&, a&': {
          outlineOffset: '$borderWidths$m',
          backgroundColor: '$ribbonBackgroundHighEmphasis',
          color: '$textInverted',
          '&:hover': {
            backgroundColor: '$backgroundDark',
          },
        },
      },
      false: {
        'button&, a&': {
          backgroundColor: '$backgroundPrimary',
          color: '$textPrimary',
          '&:hover': {
            backgroundColor: '$backgroundSecondary',
          },
        },
      },
    },
  },
});

const stitchesClassName = 'sparky-tag';

export const Tag = forwardRef<HTMLAnchorElement, TagProps>(
  ({ children, isCurrent = false, href, target, onClick, className = '', ariaControls }, ref) => {
    const variantProps = extractVariantProps({ isCurrent });

    const baseProps = { onClick };
    const componentProps = href
      ? {
          ...baseProps,
          href,
          ...(target ? { target } : null),
          rel: target === '_blank' ? 'noreferrer' : '',
          'aria-current': (isCurrent ? 'page' : undefined) as 'page' | undefined,
        }
      : {
          ...baseProps,
          as: 'button',
          type: 'button',
          'aria-controls': ariaControls,
          'aria-pressed': isCurrent.toString() as 'true' | 'false',
        };

    return (
      <StyledTag
        {...variantProps}
        {...componentProps}
        className={`${stitchesClassName} ${className}`}
        data-label={reactToText(children)}
        href={href}
        onClick={onClick}
        ref={ref}>
        {children}
      </StyledTag>
    );
  },
);

Tag.toString = () => `.${stitchesClassName}`;

StyledTag.displayName = 'styled(Tag)';
Tag.displayName = 'Tag';

type TagButtonProps = {
  href?: never;
  target?: never;
  /** The id of the element that contains the filtered content */
  ariaControls: string;
};

type TagAnchorProps = {
  href?: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  ariaControls?: never;
};

type TagProps = Omit<TransformStitchesToSparky<typeof StyledTag>, 'isCurrent'> & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  /** Whether or not the Tag has been selected */
  isCurrent?: boolean;
  /** Text shown inside the link. */
  children: ReactNode;
  className?: never;
} & (TagAnchorProps | TagButtonProps);
