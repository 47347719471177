import { InputHTMLAttributes, forwardRef } from 'react';

import { Input } from '../Input/Input';
import { InputBaseProps } from '../Input/Input';
/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputTimePickerHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'placeholder'
  | 'defaultValue'
  | 'value' /** used for controlled inputs */
  | 'name'
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
>;

type Props = InputBaseProps & InputTimePickerHTMLAttributes;

export const InputTimePicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <Input ref={ref} type="time" {...props} />;
});

InputTimePicker.displayName = 'InputTimePicker';
