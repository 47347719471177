// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgTwitterIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M13.903 10.655 21.348 2h-1.764l-6.465 7.515L7.955 2H2l7.808 11.364L2 22.439h1.764l6.828-7.935 5.452 7.935H22l-8.098-11.784Zm-2.417 2.809-.791-1.132L4.4 3.328h2.71l5.08 7.267.791 1.131 6.604 9.446h-2.71l-5.389-7.708Z" />
  </Icon>
);
export default SvgTwitterIcon;
SvgTwitterIcon.displayName = 'SvgTwitterIcon';
