// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgElectricityIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M17.89 10.54a1 1 0 0 0-.893-.54H13.61l1.384-6.8a.998.998 0 0 0-.589-1.116 1.004 1.004 0 0 0-1.216.346l-7.016 10a.999.999 0 0 0-.07 1 1 1 0 0 0 .872.57h3.388l-1.383 6.8a.998.998 0 0 0 .591 1.12c.13.055.27.082.411.08a1.004 1.004 0 0 0 .822-.43l7.016-10a.999.999 0 0 0 .07-1.03Z"
    />
  </Icon>
);
export default SvgElectricityIcon;
SvgElectricityIcon.displayName = 'SvgElectricityIcon';
