import { Middleware, SWRHook } from 'swr';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApiError } from "@dc/client/ApiError";

export class OverloadError extends Error {
  constructor() {
    super(`Server overloaded`);
    this.name = 'OverloadError';
  }
}

export const createOverloadMiddleware = (
  showOverloadNotification: (retryAfter?: number) => void,
  isInCooldown: () => boolean,
): Middleware => {
  return (useSWRNext: SWRHook) => (key, fetcher, config) => {
    const wrappedFetcher = fetcher && (async (...args: unknown[]) => {
      if (isInCooldown()) {
        throw new OverloadError();
      }

      try {
        return await fetcher(...args);
      } catch (error) {
        if (error instanceof ApiError && error.status === 529) {
          const retryAfter = error.headers?.get('retry-after');
          const retryAfterSeconds = retryAfter ? parseInt(retryAfter, 10) : undefined;
          showOverloadNotification(retryAfterSeconds);
          throw new OverloadError();
        }
        throw error;
      }
    });

    return useSWRNext(key, wrappedFetcher, {
      ...config,
      shouldRetryOnError: (error: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (error instanceof OverloadError) {
          return false; // Don't retry on overload errors
        }
        return typeof config?.shouldRetryOnError === 'function'
          ? config.shouldRetryOnError(error)
          : config?.shouldRetryOnError ?? true;
      },
    });
  };
};