// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgVisibilityOffIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M22.866 12.504A19.451 19.451 0 0 1 20.197 16l-3.269-3.266c.04-.241.064-.485.07-.729A4.992 4.992 0 0 0 12 7.011a5.2 5.2 0 0 0-.69.07L8.831 4.604A9.164 9.164 0 0 1 12 4.014c6.508 0 10.686 7.192 10.866 7.491a.999.999 0 0 1 0 1Zm-2.16 6.782a1 1 0 0 1-.325 1.637 1 1 0 0 1-1.094-.218l-2.209-2.218A9.752 9.752 0 0 1 12 19.995c-6.508 0-10.686-7.191-10.866-7.49a.998.998 0 0 1 0-1 19.272 19.272 0 0 1 4.138-4.814L3.273 4.694a.999.999 0 1 1 1.44-1.389l15.994 15.981ZM14.71 16.15l-1.46-1.458a2.91 2.91 0 0 1-1.249.31 3 3 0 0 1-2.999-2.997c.004-.444.11-.882.31-1.279l-1.46-1.458a4.871 4.871 0 0 0-.85 2.737A4.992 4.992 0 0 0 12 16.999a4.88 4.88 0 0 0 2.739-.849h-.03Z"
    />
  </Icon>
);
export default SvgVisibilityOffIcon;
SvgVisibilityOffIcon.displayName = 'SvgVisibilityOffIcon';
