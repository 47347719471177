const VALUE_DELIMITER = ':';
const PAIR_DELIMITER = '|';

type ParamName = string;
type ParamValue = string | null;

type Params = Record<ParamName, ParamValue>;

/**
 * This method will take an object and return a serialized string of all the property key and value pairs. The key and
 * values are joined by the : (colon) character and the pairs are then joined by the | (pipe) character.
 *
 * For absolute (and not partial) matching, the returned string is also prefixed and suffixed with the | (pipe)
 * character. This way, the string can be used in a Sitecore personalization rule to match the entire string exactly.
 * This is useful when `card:foo` should not match `card:foobar` and `herocard:foo`.
 *
 * @example
 * const personalizationParams = {
 *  foo: 'bar',
 *  baz: null,
 *  qux: 'fred'
 * };
 *
 * serializePersonalizationParams(personalizationParams);
 * // returns '|foo:bar|fred|baz:qux|'
 *
 *
 * @param personalizationParams
 */
export const serializePersonalizationParams = (params: Params = {}) => {
  const serializedParams = Object.entries(params)
    .map(([key, value]) => `${key}${value ? `${VALUE_DELIMITER}${value}` : ''}`)
    .join(PAIR_DELIMITER);
  // make sure the result is also prefixed and suffixed with the delimiter
  return `${PAIR_DELIMITER}${serializedParams}${PAIR_DELIMITER}`;
};
