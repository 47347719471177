import { createContext, FC, useContext, useState } from 'react';

import { useIsomorphicLayoutEffect } from '../hooks/useIsomorphicLayoutEffect';
import { mediaQueries } from '../sparky.config';
import { MediaQueryKeys } from '../types';
import { matchMedia } from '../util/polyfills/matchMedia';

type Props = Record<MediaQueryKeys, boolean> & { hasWindow: boolean; platformHint?: 'desktop' | 'mobile' };

const defaultValue = { sm: false, md: false, lg: false, xl: false, hasWindow: false, platformHint: undefined };
const platformMap: Record<MediaQueryKeys, 'mobile' | 'desktop'> = {
  sm: 'mobile',
  md: 'mobile',
  lg: 'desktop',
  xl: 'desktop',
};

export const MediaQueryContext = createContext<Props>(defaultValue);

export const MediaQueryProvider: FC<React.PropsWithChildren<{ platformHint?: 'desktop' | 'mobile' }>> = ({
  children,
  platformHint,
}) => {
  const [isSsrInitialised, setIsSsrInitialised] = useState(false);
  const [hasWindow, setHasWindow] = useState(false);
  const [matches, setMatches] = useState(defaultValue);

  const mediaQueryKeys = Object.keys(mediaQueries) as MediaQueryKeys[];

  // Set the guestimated matches server-side based on the given platformHint
  if (!isSsrInitialised && !hasWindow && platformHint) {
    setIsSsrInitialised(true);

    mediaQueryKeys.forEach(mediaQueryKey => {
      setMatches(oldState => {
        return { ...oldState, [mediaQueryKey]: platformMap[mediaQueryKey] === platformHint };
      });
    });
  }

  useIsomorphicLayoutEffect(() => {
    setHasWindow(true);

    const resizeHandler = (e: MediaQueryListEvent) => {
      Object.entries(mediaQueries).forEach(mediaQuery => {
        if (e.media === mediaQuery[1]) {
          setMatches(oldState => {
            return { ...oldState, [mediaQuery[0]]: e.matches };
          });
        }
      });
    };

    // Create an object of MediaQueryList objects, set a change eventListener on each and set the current state
    const mediaQueryList = mediaQueryKeys.reduce<Partial<Record<MediaQueryKeys, MediaQueryList>>>((object, query) => {
      const matchMediaResult = matchMedia(mediaQueries[query]);
      matchMediaResult.addEventListener('change', resizeHandler);

      setMatches(oldState => {
        return { ...oldState, [query]: matchMediaResult.matches };
      });

      return { ...object, [query]: matchMediaResult };
    }, {});

    // Make sure to clean up listeners for each MediaQueryList object on unmount
    return () =>
      Object.entries(mediaQueryList).forEach(mediaQueryItem => {
        mediaQueryItem[1].removeEventListener('change', resizeHandler);
      });
  }, []);

  return (
    <MediaQueryContext.Provider value={{ ...matches, hasWindow, platformHint }}>{children}</MediaQueryContext.Provider>
  );
};

/**
 * See if a media query is currently true.
 *
 * When running client-side (hasWindow), the given query is tested to the actual media query config. If not (ssr), it
 * will fall back onto an estimation, based on the platformHint (mobile <= md, desktop => lg)
 */
export const useMediaQuery = (query: MediaQueryKeys) => {
  return useContext(MediaQueryContext)[query];
};

/** See if the window has mounted */
export const useWindow = () => {
  return useContext(MediaQueryContext).hasWindow;
};
