import { FC, ReactNode, useCallback, useState } from 'react';

import { Unstyled } from '@custom-components/Unstyled';
import { putNextBestActionFeedback, putNextBestActionFeedbackPublic } from '@dc/services';
import useDC from '@dc/useDC';
import {
  DC_Domain_Models_NextBestAction_FeedbackStatus,
  DC_Repositories_Base_Enumerations_BusinessUnit,
  DC_Repositories_Base_Enumerations_Label,
  NextBestAction_ActionFeedbackDataRequestModel,
} from '@monorepo-types/dc';

import { useNbaData } from './hooks/useNbaData';

export type NbaFeedbackWrapperComponentProps = {
  children: ReactNode;
  nbaFeedback?: string;
};

export const sendNbaFeedback = ({
  businessUnit,
  customerId,
  label,
  personalizationId,
  requestBody,
}: {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  customerId?: number | null;
  label: DC_Repositories_Base_Enumerations_Label;
  personalizationId?: string | null;
  requestBody: NextBestAction_ActionFeedbackDataRequestModel;
}) => {
  if (customerId) {
    putNextBestActionFeedback({ businessUnit, customerId, label, requestBody: { data: requestBody } });
  } else if (personalizationId) {
    putNextBestActionFeedbackPublic(
      { businessUnit, label, personalizationId, requestBody: { data: requestBody } },
      { preservePathCase: true },
    );
  }
};

export const NbaFeedbackWrapperComponent: FC<NbaFeedbackWrapperComponentProps> = ({ children, nbaFeedback }) => {
  const nbaData = useNbaData();
  const { businessUnit, customerId, label } = useDC();
  const [nbaFeedbackGiven, setNbaFeedbackGiven] = useState(false);

  const handleNbaFeedback = useCallback(() => {
    if (nbaFeedbackGiven || !nbaData) return;

    const { personalizationId, ...bodyData } = nbaData;
    const requestBody = {
      status: nbaFeedback as DC_Domain_Models_NextBestAction_FeedbackStatus,
      ...bodyData,
    };

    sendNbaFeedback({
      businessUnit,
      customerId,
      label,
      personalizationId,
      requestBody,
    });

    setNbaFeedbackGiven(true);
  }, [businessUnit, customerId, label, nbaData, nbaFeedback, nbaFeedbackGiven]);

  if (!nbaData?.servingPointId || !nbaData?.actionId || !nbaFeedback) return children;

  return <Unstyled onClick={handleNbaFeedback}>{children}</Unstyled>;
};
