import { Locale } from '@common/application';
import type { TransformFunctions } from '@sitecore/common';

export const businessUnit = 'nl';
export const countryCode = 'NL';
export const label = 'eneco';
export const name = 'nl-eneco-main';
export const uuid = 'HePIIt';

export const transformFunctions: TransformFunctions = {
  transformSite: ({ item, locale, site }) => {
    let transformedSite = site ?? '';

    if (item?.startsWith('/over-ons') || item?.startsWith('/en/about-us') || item?.startsWith('/about-us')) {
      transformedSite = 'website_eneco_nl_over_ons';
    }
    if (item?.startsWith('/de/') || locale?.startsWith('de')) {
      transformedSite = 'website_eneco_de';
    }

    return transformedSite;
  },

  transformLocale: ({ locale, item }) => {
    let transformedLocale: string = locale ?? 'nl-NL';

    if (item?.startsWith('/en/')) {
      transformedLocale = 'en';
    }
    if (item?.startsWith('/de/') || locale?.startsWith('de')) {
      transformedLocale = 'de';
    }
    // Handle the workaround for Next.js locale detection
    if (locale?.startsWith('en')) {
      transformedLocale = 'en';
    }

    return transformedLocale as Locale;
  },

  transformItem: ({ item }) => {
    let transformedItem = item ?? '';

    if (item?.startsWith('/en/')) {
      transformedItem = item.replace(/^\/en/, '');
    }
    if (item?.startsWith('/de/')) {
      transformedItem = item.replace(/^\/de/, '');
    }

    return transformedItem;
  },
};