import { useMemo, useState } from 'react';

export const useStatefulTimer = () => {
  const [fieldset, updateFieldset] = useState<{ [key: string]: number }>({});
  const handlers = useMemo(
    () => ({
      setStart(key: string) {
        fieldset[key] = new Date().getTime();
        updateFieldset({ ...fieldset });
      },
      getSecondsSinceFieldStart(key: string) {
        if (fieldset[key]) {
          const now = new Date().getTime();
          return Math.ceil(Math.abs((now - fieldset[key]) / 1000));
        }

        return 0;
      },
    }),
    [],
  );

  return handlers;
};
