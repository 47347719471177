import { FC } from 'react';

import { Placeholder } from '@sitecore/common';
import { AppPlaceholder } from '@sitecore/common/core/AppPlaceholder';

const AppLayout: FC = () => (
  <>
    <Placeholder name="jss-meta" />
    <AppPlaceholder />
    <Placeholder name="jss-footer" />
  </>
);

export default AppLayout;
