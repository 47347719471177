import { styled } from '../../stitches.config';

export const InputWrapper = styled('div', {
  padding: '$3',
  borderRadius: '$s',
  backgroundColor: '$backgroundPrimary',
  border: '$borderWidths$s solid $formBorderDefault',
  position: 'relative',

  '&:focus-within': {
    outline: '$outlineInputFocus',
    borderColor: '$borderFocus',
  },

  variants: {
    isInvalid: {
      true: {
        border: '$borderWidths$s solid $formBorderError',
        boxShadow: '$shadowError',
      },
      false: {
        '&:hover': {
          boxShadow: '$shadowHover',
          borderColor: '$formBorderHover',
        },
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: '$opacity50',
        '&:hover': {
          boxShadow: '$none',
          borderColor: '$formBorderDefault',
        },
      },
    },
  },
});

export const StyledInput = styled('input', {
  width: '100%',
  fontSize: '$BodyL',
  border: 'none',
  color: '$textPrimary',

  '&::placeholder': {
    color: '$textLowEmphasis',
  },

  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
});

export const CalendarContainer = styled('div', {
  position: 'absolute',
  left: '0',
  top: 'calc($1 + 100%)',
  width: '100%',
  variants: {
    hasError: {
      true: {
        top: 'calc($12 + 100%)',
      },
    },
  },
});

export const ButtonIconContainer = styled('div', {
  position: 'absolute',
  right: 'calc($1 * 0.5)',
  top: 'calc($1 * 0.5)',
  button: {
    borderRadius: '$s',
  },
});

export const DatepickerWrapper = styled('div', {
  '& .sparky-card': {
    boxShadow: 'none',
  },
});
