import React from 'react';

import { DayPicker as ReactDayPicker } from 'react-day-picker';

import { DayPickerProps } from './Datepicker';
import { styled } from '../../stitches.config';

// @ts-expect-error pagedNavigation is not defined as variant prop
export const DayPicker: React.FC<DayPickerProps> = styled(ReactDayPicker, {
  '.rdp-table': {
    borderSpacing: '0 $space$2',
    padding: '$6',
    width: '100%',
  },
  '.rdp-row .rdp-cell': {
    '&:first-child': {
      borderTopLeftRadius: '$buttonBorderRadiusDefault',
      borderBottomLeftRadius: '$buttonBorderRadiusDefault',
    },
    '&:last-child': {
      borderTopRightRadius: '$buttonBorderRadiusDefault',
      borderBottomRightRadius: '$buttonBorderRadiusDefault',
    },
  },
  '.rdp-cell': {
    padding: 0,
    '&:first-child > button.rdp-day_selected': {
      borderTopLeftRadius: '$buttonBorderRadiusDefault',
      borderBottomLeftRadius: '$buttonBorderRadiusDefault',
    },

    '&:last-child > button.rdp-day_selected': {
      borderTopRightRadius: '$buttonBorderRadiusDefault',
      borderBottomRightRadius: '$buttonBorderRadiusDefault',
    },
  },
  variants: {
    pagedNavigation: {
      true: {
        '.rdp-months': {
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          '@md': {
            flexDirection: 'row',
          },
          '.rdp-month': {
            width: '100%',
            '@md': {
              width: '50%',
            },
          },
        },
      },
    },
  },
});

export const DayButton = styled('button', {
  background: 'transparent',
  fontSize: '$BodyS',
  fontWeight: '$bodyBold',
  color: '$textPrimary',
  borderRadius: '$buttonBorderRadiusDefault',
  border: '$borderWidths$s solid transparent',
  width: '100%',
  paddingX: '$2',
  paddingY: '$2',
  '&[aria-selected=true]': {
    backgroundColor: '$backgroundDark',
    color: '$textInverted',
    '&:focus-visible': {
      borderColor: '$neutralWhite',
    },
  },
  '&:hover': {
    border: '$borderWidths$s solid $borderDividerMediumEmphasis',
    cursor: 'pointer',
    outline: 'none',
  },
  '&:focus-visible': {
    borderRadius: '$buttonBorderRadiusDefault',
    zIndex: '1',
    position: 'relative',
    border: '$borderWidths$s solid $borderDividerMediumEmphasis',
    outline: '$borderWidths$m solid $colors$backgroundDark',
  },
  variants: {
    isDisabled: {
      true: {
        color: '$controlsInactive',
        '&:hover': {
          border: '$borderWidths$s solid transparent',
          cursor: 'default',
        },
      },
    },
    isToday: {
      true: {
        border: '$borderWidths$m solid $borderDividerLowEmphasis',
        '&[aria-selected=true], &:focus-visible, &:hover': {
          /** 1px spacing needed to prevent jumping on hover */
          marginY: '1px',
        },
        '&[aria-selected=true]': {
          border: '$borderWidths$s solid transparent',
        },
        '&:focus-visible': {
          border: '$borderWidths$s solid $borderDividerMediumEmphasis',
        },
      },
    },
    isRangeBound: {
      true: {
        borderRadius: '$buttonBorderRadiusDefault',
        backgroundColor: '$backgroundDark',
      },
    },
    isRangeMiddle: {
      true: {
        '&[aria-selected=true]': {
          backgroundColor: '$backgroundSecondary',
          color: '$textPrimary',
          borderRadius: 0,
          '&:hover': {
            borderRadius: '$buttonBorderRadiusDefault',
          },
          '&:focus-visible': {
            borderRadius: '$buttonBorderRadiusDefault',
          },
        },
      },
    },
  },
});
