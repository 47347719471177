import { createStitches, PropertyValue, ScaleValue, defaultThemeMap } from '@stitches/react';

import { normalizeCSS } from './normalize';
import { mediaQueries } from './sparky.config';
import { theme, globalStyles, typography, iconSizes } from './themes';
import { Theme } from './types';

const mediaQueriesMax = {
  smMax: '(max-width: 374px)',
  mdMax: '(max-width: 767px)',
  lgMax: '(max-width: 1023px)',
  xlMax: '(max-width: 1439px)',
};

const mediaFeatures = {
  safeMotion: '(prefers-reduced-motion: no-preference)',
};

const stitches = createStitches({
  prefix: '',
  theme: theme as Theme,
  themeMap: {
    ...defaultThemeMap,
    display: 'display',
    outline: 'outlines',
    opacity: 'opacities',
  },
  media: { ...mediaQueries, ...mediaQueriesMax, ...mediaFeatures },
  utils: {
    paddingY: (value: ScaleValue<'space'> | 0) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    paddingX: (value: ScaleValue<'space'> | 0) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    marginY: (value: PropertyValue<'marginTop'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    marginX: (value: PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
      marginRight: value,
    }),

    /**
     * - This property was initially created for the <Stack /> component -
     * Because the specificity of the universal selector is 0 (https://www.w3.org/TR/selectors/#specificity-rules), these margins always get overridden if the child component has margins of itself. That means that the negative margins of the parent, needed for spacing between items in the Stack, are not properly compensated. The only way to increase the specificity is with `!important` due to the nature of our scoped component based architecture.This also correctly plays into our philosophy that elements should use Box to create spacing. */
    noflexgap: (value: ScaleValue<'space'> | 0) => ({
      marginTop: `-${value}`,
      marginLeft: `-${value}`,

      '& > *': {
        marginTop: `${value} !important`,
        marginLeft: `${value} !important`,
      },
    }),

    typography: (value: `$${Typography}`) => {
      const key = value.toString().replace('$', '') as Typography;
      return typography[key];
    },

    iconography: (value: `$${Iconography}`) => {
      const key = value.toString().replace('$', '') as Iconography;

      return {
        width: iconSizes[key],
        height: iconSizes[key],
      };
    },

    dvh: (value: number) => {
      return {
        '@supports (height: 100dvh)': {
          height: `${value}dvh`,
        },
        '@supports (not(height: 100dvh))': {
          height: `${(window.innerHeight / 100) * value}px`,
        },
      };
    },
  },
});

const {
  styled,
  keyframes,
  css,
  globalCss: globalStitches,
  getCssText,
  theme: defaultTheme,
  createTheme,
  config,
} = stitches;
globalStitches({
  ...globalStyles,
  ...normalizeCSS,
})();

export { keyframes, styled, css, getCssText, globalStitches, defaultTheme, createTheme, config };

// @ts-ignore TODO: We still need to decide on how to do this
export const getTokens = (): Theme => theme;

export const getToken = <T extends keyof Theme>(category: T, token: keyof Theme[T]) => theme[category][token];

type Typography = keyof typeof typography;
type Iconography = keyof typeof iconSizes;
