// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgUploadIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M19 13v5c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-5c0-.55-.45-1-1-1s-1 .45-1 1v6c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1Zm-8.01-6.59L9.11 8.29A.996.996 0 1 1 7.7 6.88l3.59-3.59a.996.996 0 0 1 1.41 0l3.59 3.59a.996.996 0 1 1-1.41 1.41l-1.89-1.88v8.67c0 .55-.45 1-1 1s-1-.45-1-1V6.41Z" />
  </Icon>
);
export default SvgUploadIcon;
SvgUploadIcon.displayName = 'SvgUploadIcon';
