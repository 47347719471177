import { createContext, FC, useContext } from 'react';

import { Locale as DXPLocale } from '@common/application';

export type Locale = DXPLocale;
interface Props {
  locale?: Locale;
}

const LocaleContext = createContext<Locale>('nl-NL');

export const LocaleProvider: FC<React.PropsWithChildren<Pick<Props, 'locale'>>> = ({ children, locale = 'nl-NL' }) => {
  return <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>;
};

export const useLocale = (): Locale => {
  return useContext(LocaleContext);
};
