// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSmartChargingIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="m14.74 15.18-.77 3.07c-.11.45-.51.76-.97.76h-2c-.46 0-.86-.31-.97-.76l-.77-3.07A4.988 4.988 0 0 1 7 11c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.75-.9 3.28-2.26 4.18ZM12 5c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1c0 .55.45 1 1 1Zm-6 6c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1h1c.55 0 1-.45 1-1Zm14-1h-1c-.55 0-1 .45-1 1s.45 1 1 1h1c.55 0 1-.45 1-1s-.45-1-1-1ZM6.34 6.76c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41l-.71-.71a.996.996 0 1 0-1.41 1.41l.71.71h-.01Zm11.32 8.48a.996.996 0 1 0-1.41 1.41l.71.71c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41l-.71-.71h-.01Zm-11.32 0-.71.71a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29l.71-.71a.996.996 0 1 0-1.41-1.41h-.01Zm10.61-8.19c.26 0 .51-.1.71-.29l.71-.71a.996.996 0 1 0-1.41-1.41l-.71.71a.996.996 0 0 0 .71 1.7h-.01ZM13 20h-2c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1-.45 1-1s-.45-1-1-1Z" />
  </Icon>
);
export default SvgSmartChargingIcon;
SvgSmartChargingIcon.displayName = 'SvgSmartChargingIcon';
